// если вы хотите использовать sass-переменные - удалите root
// colors
:root {

  // colors
  --white: #fff;
  --black: #333;
  --primary: #feb11c;
  --red: #ee5513;
  --gray: #f4f4f4;
  --text: #4f4f4f;
  --text-light: #828282;
  --text-lighter: #bdbdbd;
  --text-white: #e0e0e0;
  

  --container-margin: Max(calc((100vw - 1460px) / 2), 70px);
  
}