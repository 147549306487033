.search{
    margin-bottom: rem(109);
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px rem(-20);
        .product-card{
            width: calc(100% / 5 - 20px);
            margin: 0 10px rem(20);
        }
        @media (min-width: 1601px) {
            .product-card{
                width: calc(100% / 6 - 20px);
            }
            .search__field{
                width: calc(100% - ((100% / 6 + 30px) * 2));
            }
        }
        @media (max-width: 1600px) {
           .product-card:nth-child(9){
               display: none;
           }
        }
    }
    &__field{
        width: calc(100% - ((20% + 30px) * 2));
        margin: 0 30px rem(20);
        text-align: center;
        position: relative;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__info{
        padding-top: rem(34);
        text-align: center;
        max-width: rem(600);
        margin: 0 auto;
    }
    &__title{
        margin-bottom: rem(10);
    }
    &__text >*{
        font-weight: 500 !important;
        color: var(--black) !important;
    }
    &__form{
        display: flex;
        width: 100%;
        max-width: 1024px;
        margin: rem(39) auto rem(15);
        @media (min-width: 769px) {
            box-shadow: 0 rem(10) rem(25) rgba(0, 0, 0, 0.07);
            border-radius: rem(18);
        }
        &>*{
            flex-grow: 1;
            @media (min-width: 769px) {
                input,
                textarea,
                .output_text {
                    border-radius: 0;
                    width: 100%;
                }
                &:first-child {
                    input,
                    textarea,
                    .output_text {
                        border-radius: rem(18) 0 0 rem(18);
                    }
                }
                &:last-child {
                    width: auto;

                    input,
                    textarea,
                    .output_text {
                        border-radius: 0 rem(18) rem(18) 0;
                        width: auto;
                    }
                }
            }
        }
    }
    &__btn{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    @media(max-width: 991px) and (min-width: 769px){
        .search__form {
            flex-wrap: wrap;
            justify-content: center;
            .input--select {
                width: calc(100% / 3);
            }
            &>:nth-last-child(2){
                border-radius: 0 rem(18) rem(18) 0;
            }
        }

        .search__container {
            margin: 0 -5px -1.25rem;

            .product-card {
                width: calc(20% - 10px);
                margin: 0 5px 1.25rem;
            }
        }
    }

    @media(max-width: 768px){
        margin-bottom: 70px;
        &__field{
            width: 100%;
            margin: 0;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__info{
            max-width: unset;
            padding-top: 50px;
            text-align: left;
            margin: 0;
        }
        &__form{
            margin-top: 28px;
            margin-bottom: 0;
            flex-direction: column;
            align-items: center;
            &>*{
                border-radius: 18px;
                &:first-child{
                    border-radius: 18px;
                }
                &:last-child{
                    margin: 30px 0;
                    border-radius: 18px;
                    max-width: 400px;
                    width: 100%;                    
                }
            }
        }
        &__container{
            margin: 0;
            .product-card{
                display: none;
            }
        }
        &__slider{
            .product-card{
                margin: 0 33px;
            }
            .product-card__img{
                max-height: 350px;
                &::before{
                    padding-top: 111.8%;
                }
            }
        }
        &__slider-btn{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 5;
            cursor: pointer;
            height: 20px;
            &>*{
                height: 100%;
                width: auto;
            }            
        }
        &__slider-btn--prev{
            left: 0;
        }
        &__slider-btn--next{
            right: 0;
        }
    }
}


