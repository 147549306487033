.orders{
    padding-top: rem(75);
    padding-bottom: rem(137);
    &__head{
        margin-bottom: rem(84);
    }
    &__accordion{
        max-width: rem(642);
        margin: 0 auto;
        .info-open{
            display: none;
        }
    }

    @media(max-width: 768px){
        padding-top: 30px;
        padding-bottom: 100px;
        &__head{
            margin-bottom: 48px;
        }
        &__accordion{
            max-width: unset;
        }
        &__title{
            margin-bottom: 15px;
            padding-left: 20px;
        }
    }
}