.footer{
    padding-top: rem(41);
    padding-bottom: rem(30);
    background-color: var(--black);
    color: var(--text-white);
    &__logo{
        margin-bottom: rem(30);
        a{
            height: rem(73);
            display: block;
            max-width: 100%;
            >*{
                height: 100%;
                width: auto;
                object-fit: contain;
            }
        }
    }
    &__container{
        display: flex;
        justify-content: space-between;
    }
    &__block{
        min-width: 33.55%;
        &>*:not(:last-child){
            margin-bottom: rem(48);
        }
    }
    &__row{
        display: flex;
        margin: 0 rem(-15);
        justify-content: space-between;
        &>*{
            margin: 0 rem(15);
            max-width: rem(220);
        }
    }

    &__list{
        li{
            &:not(:last-child){
                margin-bottom: rem(10);
            }
        }
        >*{
            font-weight: 300;
            font-size: Max(rem(13), 10px);
            line-height: rem(20);
        }
        a{
            color: var(--text-white);
            transition: color 0.2s ease-in-out;
            &:hover{
                color: var(--primary);
            }
        }
    }
    &__title{
        &:not(:last-child){
            margin-bottom: rem(12);
        }
        a{
            color: var(--text-white);
            transition: color 0.2s ease-in-out;
            display: block;
            width: max-content;
            max-width: 100%;
            &:hover{
                color: var(--primary);
            }
        }
    }
    &__contact{
        display: flex;
        min-width: rem(346);
        padding-top: rem(7);
    }
    &__phone{
        &:not(:last-child){
            margin-right: rem(26);
        }
        a{
            transition: color 0.2s ease-in-out;
            &:hover{
                color: var(--primary);
            }
        }
        >*{
            font-weight: 500;
            font-size: Max(rem(19), 12px);
            line-height: rem(20);
            color: var(--text-light);
            
        }
        span{
            display: block;
            font-weight: 300;
            font-size: Max(rem(13), 10px);
            line-height: rem(20);

        }
    }
    &__socials{
        height: 100%;
    }
    &__bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(56);
        padding-top: rem(20);
        border-top: 1px solid var(--text);
        &>*:not(.footer__bottom--img){
            flex-basis: 0;
            flex-grow: 1;
            &:last-child{
                display: flex;
                justify-content: flex-end;
            }
        }
        span{
            display: inline-block;
            font-weight: 300;
            font-size: Max(rem(13), 10px);
            line-height: rem(20);
        }
        &--img{
            line-height: 0;
            display: flex;
            >*{
                width: rem(48);
                height: auto;
                object-fit: contain;
                &:not(:last-child){
                    margin-right: rem(9);
                }
            }
        }
        &--create{
            cursor: pointer;
            padding-right: rem(37);
            align-items: center;
            display: flex;
            color: inherit;
            img{
                height: rem(29);
                object-fit: contain;
            }
            span{
                margin-left: rem(20);
                max-width: Max(rem(145), 107px);
            }
  
        }
    }
    &__list {
        margin-top: 22px;
    }
    &__nav--mobile {
        margin-right: 25px;
    }



    @media(max-width: 768px){
        padding-top: 30px;
        padding-bottom: 19px;
        &__container{
            flex-direction: column;
        }
        &__logo {
            margin-bottom: 14px;
            a{
                height: 75px;
            }
        }
        &__block{
            &:nth-child(1){
                margin-bottom: 26px;
                // > div:nth-child(1)> div:nth-child(1) {
                //     margin-right: 56px;
                //     flex-shrink: 0;
                // }
            }
            &:not(:last-child){
                margin-bottom: 30px;
            }
            &:not(.footer__nav--mobile) .footer__row>*{
                flex-grow: 1;
                flex-basis: 0;
                .footer__title{
                    max-width: 83%;
                }
            }
        }
        &__nav--mobile{
            .footer__row:nth-child(1){
                flex-wrap: wrap;
                margin-bottom: 30px;
                >*{
                    flex-basis: 50%;
                    flex-grow: 1;
                    max-width: unset;
                }
            }
            .footer__info{
                &:nth-child(1){
                    margin-bottom: 30px;
                    margin-right: 10px;
                }
            }
        }
        &__row{
            margin: 0;
            line-height: 0;
            &>*{
                margin: 0;
                max-width: unset;
            }
        }
        &__contact{
            flex-shrink: 0;
            flex-direction: column;
            min-width: unset;
            margin-right: 44px;
        }
        &__title:not(:last-child){
            margin-bottom: 20px;
        }
        &__phone{
            max-width: 164px;
            &:not(:last-child){
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
        &__info:not(:last-child){
            padding-right: 5px;
        }


        &__bottom{
            flex-direction: column;
            align-items: flex-start;
            margin-top: 37px;
            padding-top: 24px;
            span:nth-child(1) {
                order: 1;
                margin-bottom: 60px;
            }
            &--create{
                order: 2;
                padding-right: 0;
            }
            &--img{
                margin-bottom: 25px;
            }
        }


        &__list li:not(:last-child) {
            margin-bottom: 10px;
        }

        &__nav--mobile {
            margin-right: 0;
        }
        .footer__bottom-block {
            order: 2;
        }

    }

}





  

  

  
  