
.success {
    display: flex;
    align-items: flex-start;
    color: var(--black);
    .section-text p {
        color: #000;
    }
    &__img{
        width: rem(60);
        flex-shrink: 0;
        margin-right: rem(30);
    }
    &__content {
        padding-top: rem(7);
    }
    &__title:not(:last-child) {
        margin-bottom: rem(30);
    }

    &-message{
        position: relative;
        padding-left: rem(90);
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: rem(60);
            height: rem(55);
            background-image: url("data:image/svg+xml,%3Csvg width='63' height='59' viewBox='0 0 63 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='29.5' cy='29.5' r='27.5' stroke='%23FEB11C' stroke-width='4'/%3E%3Cpath d='M15 27.5L29 41.5L61.5 9' stroke='%23FEB11C' stroke-width='4'/%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        &__btn,
        &__text{
            margin-top: rem(30);
        }
        &__btn {
            display: flex;
            align-items: center;
            img {
                flex-shrink: 0;
                margin-right: 10px;
            }
            a:not(.btn) {
                color: inherit;
                transition: color .3s ease-in-out;
                text-decoration: underline !important;
                text-decoration-color: var(--primary) !important;
                text-underline-offset: 6px;
                text-decoration-thickness: 1.5px!important;
                &:hover {
                    color: var(--primary);
                }
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        &__img {
            margin-right: auto;
            line-height: 0;
            margin-left: auto;
            margin-bottom: 26px;
        }
        &__title:not(:last-child) {
            margin-bottom: 20px;
        }
        &__content {
            padding-top: 0;
        }

        &-message{
            padding-left: 0;
            padding-top: 85px;
            text-align: center;
            &::before {
                left: 50%;
                transform: translateX(-50%);
            }

            .section-text--big p {
                font-size: 14px;
                line-height: 26px;
            }

            &__btn, &__text {
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
                width: 79%;
            }
        }
    }
}