.card-post{
    &:hover{
        .card-post__title{
            color: var(--primary);
        }
    }
    &__inner{
        margin-top: rem(10);
    }
    &__title{
        font-weight: 600;
        font-size: Max(rem(19), 10px);
        line-height: rem(28);
        margin-bottom: rem(13);
        color: var(--black);
        transition: color 0.3s ease-in-out;
    }
    &__text{
        font-weight: 400;
        font-size: Max(rem(15), 10px);
        line-height: rem(25);
        color: var(--text);
    }

    @media(max-width: 768px){
        margin: 0;
        flex-grow: unset;
        flex-basis: unset;
        &:not(:last-child){
            margin-bottom: 30px;
        }
        &__inner{
            padding-right: 30px;
        }
    }
}