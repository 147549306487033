.benefits{
    padding-top: rem(63);
    padding-bottom: rem(105);
    &__container{
        display: flex;
        justify-content: space-between;
    }
    &__info{
        max-width: rem(440);
        margin-right: rem(50);
    }
    &__title{
        margin-bottom: rem(25);
    }
    &__btn{
        margin-top: rem(47);
    }
    &__content{
        display: flex;
        flex-wrap: wrap;
        max-width: rem(749);
        justify-content: space-between;
        padding-top: rem(14);
    }
    &__item{
        display: flex;
        max-width: rem(308);
        margin-bottom: rem(43);
        width: calc(50% - rem(25));
        &:nth-child(odd){
            margin-right: rem(50);
        }
        &:not(:last-child){
            margin-bottom: rem(43);
        }
        .section-text--small >*{
            color: var(--text-light);
        }
    }
    &__subtitle{
        margin-bottom: rem(12);
    }
    &__item-img{
        flex-shrink: 0;
        margin-right: rem(19);
        width: rem(52);
        height: rem(52);
        >*{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    @media(max-width: 768px){
        padding-top: 50px;
        padding-bottom: 70px;
        &__container{
            flex-direction: column;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__info{
            max-width: unset;
            margin-right: 0;
            margin-bottom: 50px;
        }
        &__btn{
            margin-top: 30px;
            text-align: center;
            >*{
                width: 100%;
                max-width: 450px;
            }
        }
        &__content{
            max-width: unset;
            flex-direction: column;
            margin-bottom: 0;
        }
        &__item{
            max-width: unset;
            width: 100%;
            margin-bottom: 0;
            &:nth-child(odd){
                margin-right: 0;
            }
            &:not(:last-child){
                margin-bottom: 30px;
            }
        }
        &__subtitle{
            margin-bottom: 12px;
        }
    }
}



  



  