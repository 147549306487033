.product{
    padding-top: rem(26);
    padding-bottom: rem(60);

    .product-card__favorite{
        transition: background-color .3s ease-in-out;
        svg path {
            fill: var(--white);
        }
        &.is-added{
            background: var(--primary);
            svg path{
                stroke: var(--white);
            }
            > span {
                color: var(--white);
            }
            &:hover > span {
                color: var(--black);
            }

        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }
    &__left {
        width: 28.8%;
        margin-right: rem(80);
    }
    &__form {
        width: calc(71.2% - 5rem);
    }


    &__gallery{
        &-arrows{
            position: absolute;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            top: 50%;
            transform: translateY(-50%);
            height: 0;
            align-items: center;
            >*{
                width: rem(10);
                height: rem(20);
                cursor: pointer;
                &.swiper-button-disabled{
                    pointer-events: none;
                svg path{
                    stroke: var(--text-white);
                }
            }
                svg{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            
        }
        &-main{
            margin-bottom: rem(54);
            width: 100%;
            position: relative;
            .product-card__tags{
                position: absolute;
                left: 0;
                top: rem(36);
                z-index: 2;
                >*{
                    font-weight: 800;
                    font-size: rem(23);
                    line-height: rem(22);
                    padding: rem(8) rem(17);
                }
            }
            .product__gallery-arrows{
                opacity: 0;
            }
        }
        &-main__item{
            position: relative;
            >*{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &::before{
                content: "";
                display: block;
                width: 100%;
                padding-top: 126.8%;
            }
        }
        &-next{
            position: relative;
            .swiper-thumbs{
                width: 82%;
            }
            .swiper {
                padding: 0 1px;
            }
        }
        &-next__item{
            position: relative;
            cursor: pointer;
            transition: border 0.2s ease-in;
            border: 2px solid #F2F2F2;
            outline: 1px solid transparent;
            transition: border-color .3s ease-in-out, outline-color .3s ease-in-out;
            &.swiper-slide-thumb-active{
                border: 2px solid var(--primary);
                outline: 1px solid transparent;
            }
            >*{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 100%;
            }
            &:hover{
                border-color: var(--primary);
                outline-color: var(--primary);
            }
        }
    }
    &__info{
        display: flex;
        align-items: center;
        margin-bottom: rem(22);
        >*{
            &:not(:last-child){
                margin-right: rem(30);
            }
        }
        .product-card__favorite{
            position: static;
            width: unset;
            height: unset;
            display: flex;
            align-items: center;
            padding: rem(3) rem(15);
            border: 1px solid var(--primary);
            border-radius: rem(10);
            span{
                font-weight: 600;
                font-size: Max(rem(14), 10px);
                line-height: rem(20);
                color: var(--black);
                transition: color 0.3s ease-in-out;
            }
            svg{
                width: rem(14);
                height: rem(14);
                object-fit: contain;
                margin-right: rem(10);
            }
            &:hover{
                span{
                    color: var(--primary);
                }
            }
        }
    }
    &__info-link{
        display: flex;
        align-items: center;
        border: 1px solid var(--primary);
        padding: rem(3) rem(15);
        border-radius: rem(10);
        color: var(--black);
        transition: color 0.3s ease-in-out;
        .product-card__favorite{
            position: static;
            display: flex;
            margin-right: rem(10);
            width: rem(14);
            height: rem(14);
        }
        span{
            font-weight: 600;
            font-size: Max(rem(14), 10px);
            line-height: rem(20);
        }
        &:hover{
            color: var(--primary);
        }
    }
    &__code{
        font-weight: 600;
        font-size: Max(rem(14), 10px);
        line-height: rem(20);
        color: var(--text);
        span{
            font-weight: 400;
        }
        &--small{
            color: var(--black);
            span{
                font-size: Max(rem(12), 10px);
                margin-right: rem(10);
                color: var(--text);
            }
        }

    }
    &__title{
        max-width: rem(640);
        margin-top: rem(2);
        margin-bottom: rem(10);
    }
    &__choises{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: rem(45);
        &-title{
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #4F4F4F;
            margin-bottom: 3px;
        }
        &-item{
            &:not(:last-child){
                margin-right: rem(40);
            }
        }
    }
    &__options{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -5px;
        li{
            margin: 0 5px rem(10);
        }
        &--colors{
            .input--radio{
                input:checked + .input--radio__btn span:last-child{
                    opacity: 1;
                }
                &__btn{
                    padding: 0 0 0 rem(4.5);
                    width: rem(36);
                    height: rem(36);
                    transition: width .3s ease-in-out;
                    justify-content: flex-start;
                    overflow: hidden;
                    span{
                        &:first-child{
                            display: block;
                            width: rem(26);
                            height: rem(26);
                            border-radius: 50%;
                            flex-shrink: 0;
                        }
                        &:last-child{
                            overflow: hidden;
                            white-space: nowrap;
                            opacity: 0;
                            transition: opacity .3s ease-in-out;
                            text-align: center;
                            padding: 0 rem(16) 0 rem(10);
                            flex-shrink: 0;
                        }
                    }
                }


            }
        }
    }
    &__variants{
        display: flex;
        align-items: flex-start;
        margin: 0 -10px rem(45);
        transition: opacity .3s ease-in-out;
        li{
            width: calc(50% - 20px);
            margin: 0 10px;

            .product__variants-btns{
                margin-left: rem(51);
            }
            &:first-child{
                .product__variants-row{
                    max-width: 363px;
                }
                .product__variants-btns{
                    max-width: 310px;
                }
            }
        }
        &-row{
            display: flex;
            .input--radio {
                width: 100%;
                &__check{
                    font-weight: 500;
                    font-size: rem(25);
                    line-height: rem(25);
                    
                }
            }
            .product-card__content {
                text-align: left;
                margin-left: rem(51);
                position: relative;
            }
            .product-card__price{
                margin-bottom: rem(3);
                display: flex;
                flex-direction: column;
                &--old{
                    position: static;
                    right: unset;
                    top: unset;
                    bottom: rem(-20);
                    order: 1;
                    font-size: Max(rem(14), 10px);
                    line-height: rem(20);
                }
                &--new{
                    font-size: rem(30);
                    line-height: rem(30);
                }
                
            }
            .product-card__package > * {
                text-align: unset;
                font-weight: 400;
                font-size: Max(rem(14), 10px);
                line-height: rem(20);
            }
        }
        &-btns, .quantity{
            display: none;
        }
        &-btns{
            width: 100%;
            margin-top: rem(20);
            &>*:not(:first-child){
                display: none;
            }
        }
        &-info{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: rem(8);
        }
        &-status{
            .product__status>*{
                max-width: rem(93);
                text-align: right;
                font-weight: 400;
                font-size: Max(rem(14), 10px);
                line-height: rem(14);
            }
        }

        &.is-loading{
            opacity: 0.5;
            cursor: progress;
            &>*{
                pointer-events: none;
            }
        }
    }
    &__similiar{
        padding-top: rem(23);
        margin-bottom: rem(45);
        border-top: 2px solid var(--gray);
        &-title{
            margin-bottom: rem(11);
        }
        &-container{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px rem(-20);
            >*{
                width: calc(100% / 2 - 20px);
                margin: 0 10px rem(20);
            }
        }
        &-btn{
            margin-top: rem(20);
            display: flex;
            justify-content: center;
        }
    }
    &__table{
        border-top: 4px solid var(--gray);
        border-bottom: 2px solid var(--gray);
        margin-bottom: rem(30);
        padding-top: rem(35);
        padding-bottom: rem(16);
        ul{
            columns: 2;
            column-gap: rem(20);
            margin-top: rem(35);
            li{
                display: flex;
                justify-content: space-between;
                padding: rem(6) rem(8);
                >*{
                    font-weight: 400;
                    font-size: Max(rem(14), 10px);
                    line-height: rem(20);
                    color: var(--text);
                }
                span:nth-child(2){
                    font-weight: 600;
                    text-align: right;
                    padding-left: rem(20);
                }
                &:nth-child(odd){
                    background-color: #F3F3F3;
                }
                &:nth-child(even){
                    background-color: var(--white);
                }
            }
        }
    }
    &__sketch{
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        font-size: Max(rem(14), 10px);
        line-height: rem(20);
        color: var(--black);
        img{
            width: rem(16);
            height: rem(21);
            object-fit: cover;
            margin-right: rem(10);
        }
        &:not(:last-child){
            margin-right: rem(40);
        }
    }
    .section-text >*{
        font-weight: 300;
        max-width: unset;
    }
    &__row{
        display: flex;
        margin-top: auto;
    }

    @media(max-width: 1180px){
        .product__gallery-next {
            padding: 0 15px;
        }
    }

    @media(max-width: 768px){
        padding-bottom: 50px;
        &__container {
            flex-direction: column;
        }
        &__left {
            width: 100%;
            margin-right: 0;
        }
        &__title{
            margin-top: 5px;
            max-width: unset;
            >*{
                font-size: 30px;
            }
        }
        &__info{
            margin: 0 -10px -10px;
            flex-wrap: wrap;
            >*{
                margin: 0 10px 10px !important;
            }
        }
        &__code--small span{
            margin-right: 5px;
        }
        &__gallery{
            margin-top: 40px;
        }
        &__gallery-main{
            margin-bottom: 30px;
            .product-card__tags > *{
                font-size: 20px;
                padding: 6px 17px 6px 21px;
            }
            .product__gallery-arrows{
                opacity: 1;
                z-index: 2;
            }   
        }
        &__gallery-main__item{
            max-height: 350px;
            &::before{
                padding-top: 109.4%;
            }
        }
        &__gallery-next{
            display: none;
        }
        &__similiar{
            padding-top: 19px;
            margin-bottom: 35px;
            &-title{
                margin-bottom: 5px;
            }
            &-container{
                margin: 0;
                >*{
                    width: 100%;
                    margin: 0;
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &__table{
            border-bottom: 0;
            padding-bottom: 0;
            ul{
                margin-top: 16px;
                columns: 1;
                li{
                    padding-left: 0;
                    padding-right: 14px;
                }
            }
        }
        &__sketch:not(:last-child){
            margin-right: 10px;
            margin-bottom: 25px;
        }






        .product__choises-item:not(:last-child) {
            margin-right: 0;
        }

        .product__options li {
            margin: 0 5px 10px;
            white-space: nowrap;
        }

        .product__options {
            margin-bottom: 0;
            overflow-x: auto;
            padding-top: 1px;
            flex-wrap: nowrap;
        }

        .product__choises {
            margin-bottom: 38px;
        }

        .product__variants {
            flex-direction: column;
            margin-bottom: 32px;
        }

        .product__variants li:not(:last-child) {
            margin-right: 0;
            margin-bottom: 30px;
        }

        .product__variants li {
            min-width: 100%;
        }

        .product__variants-row .input--radio__check {
            font-size: 20px;
            line-height: 25px;
        }

        .product__variants-info {
            margin-top: 3px;
        }

        .product__variants-row .product-card__price--new {
            font-size: 25px;
            line-height: 30px;
        }

        .product__variants-row .product-card__price--old {
            font-size: 12px;
            line-height: 16px;
        }

        .product__variants-row .product-card__package > * {
            font-size: 12px;
            line-height: 14px;
        }

        .product__variants--available p {
            font-size: 12px;
            line-height: 12px;
        }

        .product__variants-btns {
            width: calc(100% - 43px);
            margin-top: 5px;
            margin-left: 43px;
        }

        .product__variants-row .product-card__content {
            margin-left: 43px;
        }

        .input--radio__check {
            padding-left: 43px;
        }

        .product__choises-item {
            width: 100%;
        }




        .product__form {
            width: 100%;
        }

        .product__variants {
            margin: 0 0 32px;
        }

        .product__variants li {
            width: 100%;
            margin: 0;
        }

        .product__variants li:first-child .product__variants-row {
            max-width: 100%;
        }

        .product__variants li .product__variants-btns {
            margin-left: 43px;
        }
        .product__variants li:first-child .product__variants-btns {
            max-width: 100%;
        }

    }

}