.forrozn-find{
    padding-top: rem(30);
    padding-bottom: rem(37);
    position: relative;
    &__content{
        max-width: rem(600);
        margin: 0 auto;
        text-align: center;
    }
    &__title{
        margin-bottom: rem(13);
    }
    &__btn{
        margin-top: rem(52);
    }
    &__img{
        position: relative;
        height: rem(234);
        margin-top: rem(37);
        overflow: hidden;
        width: 100%;

        background-size: auto 100%;
        background-repeat: repeat;
        background-position: center;
        &>*{
            position: absolute;
            bottom: 0;
            top: 0;
            height: 100%;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @media(max-width: 768px){
        padding-top: 0;
        padding-bottom: 55px;
        &__content{
            text-align: left;
            margin: 0;
            max-width: unset;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__btn{
            margin-top: 30px;
            text-align: center;
            >*{
                width: 100%;
                max-width: 400px;
            }
        }
        &__img{
            margin-top: 70px;
            height: 104px;
        }
    }

}



  


  