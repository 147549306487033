.account{
    padding-top: rem(75);
    padding-bottom: rem(201);
    &__head{
        margin-bottom: rem(55);
    }
    &__container{
        margin: 0 auto;
        max-width: rem(650);
    }
    &__form{
        .show-on-success{
            display: none;
        }
        &-row{
            position: relative;
            z-index: 2;
            margin-bottom: rem(60);
            .section-text{
                margin-bottom: rem(10);
            }
            &#payment {
                position: relative;
                z-index: 1 !important;
            }
        }
        &-title{
            margin-bottom: rem(35);
        }
        &-btn{
            padding-top: rem(45);
            border-top: 4px solid var(--gray);
            display: flex;
            align-items: center;
            &>*:not(:last-child){
                margin-right: rem(15);
            }
            .btn{
                max-width: rem(217);
                width: 100%;
            }
        }
    }

    @media(max-width: 768px){
        padding-top: 24px;
        padding-bottom: 110px;
        &__head{
            margin-bottom: 48px;
        }
        &__title{
            padding-left: 20px;
            margin-bottom: 15px;
        }
        &__form{
            &-title{
                margin-bottom: 20px;
            }
            &-row{
                margin-bottom: 50px;
                .section-text{
                    padding-top: 10px;
                }
            }
            &-btn{
                padding-top: 50px;
                text-align: center;
                >*{
                    width: 100%;
                    max-width: 400px;
                }
            }
        }
    }
}