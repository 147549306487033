.form{
    display: flex;
    flex-direction: column;
    &__error{
        display: none;
        font-size: 11px;
        color: #EB5757;
        line-height: 16px;
        margin-top: 14px;
    }
    &.ajax-error{
        .form__error{
            display: block;
        }
    }
    &__row{
        display: flex;
        margin: 0 rem(-10);
        &:not(:last-child){
            margin-bottom: rem(20);
        }
        &--gap{
            margin-top: rem(45);
        }
        &+.form__error{
            margin-top: 0;
        }
    }
    &__input{
        margin: 0 rem(10);
        &--2{
            width: 31.4%;        
        }
        &--3{
            width: 46.9%;
        }
        &--4{
            width: 62.8%;
        }
        &--5{
            width: 79.2%;
        }
        &--6{
            width: 100%;
        }
        &--hidden{
            display: none;
        }
    }
    &__btn{
        margin-top: rem(33);
        .btn{
            width: 100%;
        }
    }
    &__file{
        position: relative;
        label{
            display: flex;
            justify-content: flex-end;
            span{
                display: inline-flex;
            }
        }   
    }
    &__success{
        display: none;
        .cart__price--min {
            padding-top: rem(20);
            img {
                width: rem(25);
                height: rem(30);
                margin-right: rem(11);
            }
        }
    }

    @media(max-width: 768px){
        &__btn{
            text-align: center;
            >*{
                width: 100%;
                max-width: 400px;
            }
        }
        &__row{
            flex-direction: column;
            margin: 0;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            >*:not(:last-child){
                margin-bottom: 10px;
            }
        }
        &__input{
            margin: 0;
            &--2{
                width: 50%;
            }
            &--3{
                width: 83.5%;
            }
            &--4{
                width: 83.5%;
            }
            &--5{
                width: 100%;
            }
        }
    }
    
}

