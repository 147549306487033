.crown-category{
    .container{
        display: flex;
    }
    &:not(:last-child){
        margin-bottom: rem(30);
    }
    &--box{
        .crown-category__bg{
            z-index: 1;
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 55%;
        max-width: 100%;
        line-height: 0;
        z-index: 1;
        transition: opacity .3s ease-in-out;
        opacity: 1;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left bottom;
        }
    }
    &__container{
        position: relative;
        padding: rem(55) rem(67) rem(53) rem(60);
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        flex-basis: 0;
        background: rgba(255, 255, 255, 0.95);
        border: 1px solid #EDEDED;
        min-height: rem(260);
        transition: box-shadow .3s ease-in-out;
        &:hover{
            box-shadow: 0 8px 50px rgba(19, 20, 21, 0.07);
        }
        &:not(:last-child){
            margin-right: rem(20);
        }
        @media (min-width: 769px){
            &:hover{
                .crown-category__bg{
                    opacity: 0.05;
                }
                .crown-category__text{
                    opacity: 1;
                }
            }
            &.has-info:hover{
                .crown-category__bg{
                    opacity: 0.05;
                }
                .crown-category__info{
                    opacity: 1;
                }
            }
        }
    }
    &__content{
        z-index: 2;
        display: block;
    }
    &__info{
        opacity: 0;
        transition: opacity .3s ease-in-out;
    }
    &__title{
        &:not(:last-child){
            margin-bottom: rem(30);
        }
    }
    &__text{
        padding-top: rem(30);
        position: relative;
        max-width: rem(400);
        transition: opacity .3s ease-in-out;
        @media (min-width: 769px) {
            opacity: 0;
        }
        &::after{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: rem(8);
            width: rem(140);
            background-color: var(--primary);
        }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 57.6%;
        margin: 0 -15px rem(-55);
        &+.crown-category__bg{
            width: 40%;
        }
    }
    &__item{
        position: relative;
        text-align: center;
        width: calc(100% / 4 - 30px);
        margin: 0 15px rem(55);
        @media(min-width: 769px){
            &:hover{
                .crown-category__item-title::after{
                    opacity: 1;
                }
            }
        }
    }
    &__item-link{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
    }
    &__item-img{
        position: relative;
        &::before{
            content: "";
            display: block;
            width: 100%;
            padding-top: 83%;
        }
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__item-title{
        font-weight: 600;
        font-size: Max(rem(16), 10px);
        line-height: rem(20);
        position: relative;
        padding-top: rem(10);
        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: rem(4);
            background-color: var(--primary);
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
        }
    }
    &__info {
        margin-top: -10px;
        display: flex;
        flex-direction: column;
        
        .crown-category__text {
            padding-top: 0;
            margin-bottom: 20px;
            max-width: unset;
            &::after {
                content: none;
            }
        }
        
        .crown-category__btn {
            margin-top: auto;
        }
    }
    
    
  


    @media(max-width: 768px){
        &:not(:last-child){
            margin-bottom: 20px;
        }
        .container{
            flex-direction: column;
        }
        &__container{
            padding: 35px 9px 40px;
            flex-direction: column;
            min-height: unset;
            &:not(:last-child){
                margin-right: 0;
                margin-bottom: 20px;
            }
            &.has-info{
                .crown-category__content {
                    margin-bottom: 0;
                }
            }
        }
    
        &__bg {
            top: unset;
            width: 100%;
            height: 140px;
            img {
                object-position: right bottom;
            }
        }
        &--box{
            .crown-category__container{
                box-shadow: none;
            }
        }
        &__content{
            max-width: unset;
            margin-bottom: 52px;
            padding: 0 18px;
        }
        &__title{
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
        &__text{
            padding-top: 0;
            max-width: 100%;
            &::after{
                display: none;
            }
        }
        &__list{
            width: 100%;
            margin: 0 0 -34px;
            &+.crown-category__bg{
                display: none;
            }
        }
        &__item{
            width: calc(100% / 3);
            margin: 0;
            margin-bottom: 34px;
        }
        &__item-img{
            max-height: 130px;
            &::before{
                padding-top: 79.5%;
            }
        }
        &__item-title{
            font-size: 14px;
            line-height: 18px;
            padding-top: 0;
        }
    
        &__info{
            opacity: 1;
            .crown-category__text {
                margin-bottom: 30px;
            }
            
        }
    }
}