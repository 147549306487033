.foropt-find{
    padding-top: rem(30);
    padding-bottom: rem(92);
    &__container{
        display: flex;
    }
    &__content{
        width: 49.5%;
    }
    &__title{
        margin-bottom: rem(28);
    }
    &__text{
        max-width: rem(520);
        >*{
            font-weight: 500 !important;
            color: var(--black) !important;
        }
    }
    &__btn{
        margin-top: rem(52);
        position: relative;
        z-index: 2;
    }
    &__img{
        position: relative;
        left: rem(-155);
        width: rem(615);
        top: -1px;    
        line-height: 0;
        >*{
            width: 100%;
        }
    }

    &__info{
        max-width: rem(420);
        margin-left: rem(70);
    }

    &__form{
        margin-top: rem(38);
        .form__input{
            width: 73.9%;
            &:not(:last-child){
                margin-bottom: rem(12);
            }
        }
        .form__textarea{
            line-height: 0;
            width: 100%;
            margin-bottom: rem(22) !important;
        }
        .form__file{
            width: 100%;
        }
        .form__btn{
            margin-top: rem(42);
            >*{
                min-width: rem(310);
            }
        }
        .section-text{
            margin-bottom: rem(14);
            p{
                color: var(--text-light);
                font-weight: 400;
                font-size: Max(rem(13), 10px);
                line-height: rem(22px);
            }
        }    
    }

    @media(max-width: 768px){
        padding-top: 0;
        padding-bottom: 152px;
        &__container{
            flex-direction: column;
        }
        &__content{
            width: 100%;
            margin-bottom: 30px;
        }
        &__info{
            max-width: unset;
            margin-left: 0;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__btn{
            margin-top: 30px;
            text-align: center;
            >*{
                width: 100%;
                max-width: 400px;
            }
        }
        &__text{
            max-width: unset;
            >*{
                font-weight: 400 !important;
            }
        }
        &__img{
            left: unset;
            width: 92.2%;
            max-height: 300px;
            margin: 0 auto;
            overflow: hidden;
            &::before{
                content: '';
                width: 100%;
                display: block;
                padding-top: 102%;
            }
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__form{
            .section-text{
                margin-bottom: 10px;
            }
            .form__input{
                width: 83.5%;
            }
            .form__textarea{
                width: 100%;
            }
            .form__file{
                width: 100%;
                label{
                    margin-left: 0;
                }
            }
            .form__btn {
                margin-top: 38px;
                >*{
                    min-width: unset;
                }
                
            }    
        }
    }
}




  