.cart{
    padding-top: rem(85);
    padding-bottom: rem(90);
    &__container{
        max-width: rem(934);
        margin: 0 auto;
    }
    &__title{
        margin-bottom: rem(43);
    }
    &__body{
        margin-bottom: rem(41);
    }
    &__bottom{
        padding-top: rem(27);
        border-top: 4px solid var(--gray);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__price{
        text-align: right;
        &--min{
            display: flex;
            align-items: flex-start;
            max-width: rem(383);
            margin-bottom: rem(19);
            img{
                width: rem(32);
                height: rem(32);
                flex-shrink: 0;
                object-fit: contain;
            }
            span{
                font-weight: 700;
            }
        }
    }
    &__form{
        padding-top: rem(52);
        border-top: rem(4) solid #F4F4F4;
        display: flex;
        &-title {
            margin-bottom: rem(35);
        }
        &-subtitle {
            margin-bottom: rem(30);
        }
        &-text {
            margin-left: rem(65);
            flex-basis: 0;
            flex-grow: 1;
            margin-top: rem(135);
        }
        &-inner {
            width: 57%;
        }
        &-privacy{
            margin-top: rem(16);
            &.section-text p {
                color: var(--text-light);
            }
        }

        .form {
            > .section-text {
                margin-bottom: 10px;
                p {
                    color: #828282;
                }
            }
            &__btn .btn {
                width: auto;
                min-width: rem(310);
            }
        }
    }
    &-item{
        display: flex;
        align-items: center;
        &:not(:last-child){
            margin-bottom: rem(25);
        }
        .product-card__content{
            margin-left: unset;
            margin-right: rem(30);
            @media (min-width: 769px) {
                flex-shrink: 0;
                flex-grow: 1;
            }
        }
        .product-card__price{
            margin-bottom: 0;
            &--new{
                font-size: rem(20);
            }
            &--old{
                top: rem(-17);
                font-size: Max(rem(12), 10px);
            }
        }
        .product-card__package >*{
            font-size: Max(rem(12), 10px);
        }
        .quantity{
            &__input{
                width: rem(41);
                font-size: rem(20);
            }
            &__btn{
                width: rem(16);
                height: rem(16);
            }
        }
        &__img{
            display: block;
            width: rem(95);
            height: rem(95);
            margin-right: rem(21);
            flex-shrink: 0;
            >*{
                width: 100%;
                height: 100%!important;
                object-fit: contain;
            }
        }
        &__info{
            max-width: rem(379);
            width: 100%;
            margin-right: rem(40);
            span{
                display: inline-block;
                margin-top: 2px;
                font-weight: 500;
                font-size: Max(rem(14), 10px);
                line-height: rem(16);
            }
        }
        &__title {
            & > * {
                transition: color 0.3s ease-in-out;
            }
            &:not(div){
                &:hover>*{
                    color: var(--primary);
                }
            }
        }
        &__total {
            margin-left: auto;
            margin-right: rem(46);
            font-weight: 700;
            font-size: rem(20);
            line-height: rem(22);
            @media (min-width: 769px) {
                min-width: 20%;
                padding-left: rem(25);

                text-align: right;
                white-space: nowrap;
            }
        }
        &__close{
            display: inline-flex;
            width: rem(12);
            height: rem(12);
            flex-shrink: 0;
            >*{
                width: 100%;
                height: 100%;
                object-fit: contain;
                path{
                    transition: stroke 0.2s ease-in-out;
                }
            }
            &:hover{
                svg path{
                    stroke: var(--primary);
                }
            }
        }

        &:not(.cart-item--smallest){
            .cart-item__info+.quantity{
                margin-left: auto;
            }
            .quantity+.cart-item__close{
                margin-left: auto;
            }
        }
        &.cart-item--smallest{
            padding: rem(10) rem(19) rem(10) rem(10);
            color: var(--black);
            .cart-item__img {
                width: rem(50);
                height: rem(50);
                margin-right: rem(10);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .cart-item__title,
            .cart-item__title>*{
                font-size: rem(14);
                font-weight: 500;
                line-height: rem(18);
            }
            .cart-item__title:last-child,
            .cart-item__title:last-child > *{
                font-size: rem(15);
                line-height: rem(20);
            }
            .product-card__content{
                max-width: rem(226);
                text-align: left;
                margin-right: 0;
                flex-grow: 1;
                margin-left: auto;
            }
            .cart-item__total{
                font-size: rem(20);
                font-weight: 800;
                line-height: rem(25);
                margin-left: 0;
                margin-right: 0;
                min-width: unset;
                padding: 0;
                text-align: left;
            }
            .cart-item__quantity {
                text-align: right;
                font-size: rem(14);
                font-weight: 400;
                line-height: rem(17);
                flex-shrink: 0;
                margin-left: rem(15);
                align-self: flex-start;
            }
        }
    }
    &-item--small{
        &:not(:last-child){
            margin-bottom: rem(20);
        }
        .cart-item__img{
            width: rem(32);
            height: rem(50);
            margin-right: rem(7);
        }
        .cart-item__info{
            max-width: rem(336);
            margin-right: rem(66);
        }
        .cart-item__title >*{
            font-weight: 400;
            font-size: Max(rem(18), 10px);
            line-height: rem(24);
        }
        .cart-item__total{
            margin-right: 0;
            font-weight: 600;
            font-size: Max(rem(18), 10px);
            line-height: rem(17);
        }
        .cart-item__count{
            font-weight: 400;
            font-size: Max(rem(18), 10px);
            line-height: rem(17);
        } 
    }

    &.cart--wholesale{
        padding-bottom: rem(160);
    }

    @media(max-width: 768px){
        padding-top: 24px;
        padding-bottom: 76px;
        &__title{
            margin-bottom: 33px;
        }
        &__body{
            margin-bottom: 20px;
        }
        &__bottom{
            padding-top: 23px;
            flex-direction: column;
        }
        &__price{
            order: -1;
            margin-bottom: 64px;
            &--min{
                max-width: unset;
            }
        }
        &__form {
            padding-top: 45px;
            flex-direction: column;
            &-inner {
                width: 100%;
            }
            &-title {
                margin-bottom: 16px;
            }
            &-privacy {
                margin-top: 20px;
            }
            .form__btn .btn {
                width: 100%;
                min-width: unset;
            }
            &-text {
                margin-left: 0;
                margin-top: 10px;
            }

            &-subtitle {
                margin-bottom: 30px;
            }

            .form {
                margin-top: 30px;
                > .section-text {
                    margin-top: 10px;
                }
            }


        }

        &-item:not(.cart-item--small):not(.cart-item--smallest ){
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: unset;
            justify-content: unset;
            &:not(:last-child){
                margin-bottom: 20px;
            }
            .cart-item{
                &__img{
                    width: 60px;
                    height: 60px;
                    margin-right: 28px;
                    margin-left: 20px;
                }
                &__close{
                    position: absolute;
                    left: 0;
                    top: 10px;
                }
                &__title >*{
                    font-size: 16px;
                    line-height: 22px;
                }
                &__total{
                    font-size: 16px;
                    align-self: center;
                    text-align: right;
                    margin-right: 0;
                    width: auto;
                    margin-left: auto;
                }
                &__info{
                    max-width: unset;
                    width: calc(100% - 108px);
                    margin-right: 0;
                    margin-bottom: 3px;
                    min-height: 60px;
                }
            }
            .product-card__price{
                &--new{
                    font-size: 16px;
                }
                &--old{
                    font-size: 11px;
                }
            }
            .product-card__package >*{
                font-weight: 500;
                font-size: 11px;
                text-align: left;
            }
            .product-card__content{
                margin-right: 0;
                padding-top: 17px;
                width: 108px;
                text-align: left;
            }
            .product-card__price{
                &--old{
                    right: unset;
                    left: 0;
                }
            }
            .quantity{
                margin: 0;
                align-self: center;
                &__input{
                    font-size: 16px;
                }
                &__btn{
                    width: rem(16);
                    height: rem(16);
                }
            }
        }
        &-item--small{
            flex-wrap: wrap;
            position: relative;
            padding-left: 60px;
            min-height: 50px;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 15px;
            }
            .cart-item__info{
                max-width: unset;
                margin-right: 0;
                margin-bottom: 5px;
            }
            .cart-item__img{
                position: absolute;
                margin-right: 0;
                width: 50px;
                left: 0;
                top: 0;
            }
            .cart-item__title >*{
                font-size: 16px;
            }
            .cart-item__total{
                font-size: 16px;
            }
            .cart-item__count{
                font-size: 16px;
            }
        }

        &.cart--wholesale {
            padding-bottom: 110px;
        }
    }
}






// @media (max-width: 768px) {
//     // .cart-item {
//     //   display: flex;
//     //   flex-wrap: wrap;
//     //   align-items: unset;
//     //   justify-content: space-between;
//     // }
//     // .cart-item .product-card__content {
//     //   /* margin-right: 73px; */
//     //   margin-right: 0;
//     //   padding-top: 17px;
//     //   width: calc((100% - 97px) / 2);
//     // }
//     // .cart-item__total {
//     //   align-self: center;
//     //   width: calc((100% - 97px) / 2);
//     //   text-align: right;
//     // }
//   }
  
// //   .cart-item__info {
// //     /* max-width: 23.6875rem; */
// //     /* width: 100%; */
// //     /* margin-right: 2.5rem; */
// //     max-width: unset;
// //     width: calc(100% - 88px);
// //     margin-right: 0;
// //     margin-bottom: 3px;
// //     min-height: 60px;
// //   }
  
// //   .quantity {
// //     margin: 0 17px 0 7px;
// //     align-self: center;
// //   }
  
// //   .cart-item__total {
// //     /* margin-left: auto; */
// //     /* margin-right: 2.875rem; */
// //     margin-left: 0;
// //     margin-right: 0;
// //   }
  
// //   .product-card__content {
// //     /* text-align: right; */
// //     text-align: left;
// //   }
  
// //   .product-card__price--old {
// //     /* right: 0; */
// //     right: unset;
// //     left: 0;
// //   }
  
//   .product-card__package > * {
//     /* text-align: right; */
//     text-align: left;
//   }
  