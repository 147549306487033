.about-gallery{
    padding-top: rem(100);
    padding-bottom: rem(167);
    &__text{
        break-inside: avoid;
        columns: 2;
        gap: rem(70);
        margin-bottom: rem(100);
        max-width: rem(1030);
        >*{
            max-width: rem(480);
            break-inside: avoid;
        }
    }

    @media(max-width: 768px){
        padding-top: 50px;
        padding-bottom: 98px;
        overflow: hidden;
        &__text{
            columns: 1;
            margin-bottom: 50px;
            >*:not(:last-child){
                margin-bottom: 30px;
            }
            >*{
                max-width: unset;
            }
        }
    }
}