.page-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title{
        max-width: rem(750);
    }
    &__link{
        a{
            display: inline-flex;
            font-weight: 600;
            font-size: Max(rem(18), 12px);
            line-height: rem(20);
            color: var(--black);
            transition: color 0.2s ease-in-out;
            &:hover{
                color: var(--primary);
            }
            &:not(:last-child){
                margin-right: rem(30);
            }
            &.active{
                pointer-events: none;
                text-decoration-color: var(--primary) !important;
                text-underline-offset: 8px;
                text-decoration: underline;
                text-decoration-thickness: 4px;

            }
        }
    }

    @media (max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
        overflow: hidden;
        width: calc(100% + 40px);
        margin: 0 -20px;
        &__title{
            max-width: unset;
            margin-bottom: 15px;
            padding: 0 20px;
        }
        &__link{
            display: flex;
            overflow-x: auto;
            width: 100%;
            padding: 0 20px 15px;
            a{
                flex-shrink: 0;
            }
        }
    }
}





  