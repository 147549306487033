.main-banner {
    margin-bottom: rem(30);
    .container.container--full {
        padding: 0;
    }
    &__container {
        display: flex;
        position: relative;
        overflow: hidden;
        @media (min-width: 769px) {
            height: rem(570);
        }
    }
    &__item {
        width: calc(50% - 10px);
        position: absolute;
        top: 0;
        bottom: 0;
        transition: width .3s linear;
        z-index: 1;
        overflow: hidden;
        background: linear-gradient(359.39deg, rgba(255, 255, 255, 0.8) 16.5%, rgba(255, 255, 255, 0) 52.26%), #F8F8F8;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            background: rgba(238, 238, 238, 0.8);
            backdrop-filter: blur(5px);
            transition: opacity .3s ease-in-out;
            width: 100vw;
        }
        &:first-child{
            left: 0;
            .main-banner__item-bg>*{
                right: rem(-450);
            }
            .main-banner__item-content{
                left: var(--container-margin);
                right: rem(140);
            }
            .main-banner__item-hidden{
                left: calc(50vw - 10px);
            }
            .btn{
                left: 100%;
            }
            &::after{
                right: 0;
                transform: translateX(100%);
            }
        }
        &:last-child{
            right: 0;
            .main-banner__item-bg>*{
                left: rem(-450);
            }
            .main-banner__item-content{
                left: rem(140);
                right: var(--container-margin);
            }
            .main-banner__item-hidden{
                right: calc(50vw - 10px);
            }
            .btn{
                right: 100%;
            }
            &::after{
                left: 0;
                transform: translateX(-100%);
            }
        }


        @media (min-width: 769px) {
            &:hover{
                width: calc(50% + rem(440));
                &::after{
                    opacity: 1;
                }

                &:first-child{
                    .main-banner__item-content{
                        right: rem(429);
                    }
                    .main-banner__item-bg>*{
                        right: 0;
                    }
                }
                &:last-child{
                    .main-banner__item-content{
                        left: rem(420);
                    }
                    .main-banner__item-bg>*{
                        left: 0;
                    }
                }
                .title-link {
                    .btn {

                        img {
                            opacity: 0;
                        }

                        & > *:not(img) {
                            opacity: 1;
                        }
                    }
                }
                .main-banner__item-hidden{
                    opacity: 1;
                    transition: opacity .3s linear .3s;
                }
            }
            &:last-child{
                .main-banner__item-hidden{
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
                .main-banner__item-title {
                    flex-direction: row-reverse;
                    text-align: right;
                    .title-link {
                        margin-right: 4.375rem;
                        margin-left: 0;
                        flex-direction: row-reverse;
                        .btn img {
                            transform: rotate(180deg) translate(50%, 50%);
                        }
                    }
                }
            }
            &-hidden .section-text{
                margin-top: auto;
                margin-bottom: rem(100);
            }
        }
        &-bg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            width: 100%;
            z-index: 1;
            &>*{
                position: absolute;
                top: 0;
                bottom: 0;
                height: 100%;
                max-width: unset;
                width: auto;
                transition: left .3s linear, right .3s linear;
            }
        }
        &-content {
            position: absolute;
            bottom: rem(57);
            right: 0;
            z-index: 3;
            transition: left .3s linear, right .3s linear;
        }
        &-title{
            display: flex;
            align-items: flex-end;

            .title-h3 {
                flex-shrink: 0;
            }
            .title-link{
                min-height: rem(54);
                max-width: 100%;
                flex-grow: 1;
                margin-left: rem(70);
                transition: max-width .5s ease-in-out;
                .btn{
                    position: absolute;
                    width: rem(54);
                    padding: rem(16) rem(26);
                    overflow: hidden;
                    border-radius: rem(30);
                    transition: border-radius .3s ease-in-out, width .3s ease-in-out;
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: opacity .3s ease-in-out;
                        width: 45%;
                        height: 45%;
                        object-fit: contain;
                    }
                    &>*:not(img){
                        opacity: 0;
                        white-space: nowrap;
                        transition: opacity .3s ease-in-out;
                    }
                }
            }
        }
        &-hidden {
            position: absolute;
            top: 0;
            bottom: 0;
            width: rem(440);
            padding: rem(113) rem(30) rem(57) rem(20);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            opacity: 0;
            transition: opacity .2s linear;
            z-index: 2;

            .section-text{
                max-width: rem(330);
            }
        }
    }


    @media (max-width: 768px) {
        margin-bottom: 0;
        &__container {
            flex-direction: column;
        }

        &__item:first-child {
            margin-right: 0;
            margin-bottom: 10px;
        }

        &__item {
            width: 100%;
            position: relative;
        }

        &__item::after {
            backdrop-filter: none;
            opacity: 1;
        }

        &__item-bg {
            z-index: 1;
        }

        &__item-hidden {
            background: #E8E8E8B0;
            background-blend-mode: multiply;
        }

        &__item-content {
            display: none;
        }

        &__item-bg__inner::before {
            padding-top: 0;
        }

        &__item-bg__inner {
            max-height: unset;
        }

        &__item-hidden {
            max-width: 100%;
            padding: 100px 22px 40px;
            min-height: 530px;
            position: relative;
            justify-content: flex-end;
            br{
                display: none;
            }
            [class*="title-"]{
                margin-bottom: 15px;
            }
            .section-text{
                max-width: unset;
            }
            &__btn{
                margin-top: 30px;
                width: 100%;
                text-align: center;
                >*{
                    max-width: 400px;
                    width: 100%;
                }
            }
        }

        &__item-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__item-hidden > * {
            opacity: 1;
        }

        .main-banner__item:first-child .main-banner__item-hidden {
            left: 0;
        }

        .main-banner__item-hidden {
            opacity: 1;
            z-index: 7;
            width: 100%;
        }

        .main-banner__container::before {
            content: none;
        }

        .main-banner__item:last-child .main-banner__item-hidden {
            right: 0;
        }

    }
}
  