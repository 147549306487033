.blog-single{
    padding-top: rem(76);
    padding-bottom: rem(60);
    &__info{
        margin-bottom: rem(60);
    }
    &__img{
        position: relative;
        width: 72%;
        overflow: hidden;
        margin-bottom: rem(60);
        >*{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 61.6%;
        }
    }
    &__title{
        margin-bottom: rem(12);
    }
    &__text{
        margin-bottom: rem(90);
    }
    &__back{
        margin-top: rem(200);
    }

    @media(max-width: 768px){
        padding-top: 24px;
        &__title{
            padding: 0 !important;
        }
        &__info{
            margin-bottom: 23px;
        }
        &__img{
            width: 100%;
            margin-bottom: 23px;
            max-height: 350px;
        }
        &__text{
            margin-bottom: 35px;
        }
        &__back{
            margin-top: 58px;
        }
    }
}