.checkout{
  padding-bottom: rem(116);
  padding-top: rem(75);
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__col {
    width: calc(50% - rem(65));
  }
  &__title {
    margin-bottom: rem(40);
  }
  &__subtitle {
    margin-bottom: rem(25);
  }
  &__text {
    margin-top: rem(35);
    margin-bottom: rem(33)!important;
  }
  &__block {
    &:not(:last-child) {
      margin-bottom: rem(60);
    }
  }
  &__mobile{
    display: none;
  }
  &__radio{

    .wcus-checkout-fields {
      margin-top: 10px;
      margin-left: 43px;
      width: calc(100% - 43px);
      position: relative;
      > h3 {
        color: #828282;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .input__arrow {
        position: absolute;
        right: 10px;
        bottom: 17px;
        width: 10px;
        height: 10px;
        transition: transform .3s ease,color .3s ease;
        line-height: 0;
        >*{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .input{
      display: flex;
    }
    .form-row {
      padding: 0;
      margin: 0;
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: rem(25);
      }
      &+.input:not(:last-child) {
        margin-bottom: rem(25);
      }
      a{
        outline: none!important;
        font-weight: 500;
        color: var(--black);
        text-decoration-color: var(--primary) !important;
        text-underline-offset: 4px;
        text-decoration: underline;
        transition: color 0.2s ease-in-out;
        &:hover{
          color: var(--primary);
        }
      }
    }
  }
  &__subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: rem(15);
    line-height: rem(20);
    color: var(--black);
    margin-bottom: rem(30);
    &>*:last-child{
      font-weight: 700;
      font-size: rem(18);
      line-height: rem(22);
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: rem(20);
    line-height: 20px;
    color: var(--black);
    margin-bottom: 47px;
    padding-top: 25px;
    margin-top: 30px;
    border-top: 4px solid #F4F4F4;
    &>*:last-child{
      font-weight: 700;
      font-size: 30px;
      line-height: 22px;
    }
  }
  &__review {
    padding: 30px 40px 40px;
    border: 4px solid #F8F8F8;
    position: relative;
    .cart-item.cart-item--small{
      &:not(:last-child){
        margin-bottom: 10px;
      }
      .cart-item__img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      .cart-item__count,
      .cart-item__total,
      .cart-item__title > * {
        font-size: max(15px, 10px);
        line-height: 19px;
      }
    }
    &-list {
      padding-bottom: 24px;
      margin-bottom: 13px;
      border-bottom: 2px solid #F4F4F4;
    }
    &-back {
      position: absolute;
      background: #fff;
      right: 30px;
      padding: 0 10px;
      top: -17px;
      img{
        margin-right: 8px;
      }
    }
    &-info{
      &>*{
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        &:not(:last-child){
          margin-bottom: rem(20);
        }
        &>*:first-child>* {
          color: #828282;
        }
        &>*:nth-child(2) {
          text-align: right;
          font-weight: 600;
        }
      }
    }
  }

  &__btn {
    > button {
      width: 100%;
    }
    .hidden{
      display: none;
    }
  }
  &__descr {
    margin-top: 16px;
    .section-text p {
      color: #828282;
    }

    .section-text--small p {
      font-size: max(13px, 10px) !important;
    }
  }
  &__code{
    &.checkout__total {
      padding-top: 14px;
      margin-bottom: -16px;
      display: flex;
      flex-direction: column;
    }
    .woocommerce-form-coupon-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &:not(.is-open)+*{
        display: none!important;
      }
    }
    .checkout_coupon {
      display: none;
      border: none!important;
      padding: 0!important;
      margin: 0!important;
      border-radius: 0!important;
      width: 100%;
    }
    &-input{
      position: relative;
      padding: 16px 0!important;
      input {
        width: 100%;
        height: 46px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border-radius: 18px;
        border: 1px solid var(--text-white);
        background: #FFF;
        padding: 0 144px 0 16px;
      }

      .btn {
        position: absolute;
        top: 20px;
        right: 4px;
        bottom: 20px;
      }
    }
    &-error{
      font-size: 11px !important;
      line-height: normal !important;
      color: #EB5757;
      text-align: left;
      width: 100%;
      font-weight: normal !important;
    }
  }
  .form-row.place-order {
    position: absolute;
    left: -9999px;
  }


  @media (max-width: 768px) {
    padding-bottom: 30px;
    padding-top: 24px;

    &__col {
      width: 100%;
      &-inner{
        overflow-y: auto;
        height: 100%;
        margin-right: -15px;
        padding-right: 15px;
      }
    }
    &__total {
      font-size: 16px;
      margin-bottom: 20px;
      padding-top: 10px;
      &:first-child {
        margin: 0 0 11px;
        border: none;
        padding: 0;
      }
      > :last-child {
        font-size: 25px;
        line-height: 40px;
      }
      a:not(.btn) {
        color: var(--primary);
        padding-left: 14px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 9px;
          height: 9px;
          background-image: url('data:image/svg+xml,%3Csvg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 8L7.24329 3.82588C6.84605 3.3845 6.15395 3.3845 5.75671 3.82588L2 8" stroke="%23FEB11C" stroke-width="2" stroke-linecap="square"/%3E%3C/svg%3E');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    &__mobile {
      display: block;
      padding-bottom: 24px;
      &-btn .btn {
        width: 100%;
      }
    }
    &__review {
      padding: 0;
      border: none;
      &-back {
        display: none;
      }
      &-list {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      &-info > :not(:last-child) {
        margin-bottom: 15px;
      }
    }
    &__descr {
      margin-top: 20px;
    }


    .woocommerce-checkout-review{
      &-order {
        display: none;
        padding: 26px 0 53px;
        .checkout__subtitle {
          position: relative;
          margin-bottom: 32px;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            right: 0;
            background-image: url('data:image/svg+xml,%3Csvg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3L11.7474 11.1592C11.3496 11.6067 10.6504 11.6067 10.2526 11.1592L3 3" stroke="%23FEB11C" stroke-width="4" stroke-linecap="square"/%3E%3C/svg%3E');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &-table {
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--White, #FFF);
          box-shadow: 0px -8px 50px 0px rgba(19, 20, 21, 0.15);
          padding: 10px 20px 0;
          max-height: calc(100% - 80px);
          transition: height .3s ease-in-out .3s;
          z-index: 10000;
          display: flex;
          flex-direction: column;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100vh;
            transform: translateY(-100%);
            background: rgba(238, 238, 238, 0.80);
            backdrop-filter: blur(5px);
            z-index: 99999;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s ease-in-out .6s, opacity .3s ease-in-out .6s;
          }
          &.is-expanded::before{
            transition-delay: 0s;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.is-hidden{
  display: none !important;
}