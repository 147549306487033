.banner-dist{
    padding-top: rem(92);
    padding-bottom: rem(90);
    &__container{
        display: flex;
        max-width: rem(1106);
        padding-right: rem(90);
        margin: 0 auto;
        width: 100%;
    }
    &__img{
        position: relative;
        margin-right: rem(126);
        width: rem(327);
        flex-shrink: 0;
        overflow: hidden;
        &::before{
            content: '';
            width: 100%;
            display: block;
            padding-top: 88%;
        }
        >*{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:first-child{
                z-index: 2;
                max-width: unset;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                left: -1px;
                top: -1px;
            }
            &:last-child{
                left: 1px;
                top: 1px;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
            }
        }
    }
    &__title{
        margin-bottom: rem(20);
    }


    @media(max-width: 768px){
        padding: 50px 0;
        &__container{
            flex-direction: column;
            max-width: unset;
            padding-right: 0;
            margin: 0;
        }
        &__img{
            margin-bottom: 35px;
            margin-right: 0;
            width: 211px;
        }
    }
}