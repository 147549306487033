.products{
    padding-bottom: rem(20);
    &__item{
        background-color: #f8f8f8;
        position: relative;
        overflow: hidden;
        &:hover{
            .products__picture{
                opacity: 1;
                transform: translate(0, -50%);
            }
        }
    }
    &__picture{
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        height: auto;
        top: calc(50% - var(--translateY));
        width: 33%;
        z-index: 2;
        >img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &.products__picture{
            &--left{
                left: 0;
                transform: translate(-100%, -50%);
            }
            &--right{
                right: 0;
                transform: translate(100%, -50%);
            }
        }
    }
    &__content{
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding-bottom: rem(42);
        overflow: hidden;
    }
    &__inner{
        position: relative;
        overflow: hidden;
        &::before{
            content: '';
            width: 100%;
            display: block;
            padding-top: 50.1%;
        }
        @media (min-width: 769px) {
            max-height: 900px;
        }
    }
    &__container{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: rem(20);
        grid-row-gap: rem(20);
        @media(min-width: 769px){
            >*{
                &:nth-child(1){
                    grid-area: 1 / 1 / 5 / 2;
                }
                &:nth-child(2){
                    grid-area: 1 / 2 / 3 / 3;
                }
                &:nth-child(3){
                    grid-area: 3 / 2 / 5 / 3; 
                }
                &:nth-child(4){
                    grid-area: 1 / 3 / 4 / 4;
                }
                &:nth-child(5){
                    grid-area: 4 / 3 / 5 / 4;
                    min-height: rem(223);
                }
                &:nth-child(6){
                    grid-area: 1 / 4 / 3 / 5;
                }
                &:nth-child(7){
                    grid-area: 3 / 4 / 5 / 5;
                }
            } 
        }
    }
    &__img{
        width: 100%;
        position: relative;
        flex-grow: 1;
        >img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            position: absolute;
            top: 0;
            left: 50%;
            max-width: 100%;
            transform: translateX(-50%);
        }
    }
    &__text{
        z-index: 1;
        margin: 0 auto;
        max-width: rem(255); 
        width: 83%;
        min-height: rem(46);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        >*{
            text-align: center;
            font-weight: 700;
            font-size: rem(19);
            line-height: rem(23);
            letter-spacing: -0.02em;
            color: var(--black);
        }
    }
    &--small{
        .products__container{
            position: static;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px rem(-20);
            grid-column-gap: unset;
            grid-row-gap: unset;
            &>*{
                width: calc(100% / 4 - 20px);
                margin: 0 10px rem(20);
            }
        }
        .products__inner::before{
            display: none;
        }
        .products__item{
            position: relative;
            min-height: unset;
            &::before{
                content: "";
                display: block;
                width: 100%;
                padding-top: 111.5%;
            }
        }
        .products__inner{
            max-height: unset;
        }
        .products__content{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        .products__img{
            >img{
                max-width: 58%;
            }
        }
    }


    @media(max-width: 768px){
        &__container{
            display: flex;
            flex-direction: column;
            position: static;
        }
        &__inner{
            &::before{
                display: none;
            }
        }
        &__content{
            flex-direction: row;
            padding-bottom: 0;
        }
        &__item{
            padding-right: 20px;
        }
        &__text{
            position: static;
            display: flex;
            align-items: center;
            max-width: unset;
            justify-content: unset;
            text-align: left;
            padding-left: 20px;
            >*{
                text-align: left;
            }
        }
        &__picture{
            transform: translate(0, -50%) !important;
            opacity: 1;
        }
        
        &__img{
            position: relative;
            flex-shrink: 0;
            width: 35.7%;
            max-width: 200px;
            max-height: 273px;
            >img{
                width: 92%;
                height: auto;
                object-fit: contain;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: unset; 
            }
            &::before{
                content: '';
                width: 100%;
                display: block;
                padding-top: 155%;
            }
        }

        &--small{
            .products__container{
                margin: 0;
                &>*{
                    width: 100%;
                    margin: 0;
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }
            }
            .products__item{
                max-height: 273px;
                &::before{
                    padding-top: 55.3%;
                }
            }
        }
    }
}






  

    