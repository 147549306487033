.product-card{
    border: 1px solid #EDEDED;
    flex-shrink: 0;
    position: relative;
    transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
    &.is-favorite .product-card__favorite{
        svg path{
            fill: var(--primary);
            stroke: var(--primary);
        }
    }
    &.is-loading {
        cursor: progress;
        > * {
            pointer-events: none;
        }
        @media(min-width: 769px) {
            box-shadow: 0px 8px 50px rgba(19, 20, 21, 0.07);

            .product-card__buttons {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &.is-unavailable{
        .product-card__link{
            opacity: 0.35;
        }
        @media (any-hover: hover) {
            .product-card__link{
                transition: opacity .3s ease-in-out;
            }
            &:hover{
                .product-card__link{
                    opacity: 1;
                }
            }
        }
        .product-card__row>.btn{
            width: 100%;
        }
        .product-card__row{
            justify-content: center;
        }
    }
    .quantity{
        flex-grow: 1;
        justify-content: center;
    }
    &__top{
        position: absolute;
        left: 0;
        right: rem(20);
        top: rem(20);
        display: flex;
        justify-content: space-between;
        z-index: 3;
        height: 0;
    }
    &__tags{
        flex-direction: column;
        display: flex;
    }
    &__favorite{
        width: rem(23);
        height: rem(23);
        position: absolute;
        right: 0;
        cursor: pointer;
        &:not(.is-added){
            .cross-line{
                display: none;
            }
        }
        svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
            path{
                transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
            }
        }
        &:hover{
            svg path{
                stroke: var(--primary);
            }
        }
    }
    &__link{
        display: block;
        position: relative;
        padding: rem(20) rem(20) rem(17);
        z-index: 2;
        &:hover{
            .product-card__title{
                color: var(--primary);
            }
        }
    }
    &__img{
        position: relative;
        max-width: rem(214);
        margin: 0 auto rem(20);
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
            z-index: -1;
        }
        &::before{
            content: '';
            width: 100%;
            display: block;
            padding-top: 111.8%;
        }
    }
    &__info{
        text-align: center;
    }
    &__about{
        margin-top: rem(22);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__spec{
        margin-right: rem(10);
        li{
            display: flex;
            align-items: center;
            &:not(:last-child){
                margin-bottom: rem(7);
            }
            span{
                display: inline-block;
                font-weight: 300;
                font-size: Max(rem(12),10px);
                line-height: rem(16);
                color: var(--text-light);
            }
            img{
                width: rem(14);
                height: rem(16);
                object-fit: contain;
                margin-right: rem(5);
            }
        }
    }
    &__content{
        margin-left: auto;
        text-align: right;
    }
    &__price{
        position: relative;
        margin-bottom: rem(11);
        &--old{
            position: absolute;
            right: 0;
            top: rem(-22);
            font-weight: 600;
            font-size: Max(rem(13), 10px);
            line-height: rem(22);
            text-decoration-line: line-through;
            color: var(--red);
        }
        &--new{
            font-weight: 700;
            font-size: rem(25);
            line-height: rem(22);
            color: var(--black);
        }
    }
    &__package >*{
        font-weight: 500;
        font-size: Max(rem(13), 10px);
        line-height: rem(15);
        text-align: right;
        color: var(--text);
    }
    &__buttons{
        position: absolute;
        transform: translateY(100%);
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 rem(20) rem(20);
        background: var(--white);
        box-shadow: 0px rem(8) rem(50) rgba(19, 20, 21, 0.07);
        opacity: 0;
        z-index: 1;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
        a:not(.btn){
            display: flex;
            justify-content: center;
            margin-top: rem(15);
            font-weight: 500;
            font-size: Max(rem(13), 10px);
            line-height: rem(20);
            text-align: center;
        }
        &::after{
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            top: rem(-68);
            height: rem(68);
            z-index: 1;
            background-color: var(--white);
        }
    }
    &__row{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -10px rem(-10);
        >*{
            margin: 0 10px rem(10);
        }
        .btn{
            flex-grow: 1;
        }
    }
    &__unavailable{
        text-align: center;
        &:not(:last-child) {
            margin-bottom: rem(18);
        }
        >*{
            font-weight: 400;
            font-size: Max(rem(14), 10px);
            line-height: rem(14);
            color: #EB5757;
        }
    }
    &__title{
        margin-bottom: rem(7);
        color: var(--black);
        font-weight: 700;
        font-size: Max(rem(16), 10px);
        line-height: rem(20);
        transition: color 0.3s ease-in-out;
    }
    &__text{
        font-weight: 600;
        font-size: Max(rem(12), 10px);
        line-height: 14px;
        color: var(--text-lighter);
    }

    @media(min-width: 769px){
        &:hover{
            box-shadow: 0px 8px 50px rgba(19, 20, 21, 0.07);
            .product-card__buttons{
                opacity: 1;
                visibility: visible;
            }
        }
        &.product-card--small {
            .product-card__img::before {
                padding-top: 82%;
            }

            .product-card__img {
                margin-bottom: 24px;
            }

            .product-card__title {
                margin-bottom: 4px;
            }

            .product-card__link {
                padding: 1.25rem 1.25rem 14px;
            }
        }
    }
    @media(max-width: 768px){
        &__img{
            max-height: 300px;
        }
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
