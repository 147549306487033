.error{
    min-height: Min(100vh, rem(1080));
    position: relative;
    padding: rem(85) 0;
    &__bg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        img{
            width: 100%;
            height: 80%;
            object-fit: contain;
        }
    }
    &__content{
        position: relative;
        z-index: 1;
    }
    &__title{
        margin-bottom: rem(41);
    }
    &__text{
        max-width: rem(280);
    }
    &__img{
        display: none;
    }

    @media(max-width: 768px){
        padding-top: 24px;
        padding-bottom: 118px;
        min-height: unset;
        &__bg{
            display: none;
        }
        &__title{
            margin-bottom: 30px;
        }
        &__img{
            display: block;
            position: relative;
            margin-bottom: 30px;
            max-height: 300px;
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 62.6%;
            }
            >*{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__text{
            max-width: unset;
        }
    }
}