.contact{
    padding-top: rem(73);
    &__title{
        margin-bottom: rem(67);
    }
    &__container{
        display: flex;
        margin-bottom: rem(56);
    }
    &__place{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: rem(80);
        li{
            &:not(:last-child){
                margin-right: rem(30);
            }
            &.is-active .contact__subtitle::after{
                width: rem(100);
            }
        }
        address{
            font-weight: 400;
            font-size: rem(20);
            line-height: rem(30);
            letter-spacing: -0.01em;
            margin-bottom: rem(15);
            cursor: pointer;
        }
    }
    &__subtitle{
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: Max(rem(16), 12px);
        line-height: rem(30);
        letter-spacing: -0.01em;
        color: var(--text-lighter);
        margin-bottom: rem(10);
        &::after{
            content: "";
            display: block;
            margin-left: 17px;
            max-width: 100%;
            height: rem(4);
            width: 0;
            background-color: var(--primary);
            transition: width 0.3s ease-in-out;
        }
    }
    &__mail{
        margin-bottom: rem(34);
        text-decoration-color: var(--primary) !important;
        text-underline-offset: 8px;
        text-decoration: underline;
        >*{
            font-weight: 400;
            font-size: rem(20);
            line-height: rem(30);
            letter-spacing: -0.01em;
            color: var(--black); 
            transition: color 0.2s ease-in-out;
        }
        &:hover{
            >*{
                color: var(--primary); 
            }   
        }
    }
    &__plan{
        margin-top: rem(-10);
        margin-bottom: rem(15);
        text-decoration-color: var(--primary) !important;
        text-underline-offset: 5px;
        text-decoration: underline;
        >*{
            font-weight: 500;
            font-size: Max(rem(14), 10px);
            line-height: rem(20);
            color: var(--black); 
            transition: color 0.2s ease-in-out;
        }
        &:hover{
            >*{
                color: var(--primary); 
            }   
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        font-weight: 300;
        font-size: Max(rem(14), 10px);
        line-height: rem(20);
        color: var(--text-light);
    }
    &__phone{
        flex-shrink: 0;
        .contact__subtitle::after{
            display: none;
        }
        &-number{
            &:not(:last-child){
                margin-bottom: rem(15);
            }
            a{
                font-weight: 600;
                font-size: rem(20);
                line-height: rem(30);
                letter-spacing: -0.05em;
                color: var(--black);
                transition: color 0.2s ease-in-out;
                &:hover{
                    color: var(--primary); 
                }
            }
            span{
                margin-top: rem(5);
            }
        }
    }
    &__map{
        height: 560px;
        width: 100%;
    }

    // @media(max-width: 1440px){
    //     &__place{
    //         li{
    //             &:not(:last-child){
    //                 margin-right: rem(50);
    //             }
    //         }
    //     }
    // }
    @media(max-width: 768px){
        padding-top: 24px;
        &__title{
            margin-bottom: 50px;
        }
        &__container{
            flex-direction: column;
            margin-bottom: 70px;
        }
        &__place{
            flex-direction: column;
            margin-right: 0;
            margin-bottom: 40px;
            li{
                &:not(:last-child){
                    margin-right: 0;
                    margin-bottom: 40px;
                }
            }
            address{
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 10px;
            }
        }
        &__mail {
            margin-bottom: 30px;
            >*{
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 10px;
            }
        }
        &__btn{
            >*{
                width: 100%;
                max-width: 400px;
            }
        }
        &__plan{
            margin-top: -5px;
        }
        &__phone-number{
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}

