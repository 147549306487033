.section-text{
    h2{
        font-weight: 600;
        font-size: rem(35);
        line-height: rem(55);
        margin-top: rem(60);
        margin-bottom: rem(25);
        color: var(--black);
    }
    p{
        font-size: Max(rem(16), 11px);
        line-height: rem(28);
        font-weight: 400;
        max-width: rem(750);
        color: var(--text);
        &:not(:last-child){
            margin-bottom: rem(35);
        }
        &+ol,
        &+ul{
            margin-top: rem(-10) !important;
        }
    }
    ul,
    ol{
        max-width: rem(750);
        &:not(:first-child){
            margin-top: rem(25);
        }
        &:not(:last-child){
            margin-bottom: rem(35);
        }
    }
    ul{
        li{
            padding-left: rem(45);
            position: relative;
            &:not(:last-child){
                margin-bottom: rem(16);
            }
            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: rem(13);
                width: rem(30);
                height: rem(4);
                background-color: var(--primary);
            }
        }
    }
    ol{
        list-style: none;
        counter-reset: listCounter;
        li{
            margin-left: rem(45);
            counter-increment: listCounter;
            position: relative;
            &:not(:last-child){
                margin-bottom: rem(16);
            }
            &::before{
                content: counter(listCounter);
                position: absolute;
                left: rem(-15);
                top: 0;
                transform: translateX(-100%);
                font-weight: 900;
                font-size: rem(24);
                line-height: rem(28);
                color: var(--primary);
            }
        }
    }
    a{
        outline: none!important;
        font-weight: 500;
        color: var(--black);
        text-decoration-color: var(--primary) !important;
        text-underline-offset: 4px;
        text-decoration: underline;
        transition: color 0.2s ease-in-out;
        &:hover{
            color: var(--primary);
        }
    }

    table{
        margin-top: rem(60);
        margin-bottom: rem(90);
        max-width: rem(860);
        width: 100%;
        color: var(--text);
        border-collapse: collapse;
        thead{
            border-bottom: 4px solid var(--gray);
        }
        th{
            text-align: left;
            padding-bottom: rem(10);
        }
        td{
            padding-top: rem(25);
        }
        td,
        th{
            vertical-align: top
        }
        th:not(:last-child),
        td:not(:last-child){
            padding-right: 2%;

            &:nth-child(1){
                width: 23%;
            }
            &:nth-child(2){
                width: 38.5%;
                padding-right: 13%;
            }
        }
    }
    &__table{
        width: calc(100% + 40px);
        margin: 0 -20px;
        overflow-x: auto;
        padding: 0 20px;
    }
    &--big{
        p{
            font-size: Max(rem(18), 12px);
            line-height: rem(30);
            color: var(--black);
        }
        
    }
    &--small{
        p{
            font-size: Max(rem(13), 10px) !important;
            line-height: rem(22);
        }
        
    }
    &__brands{
        display: flex;
        flex-wrap: wrap;
        margin: rem(9) rem(-15) rem(30);
        >*{
            margin: 0 rem(15) rem(30);
            width: rem(148);
            height: auto;
            object-fit: contain;
        }
    }

    @media(max-width: 768px){
        h2{
            margin-bottom: 20px;
            margin-top: 40px;
            font-size: 26px;
            line-height: 35px;
            letter-spacing: -0.02em;
        }
        p{
            font-size: 14px;
            line-height: 26px;
            &:not(:last-child){
                margin-bottom: 30px;
            }
        }
        &__brands{
            margin-bottom: 20px;
        }
        &--big{
            p{
                font-size: 16px;
                line-height: 28px;
            }
            
        }
        &--small{
            p{
                line-height: 22px;
            }
        }
        ul,
        ol{
            max-width: unset;
            font-size: 14px;
            line-height: 26px;
            &:not(:first-child){
                margin-top: 20px;
            }
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
        ul{
            li{
                &::after{
                    width: 25px;
                }
            }
        }
        ol{
            li{
                &::before{
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 900;
                }
            }
        }
        table{
            margin: 40px 0;
            min-width: 526px;
            td{
                padding-top: 20px;
            }
            >*{
                font-size: 14px;
                line-height: 26px;
            }
            th:not(:last-child),
            td:not(:last-child){
                &:nth-child(2){
                    width: 35.5%;
                    padding-right: 7%;
                }
                &:nth-child(1){
                    width: 20%;
                }
            }
        }
    }
}

.section-link{
    display: inline-block;
    color: var(--black);
    text-decoration: underline !important;
    text-decoration-color: var(--primary) !important;
    text-underline-offset: rem(5);
    transition: color 0.2s ease-in-out;
    &:hover{
        color: var(--primary);
    }
}

.section-data{
    font-weight: 500;
    font-size: Max(rem(14), 12px);
    line-height: rem(14);
    color: var(--text-lighter);
    span{
        position: relative;
        margin-left: rem(20);
        padding-left: rem(74);
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: rem(54);
            height: rem(4);
            background-color: var(--gray);
        }
    }
}

.section-arrow{
    display: inline-flex;
    align-items: center;
    line-height: 0;
    span{
        display: inline;
        margin-right: rem(10);
        font-weight: 600;
        font-size: Max(rem(16), 10px);
        line-height: rem(18);
        color: var(--primary);
    }
    &__img{
        width: rem(10);
        height: rem(10);
        >*{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &:hover{
        text-decoration: underline;
        text-decoration-color: var(--primary);
        text-underline-offset: 5px;
    }

}