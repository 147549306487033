.similiar-products{
    padding-bottom: rem(93);
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px rem(-10);
        .product-card{
            width: calc(100% / 5 - 10px);
            margin: 0 5px rem(10);
        }
    }
    &__title{
        margin-bottom: rem(35);
    }

    @media(max-width: 768px){
        padding-bottom: 62px;
        &__title{
            margin-bottom: 15px;
        }
        &__container{
            margin: 0;
            .product-card{
                width: 100%;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
        }
    }
}