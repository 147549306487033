.favourites{
    padding-top: rem(85);
    padding-bottom: rem(133);
    &__title{
        margin-bottom: rem(21);
    }
    .section-text p{
        max-width: rem(1010);
    }
    &__container{
        margin-top: rem(26);
        margin-bottom: rem(75);
        display: flex;
        flex-direction: column;
    }
    .dropdown-click{
        margin-bottom: rem(26);
        margin-left: auto;
    }
    &__cards{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px rem(-10);
        >*{
            width: calc(100% / 5 - 10px);
            margin: 0 5px rem(10);
        }
    }
    @media(max-width: 1080px){
        &__cards{
            >*{
                width: calc(100% / 4 - 10px);
            }
        }
    }
    @media(max-width: 768px){
        padding-top: 24px;
        padding-bottom: 137px;
        &__title{
            margin-bottom: 30px;
        }
        &__container{
            margin-top: 50px;
            margin-bottom: 50px;
        }
        &__cards{
            margin: 0;
            >*{
                width: 100%;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
        }
    }
}