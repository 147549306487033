.gallery{
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-20) rem(-40);
    }
    &__link{
        position: relative;
        display: inline-block;
        margin: 0 rem(20) rem(40);
        width: calc(100% / 3 - rem(40));
        line-height: 0;
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 70%;
        }
        >*{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media(max-width: 768px){
        overflow: hidden;
        width: calc(100% + 40px);
        margin: 0 -20px;
        &__container{
            margin: 0;
            flex-wrap: nowrap;
            overflow-x: auto;
            width: 100%;
            padding: 0 20px 15px;
        }
        &__link{
            margin: 0;
            width: 213px;
            flex-shrink: 0;
            &:not(:last-child){
                margin-right: 10px;
            }
            &::before{
                padding-top: 70%;
            }
        }
    }
}
