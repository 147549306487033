.register{
    padding-top: rem(76);
    padding-bottom: rem(150);
    min-height: Min(100vh, 1080px);
    display: flex;
    align-items: center;

    .success-message{
        display: none;
        margin-bottom: rem(100);
    }
    &__title{
        margin-bottom: rem(55);
    }
    &__container{
        max-width: rem(650);
        margin: 0 auto;
    }
    &__form-row{
        margin-bottom: rem(45);
        .input--select.is-open{
            border-bottom-right-radius: 0;
        }
    }
    &__btn{
        max-width: rem(310);
        margin-bottom: rem(16);
        >*{
            width: 100%;
        }
    }
    .section-text{
        max-width: rem(465);
        &:not(:last-child){
            margin-bottom: rem(10);
        }
    }

    @media(max-width: 768px){
        padding-top: 24px;
        padding-bottom: 100px;
        &__title{
            margin-bottom: 57px;
        }
        &__row{
            margin-bottom: 50px;
        }
        &__btn{
            margin-bottom: 20px;
            text-align: center;
            max-width: unset;
            >*{
                width: 100%;
                max-width: 400px;
            }
        }
        .section-text{
            max-width: unset;
        }
    }
}