.catalog-blog{
    padding-top: rem(75);
    padding-bottom: rem(164);
    overflow: hidden;
    &__head{
        margin-bottom: rem(65);
    }
    &__content{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -50px rem(-60);
        padding-right: rem(28);
        >*{
            display: inline-flex;
            width: calc((100% / 3 - 100px));
            max-width: rem(340);
            margin: 0 50px rem(60);
            transition: color 0.2s ease-in-out;
            &:hover{
                .catalog-blog__title{
                    color: var(--primary);
                }
            }
        }
    }
    &__item{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__img{
        position: relative;
        width: rem(340);
        flex-shrink: 0;
        margin-bottom: rem(19);
        >*{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 59%;
        }
    }
    &__title{
        font-weight: 600;
        font-size: Max(rem(19), 13px);
        max-width: rem(310);
        margin-top: rem(10);
        line-height: rem(28);
        color: var(--black);
        transition: color 0.2s ease-in-out;
    }
    &__pagination{
        margin-top: rem(98);
    }

    @media(max-width: 768px){
        padding-top: 30px;
        padding-bottom: 144px;
        &__head{
            margin-bottom: 50px !important;
        }
        &__content{
            flex-direction: column;
            margin: 0;
            padding-right: 0;
            &>*{
                margin: 0;
                width: 100%;
                max-width: unset;
                &:not(:last-child){
                    margin-bottom: 40px;
                }
            }
        }
        &__title{
            max-width: unset;
        }
        &__img{
            width: 100%;
            max-height: 320px;
            &::before{
                padding-top: 63%;
            }
        }
        &__pagination{
            margin-top: 50px;
        }
    }
}