.about-benefits{
    position: relative;
    padding: rem(70) 0;
    &__bg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25));
        }
        >*{
            width: 100%;
            height: 100%;
            object-fit: cover; 
        }
    }
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px rem(-20);
    }
    &__card{
        display: flex;
        flex-direction: column;
        width: calc(100% / 3 - 20px);
        margin: 0 10px rem(20);
        padding: rem(35) rem(35);
        background-color: rgba(248, 248, 248, 0.85);
        backdrop-filter: blur(5px);
    }
    &__text{
        margin-bottom: rem(8);
        font-weight: 500;
        font-size: Max(rem(18), 10px);
        line-height: rem(30);
        letter-spacing: -0.01em;
        color: var(--black);
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__img{
        width: rem(72);
        height: rem(72);
        margin-right: rem(6);
        margin-left: auto;
        margin-top: auto;
        >*{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }


    @media(max-width: 768px){
        padding: 30px 0;
        &__container{
            flex-direction: column;
            margin: 0;
        }
        &__card{
            width: 100%;
            margin: 0;
            padding: 20px 25px;
            flex-direction: row;
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
        &__text{
            margin-right: 30px;
            margin-bottom: 0;
        }
        &__img{
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            margin-top: unset;
            margin-right: 0;
        }
    }
}


