@charset "UTF-8";
html {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  padding: 0 70px;
}
.container.container--small {
  padding-left: 8.75rem;
}
.container.container--big {
  max-width: 100%;
  padding: 0 2.5rem;
}
.container.container--full {
  max-width: 156.25rem;
  padding: 0 1.25rem;
}
@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
  .container.container--small {
    padding-left: 20px;
  }
  .container.container--big {
    padding: 0 20px;
  }
}

.status-available {
  color: #27AE60;
}
.status-available .product__status {
  color: #27AE60;
}
.status-not-available {
  color: #EB5757;
}
.status-not-available .product__status {
  color: #EB5757;
}
.status-wait {
  color: #F2994A;
}
.status-wait .product__status {
  color: #F2994A;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: max(1rem, 12px);
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.75rem;
  color: var(--black);
}
.page__body.menu-open {
  overflow: hidden;
}
.page__body.menu-open .header {
  background-color: #fff;
}

.title-big > * {
  font-family: "Inter";
  font-size: 3.125rem;
  line-height: 3.4375rem;
  font-weight: 600;
  color: var(--black);
}

.title-h1 > * {
  font-family: "Inter";
  font-size: 2.8125rem;
  line-height: 3.75rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--black);
}

.title-h2 > * {
  font-family: "Inter";
  font-size: 2.1875rem;
  line-height: 2.8125rem;
  font-weight: 600;
  color: var(--black);
}

.title-h3 > * {
  font-family: "Inter";
  font-size: 1.875rem;
  line-height: 2.1875rem;
  font-weight: 700;
  color: var(--black);
}

.title-h4 > * {
  font-family: "Inter";
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: var(--black);
}

.title-h5 > * {
  font-family: "Inter";
  font-size: 1.25rem;
  line-height: 1.5625rem;
  font-weight: 700;
  color: var(--black);
}

.title-h6 > * {
  font-family: "Inter";
  font-size: max(0.9375rem, 12px);
  line-height: 1.25rem;
  font-weight: 500;
}

.btn {
  display: inline-flex;
  outline: 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0.90625rem 1.71875rem 1rem;
  font-weight: 800;
  font-size: max(1.25rem, 12px);
  line-height: 1.25rem;
  color: var(--white);
  background-color: var(--primary);
  border-radius: 1.25rem;
  border: 1px solid var(--primary);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.btn--medium {
  padding: 0.75rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
}
.btn--small {
  padding: 0.5625rem 0.9375rem;
  font-size: max(1rem, 10px);
  border-radius: 0.875rem;
}
.btn--smallest {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 2px 12px;
  border-radius: 16px;
  border: 1px solid var(--v1color, #FEB11C);
}
.btn--white {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--black);
}
.btn--white:not(span):hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}
.btn--gray {
  background-color: var(--text-white);
  border-color: var(--text-white);
  color: var(--black);
}
.btn--gray:not(span):hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}
.btn.disabled, .btn.disabled:hover, .btn[disabled], .btn[disabled]:hover {
  background: var(--text-white);
  border-color: var(--text-white);
  cursor: not-allowed;
  color: #fff;
}
.btn:not(span):hover {
  color: var(--black);
  background-color: transparent;
}

.socials {
  display: flex;
}
.socials li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.socials li:not(:last-child) {
  margin-right: 0.625rem;
}
.socials li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}
.socials li a svg path {
  transition: fill 0.2s ease-in-out;
}
.socials li a > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.socials li a:hover svg path {
  fill: var(--primary);
}
@media (max-width: 768px) {
  .socials {
    flex-wrap: wrap;
    margin: 0 -5px -10px !important;
  }
  .socials li {
    margin: 0 5px 10px !important;
  }
}

.arrow-back {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 2rem;
  width: max-content;
  max-width: 100%;
}
.arrow-back__img {
  width: 3.375rem;
  height: 1.375rem;
  margin-right: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.arrow-back__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.2s ease-in-out;
}
.arrow-back span {
  font-weight: 500;
  line-height: 1.75rem;
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 0.5625rem;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}
.arrow-back span:not(:hover) {
  color: var(--black);
}
@media (min-width: 769px) {
  .arrow-back:hover span {
    color: var(--primary);
  }
  .arrow-back:hover img {
    transform: translateX(-19%);
  }
}
@media (max-width: 768px) {
  .arrow-back__img {
    margin-right: 13px;
    width: 51px;
  }
  .arrow-back span {
    font-size: 14px;
    line-height: 30px;
  }
}

input[type=radio] {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.input {
  position: relative;
}
.input input,
.input textarea, .input.input--file,
.input .zen-ui-select__value {
  border: 1px solid var(--text-white);
  border-radius: 1.125rem;
  font-weight: 500;
  font-size: max(1rem, 12px);
  line-height: 1.6875rem;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0.5625rem 0.9375rem;
  color: var(--black);
  background: var(--white);
}
.input input::placeholder,
.input textarea::placeholder, .input.input--file::placeholder,
.input .zen-ui-select__value::placeholder {
  opacity: 1 !important;
  color: var(--black) !important;
}
.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.input input[type=file] {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.input textarea {
  resize: none;
  height: 7rem;
}
.input textarea:focus {
  outline: none;
}
.input .select2 {
  display: none;
}
.input.input--file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 0.9375rem;
}
.input.input--file label {
  flex-shrink: 0;
  margin-left: 1.1875rem;
}
.input.input--radiocheck {
  border: none;
}
.input.input--radiocheck input {
  position: absolute;
  left: -9999px;
}
.input.input--radiocheck input:checked + label::after {
  opacity: 1;
}
.input.input--radiocheck label {
  padding-left: 2.6875rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.75rem;
  min-height: 1.875rem;
  display: flex;
  align-items: center;
}
.input.input--radiocheck label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  border: 1px solid var(--black);
}
.input.input--radiocheck label::after {
  content: "";
  position: absolute;
  left: 0.4375rem;
  top: 0.4375rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--primary);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.input--radio {
  position: relative;
  display: block;
  color: var(--black);
}
.input--radio__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid transparent;
  outline: 1px solid var(--black);
  border-radius: 2.5rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 0.4625rem 0.93125rem 0.4375rem 0.875rem;
  transition: outline-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.input--radio__check {
  position: relative;
  padding-left: 3.1875rem;
}
.input--radio__check::after {
  content: "";
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid var(--black);
  border-radius: 1.125rem;
}
.input--radio__check::before {
  content: "";
  position: absolute;
  left: 0.4375rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-color: var(--primary);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.input--radio input:checked + .input--radio__check::before {
  opacity: 1;
}
.input--radio input:checked + *, .input--radio .input--radio__btn:hover {
  border-color: var(--primary);
  outline-color: var(--primary);
}
.input--checkbox {
  position: relative;
  display: block;
  cursor: pointer;
}
.input--checkbox input {
  position: absolute;
  left: -9999px;
}
.input--checkbox input:checked + span::before {
  background-color: var(--primary);
  border-color: var(--primary);
}
.input--checkbox > span {
  position: relative;
  padding-left: 2.1875rem;
  display: flex;
  align-items: center;
  min-height: 1.5625rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}
.input--checkbox > span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5625rem;
  height: 1.5625rem;
  border: 1px solid var(--black);
  background-image: url('data:image/svg+xml,%3Csvg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6 10.9091L11.4737 16L19 9" stroke="white" stroke-width="3"/%3E%3C/svg%3E ');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.input--select {
  position: relative;
  display: flex;
  align-items: center;
}
.input--select.is-open input,
.input--select.is-open textarea,
.input--select.is-open .output_text {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.input--select.is-open .input__arrow {
  transform: rotate(-180deg);
}
.input .woocommerce-input-wrapper input {
  height: 2.875rem;
}
.input .zen-ui-select__options {
  max-height: unset;
  padding: 0;
  border: none;
}
.input .zen-ui-select__value.disabled {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.input .zen-ui-select__dropdown, .input__dropdown {
  display: none;
  position: absolute;
  transform: translateY(100%);
  text-align: left;
  max-height: 12.5rem;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 0px 1px 23px rgba(30, 33, 36, 0.13);
  left: 0;
  bottom: 1px;
  border: 1px solid var(--text-white);
  right: 0;
  top: unset;
}
.input .zen-ui-select__dropdown.active, .input__dropdown.active {
  display: block;
}
.input .zen-ui-select__dropdown .group-title, .input__dropdown .group-title {
  pointer-events: none;
  color: #828282;
}
.input .zen-ui-select__dropdown .group-item, .input__dropdown .group-item {
  padding-left: 1.875rem;
}
.input .zen-ui-select__dropdown .zen-ui-select__option,
.input .zen-ui-select__dropdown li, .input__dropdown .zen-ui-select__option,
.input__dropdown li {
  cursor: pointer;
  padding: 0.4375rem 0.625rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.input .zen-ui-select__dropdown .zen-ui-select__option.is-selected,
.input .zen-ui-select__dropdown li.is-selected, .input__dropdown .zen-ui-select__option.is-selected,
.input__dropdown li.is-selected {
  pointer-events: none;
  color: var(--primary);
}
.input .zen-ui-select__dropdown .zen-ui-select__option:not(:last-child),
.input .zen-ui-select__dropdown li:not(:last-child), .input__dropdown .zen-ui-select__option:not(:last-child),
.input__dropdown li:not(:last-child) {
  border-bottom: 1px solid var(--text-white);
}
.input .zen-ui-select__dropdown .zen-ui-select__option:hover,
.input .zen-ui-select__dropdown li:hover, .input__dropdown .zen-ui-select__option:hover,
.input__dropdown li:hover {
  background-color: var(--primary);
  color: var(--white);
}
.input .zen-ui-select__dropdown .zen-ui-select__option--current, .input__dropdown .zen-ui-select__option--current {
  background: var(--primary) !important;
  color: var(--white) !important;
}
.input .zen-ui-select__option::first-letter,
.input .zen-ui-select__value-text::first-letter {
  text-transform: uppercase;
}
.input__arrow {
  position: absolute;
  right: 0.6875rem;
  transition: transform 0.2s ease-in-out;
  pointer-events: none;
}
.input--required {
  position: relative;
}
.input--required::after {
  content: "*";
  position: absolute;
  right: -0.6875rem;
  top: 0;
  width: 0.6875rem;
  height: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EB5757;
}
.input .woocommerce-input-wrapper.is-error, .input.is-error {
  margin-bottom: 0.4375rem;
  display: block;
  position: relative;
}
.input .woocommerce-input-wrapper.is-error input,
.input .woocommerce-input-wrapper.is-error textarea,
.input .woocommerce-input-wrapper.is-error .output_text,
.input .woocommerce-input-wrapper.is-error .zen-ui-select__value, .input.is-error input,
.input.is-error textarea,
.input.is-error .output_text,
.input.is-error .zen-ui-select__value {
  border: 1px solid #EB5757;
}
.input .woocommerce-input-wrapper.is-error input::placeholder, .input.is-error input::placeholder {
  color: #EB5757 !important;
}
.input .woocommerce-input-wrapper.is-error .input__error, .input.is-error .input__error {
  display: block;
}
.input__error {
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(100%);
  font-size: 11px;
  bottom: -3px;
  line-height: 11px;
  color: #EB5757;
  display: none;
  z-index: -2;
}
@media (max-width: 768px) {
  .input--select {
    width: 100%;
  }
  .input--select:not(:last-child) {
    margin-bottom: 10px;
  }
  .input--select.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input--select.is-open:first-child {
    border-bottom-right-radius: 0;
  }
}

.woocommerce-order.register .success-message {
  display: block;
}
.woocommerce-order.register .success-message .section-link {
  display: inline-flex;
}
.woocommerce-order.register .success-message .section-link img, .woocommerce-order.register .success-message .section-link svg {
  width: auto;
  max-width: unset;
  object-fit: contain;
  margin-right: 10px;
}

.hover-stroke {
  transition: stroke 0.3s ease-in-out;
}

.hover-fill {
  transition: fill 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .mobile-hide {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .mobile-show {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .title-big > * {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.02em;
  }
  .title-h1 > * {
    font-size: 32px;
    line-height: 42px;
  }
  .title-h2 > * {
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.02em;
  }
  .title-h3 > * {
    font-weight: 600;
    font-size: 19px;
    line-height: 25px;
  }
  .title-h5 > * {
    font-size: 19px;
    font-weight: 600;
  }
}
.woocommerce-error,
.woocommerce-message,
.woocommerce-notices-wrapper,
.woocommerce > .woocommerce-form-coupon-toggle,
.woocommerce-additional-fields > h3 {
  display: none !important;
}

[data-show-mini-cart] {
  position: relative;
}

.mini-cart {
  display: none;
  position: absolute;
  bottom: -10px;
  right: -5px;
  z-index: 10001;
  background: #FFF;
  box-shadow: 0px 0.5rem 3.125rem 0px rgba(19, 20, 21, 0.07);
  padding: 1.125rem 1.5625rem 2.25rem;
  width: 23.125rem;
  max-width: calc(100vw - 30px);
  transform: translateY(100%);
  cursor: default;
}
.mini-cart.cart-empty .mini-cart__btn,
.mini-cart.cart-empty .mini-cart__total {
  display: none;
}
.mini-cart.mini-cart--wholesale .mini-cart__total > * {
  display: none;
}
.mini-cart:not(.cart-empty) .mini-cart__list-empty {
  display: none;
}
.mini-cart:not(.cart-empty) .mini-cart__list-not-empty {
  display: block;
}
.mini-cart__list {
  overflow-y: auto;
  max-height: 12.5rem;
  padding-right: 1.25rem;
  margin-right: -1.25rem;
  line-height: 0;
  scrollbar-color: var(--primary) transparent;
  scrollbar-width: thin;
}
.mini-cart__list::-webkit-scrollbar {
  width: 5px;
}
.mini-cart__list::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0;
}
.mini-cart__list::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 0;
}
.mini-cart__list-empty {
  font-size: max(1rem, 12px);
  font-weight: 400;
  line-height: 1.75rem;
  color: var(--black);
  text-align: left;
  display: block;
}
.mini-cart__list-not-empty {
  display: none;
  text-align: left;
  font-size: 1.125rem;
  font-weight: normal;
  color: var(--black);
}
.mini-cart__title {
  color: var(--black);
  text-align: left;
  margin-bottom: 1.75rem;
}
.mini-cart__total {
  margin-top: 1.0625rem;
  padding-top: 1.25rem;
  border-top: 0.25rem solid #F8F8F9;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: var(--black);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
.mini-cart__btn {
  margin-top: 1.25rem;
}
.mini-cart__btn .btn {
  width: 100%;
}
.mini-cart .cart-item:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.mini-cart .cart-item.cart-item--smallest {
  padding: 0;
}
.mini-cart .cart-item.cart-item--smallest .cart-item__total {
  padding: 0;
  text-align: left;
}
.mini-cart .cart-item__title {
  line-height: 1.125rem;
}
.mini-cart .product-card__price--new {
  font-size: 1.5625rem;
  line-height: 1.5625rem;
}

.section-title {
  display: inline-flex;
  align-items: center;
}
.section-title::after {
  content: "";
  display: block;
  width: 21.625rem;
  height: 0.25rem;
  background-color: var(--primary);
}
.section-title__img {
  width: 3.375rem;
  height: 3.375rem;
  background-color: var(--primary);
  border-radius: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
}
.section-title__img > * {
  width: 1.25rem;
  height: 1.25rem;
  object-fit: contain;
}

.burger {
  width: 20px;
  height: 18px;
  position: relative;
  margin-right: 10px;
}
.burger > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease-in-out;
}
.burger > *:last-child {
  opacity: 0;
}
.burger.is-active > *:first-child {
  opacity: 0;
}
.burger.is-active > *:last-child {
  opacity: 1;
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.breadcrumbs li {
  font-weight: 400;
  font-size: 2.8125rem;
  line-height: 3.4375rem;
  color: var(--black);
}
.breadcrumbs li:not(:last-child) {
  margin-right: 0.375rem;
  padding-right: 2.1875rem;
  position: relative;
}
.breadcrumbs li:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-image: url(./../img/ico-breadcrumbs.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.breadcrumbs li a {
  color: var(--black);
  font-weight: 600;
  line-height: 3.75rem;
  letter-spacing: -0.02em;
  transition: color 0.3s ease-in-out;
}
.breadcrumbs li a:hover {
  color: var(--primary);
}
@media (max-width: 768px) {
  .breadcrumbs li {
    font-size: 32px;
    line-height: 45px;
  }
}

.tag {
  font-weight: 700;
  font-size: max(1.125rem, 10px);
  line-height: 1.375rem;
  text-transform: uppercase;
  color: var(--white);
  padding: 0.25rem 0.75rem 0.25rem 1.125rem;
  background-color: var(--red);
  border-top-right-radius: 1rem;
  pointer-events: none;
}
.tag:not(:last-child) {
  margin-bottom: 0.3125rem;
}
.tag--new {
  background-color: #29AF08;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quantity__btn {
  width: 1.1875rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
}
.quantity__btn > * {
  width: 0.875rem;
  height: 0.875rem;
  object-fit: contain;
}
.quantity__btn:last-child {
  justify-content: flex-end;
}
.quantity__input {
  width: 2.1875rem;
  flex-shrink: 0;
  min-height: 2.5rem;
  min-width: 2.1875rem;
  font-weight: 600;
  border-radius: 0;
  font-size: 1.5625rem;
  line-height: 2.5rem;
  text-align: center;
  color: var(--black);
}

.total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.375rem;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: right;
  color: var(--black);
}
.total span {
  margin-left: 0.6875rem;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 3.4375rem;
}
@media (max-width: 768px) {
  .total {
    font-size: 14px;
    line-height: 26px;
  }
  .total span {
    font-size: 25px;
    line-height: 40px;
  }
}

.dropdown {
  position: relative;
  padding: 0.4375rem 0.9375rem 0.5rem;
  margin-right: 0 !important;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}
@media (min-width: 769px) {
  .dropdown:not(.dropdown-click):hover {
    background-color: rgba(42, 46, 50, 0.05);
  }
  .dropdown:not(.dropdown-click):hover ul {
    opacity: 1;
    visibility: visible;
  }
}
.dropdown ul {
  position: absolute;
  z-index: 99;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  text-align: right;
  width: max-content;
  background: #FFFFFF;
  box-shadow: 0px 1px 23px rgba(30, 33, 36, 0.13);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.dropdown ul.active {
  z-index: 1000;
  opacity: 1 !important;
  visibility: visible !important;
}
.dropdown ul a {
  display: block;
  padding: 0.4375rem 1rem 0.5rem;
  font-weight: 400;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
  color: var(--black);
  transition: background-color 0.3s ease-in-out;
}
@media (min-width: 769px) {
  .dropdown ul a:hover {
    background-color: rgba(42, 46, 50, 0.05);
  }
}
.dropdown ul a.is-active {
  background-color: var(--primary);
  color: #fff;
}
.dropdown ul .separated {
  margin-top: 10px;
  padding-top: 2px;
  position: relative;
}
.dropdown ul .separated::before {
  content: "";
  position: absolute;
  top: 0;
  right: 1rem;
  height: 2px;
  width: 106px;
  max-width: 100%;
  background: #F4F4F4;
}

.fancybox__slide {
  padding: 20px;
}

.popup {
  width: 30.9375rem;
  max-width: 100%;
  background: #FFFFFF;
  box-shadow: 0 8px 50px rgba(19, 20, 21, 0.15);
  padding: 3.5rem 1.875rem 3.875rem;
}
.popup .form {
  margin: 1rem auto 0;
  max-width: 18.75rem;
}
.popup .form__row:not(:last-child) {
  margin-bottom: 12px;
}
.popup .form__input {
  width: calc(100% + 0.625rem);
}
.popup .f-button {
  top: 0.9375rem !important;
  right: 0.9375rem !important;
  width: 1.375rem;
  height: 1.375rem;
  border: none !important;
  outline: none !important;
  background: transparent !important;
  --f-button-bg: transparent!important;
}
.popup .f-button svg {
  width: 1.375rem;
  height: 1.375rem;
  stroke: #828282;
}
.popup__title {
  margin-bottom: 1.875rem;
}
.popup.popup--centered {
  text-align: center;
}
.popup.popup--centered .input input {
  text-align: center;
}
.popup.popup--centered .input__error {
  text-align: center;
}
.popup:not(.popup--centered) {
  width: 40rem;
}
.popup:not(.popup--centered) .form {
  max-width: 100%;
  margin-left: 0;
}
.popup:not(.popup--centered) .form__input:not(.form__textarea) {
  max-width: 310px;
}
.popup:not(.popup--centered) .popup__inner {
  max-width: 420px;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 768px) {
  .popup .popup {
    padding: 56px 20px 28px;
  }
  .popup .popup__title {
    margin-bottom: 20px;
  }
  .popup .popup__inner .success {
    margin-top: -20px;
    margin-bottom: -20px;
  }
}

.dropdown-click {
  display: inline-flex;
  align-items: center;
  padding: 0;
}
.dropdown-click img {
  flex-shrink: 0;
}
.dropdown-click img:first-child {
  width: 0.9375rem;
  height: 0.625rem;
  object-fit: contain;
  margin-right: 0.4375rem;
}
.dropdown-click img:last-child {
  width: 0.75rem;
  height: 0.75rem;
  object-fit: contain;
  margin-left: 0.4375rem;
}
.dropdown-click ul {
  bottom: -0.25rem;
}
.dropdown-click span {
  font-weight: 400;
  font-size: max(0.875rem, 12px);
  line-height: 1.25rem;
}

.pagination {
  display: flex;
  align-items: center;
}
.pagination > * {
  display: block;
}
.pagination .page-numbers {
  font-weight: 600;
  font-size: max(1rem, 10px);
  line-height: 1.5625rem;
  color: var(--black);
  transition: color 0.3s ease-in-out;
}
.pagination .page-numbers:hover {
  color: var(--primary);
}
.pagination .page-numbers:not(:last-child) {
  margin-right: 2.4375rem;
}
.pagination .page-numbers:last-child {
  position: relative;
  margin-left: 11.625rem;
}
.pagination .page-numbers:last-child::before {
  content: "";
  position: absolute;
  left: -11.625rem;
  top: 50%;
  transform: translateY(-50%);
  width: 9.125rem;
  height: 0.25rem;
  background-color: var(--gray);
  pointer-events: none;
}
.pagination .page-numbers.current {
  padding: 0.26875rem 0.7625rem;
  background-color: var(--primary);
  color: var(--white);
  margin-right: 1.6875rem;
}
.pagination .page-numbers.next, .pagination .page-numbers .prev {
  display: none;
}
@media (max-width: 768px) {
  .pagination .page-numbers:not(:last-child) {
    margin-right: 30px;
  }
  .pagination .page-numbers:last-child {
    flex-grow: 1;
    margin-left: 0;
    text-align: right;
    display: flex;
    align-items: center;
  }
  .pagination .page-numbers:last-child::before {
    position: static;
    transform: none;
    width: auto;
    flex-grow: 1;
    margin-right: 16px;
  }
  .pagination .page-numbers.current {
    margin-right: 18px;
  }
}

.fancybox__container {
  --fancybox-bg: rgba(238, 238, 238, 0.8);
}

.fancybox__backdrop {
  backdrop-filter: blur(5px);
}

.fancybox__container {
  z-index: 10001;
}

.popup-search {
  height: 46px;
  width: calc(100% - 40px);
  padding: 0;
  background: transparent;
  position: absolute;
  top: 11px;
  left: 20px;
  right: 20px;
}
.popup-search__inner {
  padding: 0;
  height: 100%;
}
.popup-search__content {
  height: 100%;
}
.popup-search__content .header__search-form {
  position: relative;
  right: 0;
}
.popup-search__content .header__search-form.is-open {
  width: 100%;
}
.popup-search__content .header__search-form input {
  width: 100%;
}
.popup-search .is-close-btn {
  display: none;
}

/* Елемент | http://localhost:3000/favourites.html */
/* main.css | http://localhost:3000/css/main.css?browsersync=1685206053940 */
.swiper-nav-pagination {
  position: absolute;
  left: 0;
  bottom: 4.125rem;
  display: flex;
  align-items: center;
  max-width: 28.125rem;
  width: 100%;
  justify-content: space-between;
}

.swiper-button-next, .swiper-button-prev {
  width: 1.5625rem;
  height: 1.5625rem;
  margin-top: 0;
  line-height: 0;
  position: static;
  flex-shrink: 0;
  outline: none !important;
  border: none !important;
}

.swiper-pagination {
  position: relative;
  margin: 0 1.25rem;
  bottom: 0 !important;
  display: flex;
  width: 100%;
  height: 0.25rem !important;
}
.swiper-pagination-bullet {
  margin: 0 0.3125rem;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 0.875rem;
  background: var(--text-white);
  opacity: 1;
}
.swiper-pagination-bullet::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--primary);
  width: 0;
  z-index: 2;
  border-radius: inherit;
}
.swiper-pagination.can-play .swiper-pagination-bullet-active {
  transition-duration: 3000ms;
  pointer-events: none;
}
.swiper-pagination.can-play .swiper-pagination-bullet-active::before {
  transition: width 3000ms linear !important;
  transition-duration: inherit !important;
  width: 100%;
}

.swiper-button-prev::after, .swiper-rtl .swiper-button-next::after,
.swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
  content: none;
}

.swiper-nav path {
  transition: stroke 0.3s ease-in-out;
}
.swiper-nav:hover path {
  stroke: var(--primary);
}
.swiper-nav > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-parallax {
  overflow: hidden;
}

.title-link {
  display: flex;
  align-items: center;
}
.title-link::before {
  content: "";
  display: block;
  width: 100%;
  height: 0.25rem;
  background: var(--primary);
  border-radius: 0.75rem;
}
.title-link__ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  flex-shrink: 0;
  background: #FEB11C;
  border: 1px solid rgba(251, 179, 95, 0.5);
  border-radius: 50%;
  margin-left: -3.375rem;
}
.title-link__ico > * {
  width: 45%;
  height: 45%;
  object-fit: contain;
}

.woocommerce form .form-row-last, .woocommerce-page form .form-row-last,
.woocommerce form .form-row-first, .woocommerce-page form .form-row-first {
  float: unset !important;
}

.woocommerce form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-first, .woocommerce-page form .form-row-last {
  width: 100% !important;
}

#add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment {
  background: transparent;
}

#add_payment_method #payment div.payment_box > *:not(:last-child),
.woocommerce-cart #payment div.payment_box > *:not(:last-child),
.woocommerce-checkout #payment div.payment_box > *:not(:last-child) {
  margin-bottom: 1.25rem;
}

#add_payment_method #payment div.payment_box, .woocommerce-cart #payment div.payment_box, .woocommerce-checkout #payment div.payment_box {
  padding: 5px 0 5px 2.6875rem;
  margin: 0;
  background-color: transparent;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #828282;
}

#add_payment_method #payment div.payment_box::before, .woocommerce-cart #payment div.payment_box::before, .woocommerce-checkout #payment div.payment_box::before {
  content: none;
}

@media (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1080px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 16px;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

img, svg {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

address {
  font-style: normal;
}

ul {
  list-style: none;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

:root {
  --white: #fff;
  --black: #333;
  --primary: #feb11c;
  --red: #ee5513;
  --gray: #f4f4f4;
  --text: #4f4f4f;
  --text-light: #828282;
  --text-lighter: #bdbdbd;
  --text-white: #e0e0e0;
  --container-margin: Max(calc((100vw - 1460px) / 2), 70px);
}

.header {
  position: relative;
  z-index: 10000;
  transition: background-color 0.3s ease-in-out;
}
.header__logo {
  display: block;
  max-width: 18.375rem;
  width: 22.6%;
  flex-shrink: 0;
  margin-top: 1.6875rem;
  margin-right: 2.25rem;
}
.header__container {
  display: flex;
}
.header__content {
  width: 100%;
  padding: 0 0 0.5rem;
}
.header__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4375rem 0;
}
.header__row:first-child {
  position: relative;
  padding: 0;
  z-index: 1001;
}
.header__row:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100% + var(--container-margin));
  background: rgba(247, 247, 248, 0.85);
  backdrop-filter: blur(2px);
  z-index: -1;
}
.header__row:first-child .header__btns > *:not(:last-child) {
  margin-right: 0.625rem;
}
.header__row:not(:last-child) {
  margin-bottom: 0.5rem;
}
.header__nav {
  line-height: 1.25rem;
}
.header__nav > ul {
  display: flex;
}
.header__nav > ul a {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #4F4F4F;
  transition: background-color 0.3s ease-in-out;
  display: inline-block;
  padding: 0.4375rem 1.25rem 0.5rem;
  position: relative;
}
@media (min-width: 769px) {
  .header__nav > ul a:hover {
    background-color: rgba(42, 46, 50, 0.05);
  }
}
.header__nav > ul > li.is-active.is-small {
  position: relative;
}
.header__nav > ul > li.is-active.is-small a {
  padding-right: 0.9375rem;
}
.header__nav > ul > li.is-active.is-small a::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background: var(--primary);
}
.header__nav > ul > li.is-active:not(.is-small) a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0.25rem;
  background: var(--primary);
}
.header__btn {
  outline: none;
  border: none;
  background: none;
  border-radius: 0;
  font-weight: 500;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #4F4F4F;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  transition: color 0.3s ease-in-out;
  position: relative;
  z-index: 99;
}
.header__btn img, .header__btn svg {
  width: 1.25rem;
  height: 1.25rem;
  object-fit: contain;
  line-height: 0;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}
.header__btn img + *:not(.header-message):not(.cart-empty), .header__btn svg + *:not(.header-message):not(.cart-empty) {
  margin-left: 0.15625rem;
}
.header__btn.mini-cart-target {
  color: var(--primary);
}
.header__btn.mini-cart-target .hover-fill {
  fill: var(--primary);
}
.header__btn.mini-cart-target .hover-stroke {
  stroke: var(--primary);
}
.header__btn.header-catalog-btn {
  margin-left: -0.4375rem;
}
.header__btn.header-catalog-btn + .breadcrumbs {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.header__btn.header-catalog-btn.is-open + .breadcrumbs {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 769px) {
  .header__btn:not(.header__dropdown):hover {
    color: var(--primary);
  }
  .header__btn > svg:hover .hover-fill {
    fill: var(--primary);
  }
  .header__btn > svg:hover .hover-stroke {
    stroke: var(--primary);
  }
}
.header__btns {
  display: flex;
  align-items: center;
}
.header__btns > *:not(:last-child) {
  margin-right: 1.75rem;
}
.header__btns:not(.header__btns--big) {
  margin-right: -0.9375rem;
}
.header__btns.header__btns--big .header__btn {
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: var(--black);
}
@media (min-width: 769px) {
  .header__btns.header__btns--big .header__btn:hover {
    color: var(--primary);
  }
}
.header__btns.header__btns--big .header__btn svg, .header__btns.header__btns--big .header__btn img {
  width: 1.875rem;
  height: 1.875rem;
}
.header__btns.header__btns--big .header__btn svg + *:not(.header-message):not(.cart-empty), .header__btns.header__btns--big .header__btn img + *:not(.header-message):not(.cart-empty) {
  margin-left: 0.3125rem;
}
.header__static {
  height: 7.5625rem;
}
.header ~ .main .page-promo:first-child {
  margin-top: -7.5625rem;
}
.header__fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(247, 247, 248, 0.85);
  backdrop-filter: blur(2px);
  transition: transform 0.4s ease-in-out;
  transform: translateY(-100%);
}
.header__fixed .header__logo {
  max-width: none;
  width: auto;
  margin: 1.125rem 3.4% 1.125rem 0;
  height: 3.375rem;
}
.header__fixed .header__logo > * {
  height: 100%;
  object-fit: contain;
  width: auto;
}
.header__fixed .header__nav > ul {
  margin: 0 -0.875rem;
}
.header__fixed .header__nav > ul > li {
  margin: -0.8125rem 0.875rem 0;
  padding-top: 0.8125rem;
  position: relative;
}
.header__fixed .header__nav > ul > li.is-active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0.25rem;
  background: var(--primary);
}
.header__fixed .header__nav > ul > li > a {
  padding: 0;
  background: transparent;
  transition: color 0.3s ease-in-out;
}
@media (min-width: 769px) {
  .header__fixed .header__nav > ul > li > a:hover {
    color: var(--primary);
  }
}
.header__fixed .header__nav > ul > li > a::before {
  content: none !important;
}
.header__fixed .header__row {
  padding: 0.40625rem 0;
}
.header__fixed .header__row:first-child::before {
  content: none;
}
.header__fixed .header__row:not(:last-child) {
  margin-bottom: 0;
}
.header__search {
  position: relative;
}
.header__search-form {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
  z-index: -1;
  width: 2rem;
  opacity: 0;
  transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.header__search-form input {
  height: 2.875rem;
  width: 100%;
  background: var(--white);
  border: 1px solid var(--text-white);
  border-radius: 1.125rem;
  padding: 0.8125rem 6.75rem 0.8125rem 2.8125rem;
}
.header__search-form button {
  position: absolute;
  right: 0.1875rem;
  top: 50%;
  transform: translateY(-50%);
}
.header__search-form svg {
  position: absolute;
  left: 0.9375rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5625rem;
  height: 1.5625rem;
  pointer-events: none;
}
.header__search-form.is-open {
  display: block;
  opacity: 1;
  width: 31.4375rem;
  z-index: 100;
  transition-delay: 0.3s;
}
.header__search-form.is-open + .header__search-btn {
  opacity: 0;
  transition-delay: 0s;
}
.header__search-form.dropdown-open .header__search-list {
  opacity: 1;
  visibility: visible;
}
.header__search-list {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1.4375rem;
  transform: translateY(100%);
  background: #fff;
  box-shadow: 0px 0.5rem 3.125rem rgba(19, 20, 21, 0.15);
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  padding: 2.5rem 1.6875rem 1.5625rem 0.875rem;
}
.header__search-list > ul {
  width: 28.875rem;
  overflow-y: auto;
  max-height: 18.125rem;
}
.header__search-list > ul > li:not(:last-child) {
  margin-bottom: 0.625rem;
}
.header__search-item {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: var(--black);
  transition: color 0.3s ease-in-out;
}
.header__search-item img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
  flex-shrink: 0;
  margin-right: 0.4375rem;
}
@media (min-width: 769px) {
  .header__search-item:hover {
    color: var(--primary);
  }
}
.header__search-btn {
  transition: opacity 0.3s ease-in-out 0.3s, color 0.3s ease-in-out;
}
.header__menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  box-shadow: 0px 0.5rem 3.125rem rgba(19, 20, 21, 0.15);
  z-index: -1;
  padding: 6.5rem 0 3.625rem;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.header__menu::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: -2;
  transform: translateY(100%);
  background: rgba(238, 238, 238, 0.8);
  backdrop-filter: blur(5px);
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
}
.header__menu.is-open {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.header__menu.is-open::after {
  opacity: 1;
}
.header__menu.is-open .header__menu-inner {
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0.5s;
}
@media (min-width: 769px) {
  .header__menu-inner {
    overflow-y: auto;
    max-height: calc(100vh - 170px);
    padding: 0 20px;
    margin: 0 -20px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out 0s;
  }
}
.header__menu-row {
  display: flex;
  justify-content: space-between;
  margin: 0 -0.9375rem 0.4375rem;
}
.header__menu-row > * {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 0.9375rem;
}
.header__menu-row .header__menu-item {
  padding-bottom: 0.875rem;
  position: relative;
}
.header__menu-row .header__menu-item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -20px);
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 2.55737L10.75 10.8074L19 2.55737' stroke='%23E0E0E0' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.3125rem;
  height: 0.875rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.header__menu-row .header__menu-item.is-selected::before {
  opacity: 1;
  transform: translate(-50%, 0);
}
.header__menu-subs {
  position: relative;
  overflow: hidden;
  height: 0rem;
  transition: height 0.4s ease-in-out;
}
.header__menu-subs > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.header__menu-sub {
  display: none;
  padding: 0 0.1875rem 2.125rem;
  flex-wrap: wrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.header__menu-sub .header__menu-item {
  width: 4.5625rem;
  margin: 0 1.5625rem;
  padding-bottom: 1rem;
  position: relative;
}
.header__menu-sub .header__menu-item::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: var(--primary);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
@media (min-width: 769px) {
  .header__menu-sub .header__menu-item:hover::after {
    opacity: 1;
  }
}
.header__menu-sub .header__menu-item__img {
  border-bottom: none;
  margin-bottom: 0;
}
.header__menu-sub .header__menu-item__title {
  font-size: 0.8125rem;
  line-height: 1rem;
  color: #000;
}
.header__menu-item {
  max-width: 10.9375rem;
  cursor: pointer;
  display: block;
}
.header__menu-item:hover .header__menu-item__img, .header__menu-item.is-selected .header__menu-item__img {
  border-bottom-color: var(--primary);
}
.header__menu-item__img {
  position: relative;
  border-bottom: 0.25rem solid transparent;
  margin-bottom: 0.5rem;
  transition: border-bottom-color 0.3s ease-in-out;
}
.header__menu-item__img > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center bottom;
}
.header__menu-item__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.header__menu-item__title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  color: var(--black);
}
.header__menu-use {
  padding-top: 2.375rem;
  border-top: 0.25rem solid #F4F4F4;
  display: flex;
}
.header__menu-content {
  width: 23%;
  margin-right: 1.9375rem;
  flex-shrink: 0;
}
.header__menu-text {
  margin-top: 0.75rem;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  color: #828282;
  max-width: 15.8125rem;
}
.header__menu-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.header__menu-list li {
  margin: 0 0.625rem 0.625rem 0;
}
.header__menu-list a {
  display: inline-block;
  padding: 0.5625rem 1.375rem 0.625rem;
  background: #F8F8F8;
  border-radius: 0.9375rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--black);
  transition: background-color 0.3s ease-in-out;
}
@media (min-width: 769px) {
  .header__menu-list a:hover {
    background-color: var(--primary);
  }
}
.header-message {
  display: none;
  position: absolute;
  top: -0.25rem;
  right: -1rem;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 0.5rem 3.125rem 0px rgba(19, 20, 21, 0.15);
  color: var(--black);
  min-width: 310px;
  width: max-content;
  max-width: calc(100vw - 30px);
  cursor: default;
}
.header-message__btn {
  padding: 0.75rem 1.0625rem 1.0625rem;
}
.header-message__btn .btn {
  width: 100%;
}
.header-message__title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5625rem;
  padding: 0.5625rem 2.5rem 0.5rem 1.125rem;
  text-align: left;
}
.header-message__text {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  max-width: 19.6875rem;
  text-align: left;
  padding: 0 1.0625rem;
  color: var(--text);
}
@media (max-width: 768px) {
  .header-message {
    top: -10px;
    right: -7px;
  }
}
.header-wholesale.wholesale-empty {
  display: none;
}
.header-regular.cart-empty .cart-not-empty {
  display: none;
}
.header-regular:not(.cart-empty) .cart-empty {
  display: none;
}
.header .breadcrumbs {
  margin-left: -21px;
}
.header .breadcrumbs li {
  color: var(--text-light);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}
.header .breadcrumbs li a {
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
.header .breadcrumbs li a:hover {
  color: var(--primary);
}
.header .breadcrumbs li:not(:last-child) {
  margin-right: 7px;
  padding-right: 16px;
}
.header .breadcrumbs li:not(:last-child)::after {
  width: 8px;
  height: 8px;
}
.header .breadcrumbs li:first-child a {
  display: none;
}
.header.header--fixed .header__fixed {
  transform: translateY(0);
}
.header.header--big .header__static .header__logo {
  max-width: 20rem;
  width: 16.7%;
  margin-top: 0;
  margin-right: 0;
  position: absolute;
  top: 3.5625rem;
  left: 50%;
  transform: translateX(-50%);
}
.header.header--big .header__static .header__row:first-child::before {
  width: calc(100% + var(--container-margin) * 2);
  left: calc(-1 * var(--container-margin));
}
.header.header--big .header__static .header__nav {
  margin: 0 -1.25rem;
}
.header.header--big .header__static .header__row:not(:last-child) {
  margin-bottom: 1.1875rem;
}
.header.header--big .header__search-list > ul {
  width: 21.1875rem;
}
.header.header--big .header__search-form.is-open {
  width: 23.75rem;
}
@media (max-width: 768px) {
  .header__search-list > ul {
    width: auto;
  }
  .header ~ .main .page-promo:first-child {
    margin-top: -66px;
  }
  .header__btns > *:not(:last-child) {
    margin-right: 10px;
  }
  .header__static {
    height: auto;
  }
  .header__btn img, .header__btn svg {
    width: 28px;
    height: 28px;
  }
  .header-mobile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 10px;
  }
  .header-mobile__search {
    cursor: pointer;
  }
  .header-mobile__logo {
    height: 40px;
    width: auto;
    margin-bottom: -10px;
  }
  .header-mobile__logo img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }
  .header-mobile .burger {
    margin-top: 4px;
  }
  .header .header-wholesale .mini-cart {
    right: -43px;
  }
}

.menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  height: 100%;
  background-color: var(--white);
  transform: translateX(-100%);
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
}
.menu.is-active {
  transform: translateX(0);
}
.menu__inner {
  padding: 90px 20px 35px;
  display: flex;
  flex-direction: column;
}
.menu__inner .header__btn img, .menu__inner .header__btn svg {
  width: 35px;
  height: 35px;
}
.menu__btns {
  margin-bottom: 40px;
}
.menu__btns .header__btn {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: var(--black);
  padding-left: 8px;
}
.menu__btns .header__btn svg:not(:last-child) {
  margin-right: 6px;
}
.menu__nav {
  padding-top: 30px;
  border-top: 4px solid var(--gray);
}
.menu__nav:not(:last-child) {
  padding-bottom: 40px;
}
.menu__nav ul {
  flex-direction: column;
}
.menu__nav ul li {
  margin: 0;
  padding: 0;
  padding-left: 17px;
}
.menu__nav ul li a {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--black);
}
.menu__nav ul li a span {
  margin-left: 37px;
}
.menu__nav ul li:not(:last-child) {
  margin-bottom: 30px;
}
.menu__nav ul ul {
  margin-top: 23px;
  margin-left: 23px;
}
.menu__nav .header__btn svg:not(:last-child) {
  margin-right: -22px;
}
.menu__nav--small ul li:first-child {
  margin-bottom: 20px;
}
.menu__nav--small ul li.is-small a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.menu__nav--small ul li.is-small:not(:last-child) {
  margin-bottom: 14px;
}
.menu__nav--small ul li.is-active a {
  pointer-events: none;
  position: relative;
  padding-right: 15px;
}
.menu__nav--small ul li.is-active a::after {
  content: "";
  position: absolute;
  right: 0;
  top: 52%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--primary);
}
.menu .header__menu-inner {
  padding: 10px 0 0;
  display: none;
}
.menu .header__menu-row > * {
  width: 37%;
}
.menu .header__menu-item__img::before {
  padding-top: 171%;
}
.menu .header__menu-item__img {
  margin-bottom: 10px;
}
.menu .header__menu-row {
  margin: 0;
  justify-content: space-around;
}
.menu .header__menu-row:not(:last-child) {
  margin-bottom: 20px;
}
.menu .header__menu-row .header__menu-item::before {
  bottom: -32px;
}
.menu .header__menu-row .header__menu-item {
  padding-bottom: 0;
}
.menu .header__menu-sub {
  display: none;
  padding: 55px 0 0;
  justify-content: space-around;
}
.menu .header__menu-sub .header__menu-item {
  width: 44%;
  margin: 0;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}
.menu .header__menu-sub .header__menu-item:last-child:nth-child(odd) {
  margin-right: auto;
}
.menu .header__menu-sub .header__menu-item__img {
  width: 50%;
  flex-shrink: 0;
  margin-bottom: 0;
}
.menu .header__menu-sub .header__menu-item__img::before {
  padding-top: 80%;
}
.menu .header__menu-sub .header__menu-item__title {
  text-align: left;
}
.menu .header__menu-subs > * {
  padding-top: 55px;
}
.menu .header__menu-item {
  max-width: 215px;
}
.menu .header__menu-row > * {
  flex-grow: unset;
  flex-basis: unset;
  margin: 0;
}
.menu .header__menu-use {
  padding-top: 50px;
  border-top: none;
  flex-direction: column;
}
.menu .header__menu-use .section-arrow {
  margin-top: 30px;
  margin-left: 40px;
}
.menu .header__menu-use .section-arrow img {
  transition: transform 0.3s ease-in-out;
}
.menu .header__menu-use .section-arrow.is-open .open-text {
  display: none;
}
.menu .header__menu-use .section-arrow.is-open img {
  transform: rotate(180deg);
}
.menu .header__menu-use .section-arrow:not(.is-open) .close-text {
  display: none;
}
.menu .header__menu-content {
  width: 100%;
  margin-right: 0;
  padding-left: 40px;
}
.menu .header__menu-list {
  display: none;
  padding-top: 30px;
  padding-left: 20px;
}
.menu .menu__nav ul li a {
  padding: 0;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 100%;
}
.menu .menu__inner .header__btn img, .menu .menu__inner .header__btn svg {
  width: 25px;
  height: 25px;
}
.menu .menu__nav .header__btn svg:not(:last-child) {
  margin-right: 11.5px;
}
@media (max-width: 768px) {
  .menu .header__menu-item__img::before {
    padding-top: 91%;
  }
  .menu .header__menu-sub {
    justify-content: space-between;
  }
  .menu .header__menu-subs > * {
    padding-top: 40px;
  }
  .menu .header__menu-sub .header__menu-item {
    width: calc(50% - 17px);
    margin: 0 8.5px;
    align-items: flex-start;
  }
  .menu .header__menu-sub .header__menu-item__img {
    width: calc(50% - 10px);
    margin-right: 10px;
  }
  .menu .header__menu-sub .header__menu-item__title {
    display: flex;
    align-items: center;
    min-height: 30px;
  }
}

.section-text h2 {
  font-weight: 600;
  font-size: 2.1875rem;
  line-height: 3.4375rem;
  margin-top: 3.75rem;
  margin-bottom: 1.5625rem;
  color: var(--black);
}
.section-text p {
  font-size: max(1rem, 11px);
  line-height: 1.75rem;
  font-weight: 400;
  max-width: 46.875rem;
  color: var(--text);
}
.section-text p:not(:last-child) {
  margin-bottom: 2.1875rem;
}
.section-text p + ol, .section-text p + ul {
  margin-top: -0.625rem !important;
}
.section-text ul,
.section-text ol {
  max-width: 46.875rem;
}
.section-text ul:not(:first-child),
.section-text ol:not(:first-child) {
  margin-top: 1.5625rem;
}
.section-text ul:not(:last-child),
.section-text ol:not(:last-child) {
  margin-bottom: 2.1875rem;
}
.section-text ul li {
  padding-left: 2.8125rem;
  position: relative;
}
.section-text ul li:not(:last-child) {
  margin-bottom: 1rem;
}
.section-text ul li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0.8125rem;
  width: 1.875rem;
  height: 0.25rem;
  background-color: var(--primary);
}
.section-text ol {
  list-style: none;
  counter-reset: listCounter;
}
.section-text ol li {
  margin-left: 2.8125rem;
  counter-increment: listCounter;
  position: relative;
}
.section-text ol li:not(:last-child) {
  margin-bottom: 1rem;
}
.section-text ol li::before {
  content: counter(listCounter);
  position: absolute;
  left: -0.9375rem;
  top: 0;
  transform: translateX(-100%);
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--primary);
}
.section-text a {
  outline: none !important;
  font-weight: 500;
  color: var(--black);
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 4px;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}
.section-text a:hover {
  color: var(--primary);
}
.section-text table {
  margin-top: 3.75rem;
  margin-bottom: 5.625rem;
  max-width: 53.75rem;
  width: 100%;
  color: var(--text);
  border-collapse: collapse;
}
.section-text table thead {
  border-bottom: 4px solid var(--gray);
}
.section-text table th {
  text-align: left;
  padding-bottom: 0.625rem;
}
.section-text table td {
  padding-top: 1.5625rem;
}
.section-text table td,
.section-text table th {
  vertical-align: top;
}
.section-text table th:not(:last-child),
.section-text table td:not(:last-child) {
  padding-right: 2%;
}
.section-text table th:not(:last-child):nth-child(1),
.section-text table td:not(:last-child):nth-child(1) {
  width: 23%;
}
.section-text table th:not(:last-child):nth-child(2),
.section-text table td:not(:last-child):nth-child(2) {
  width: 38.5%;
  padding-right: 13%;
}
.section-text__table {
  width: calc(100% + 40px);
  margin: 0 -20px;
  overflow-x: auto;
  padding: 0 20px;
}
.section-text--big p {
  font-size: max(1.125rem, 12px);
  line-height: 1.875rem;
  color: var(--black);
}
.section-text--small p {
  font-size: max(0.8125rem, 10px) !important;
  line-height: 1.375rem;
}
.section-text__brands {
  display: flex;
  flex-wrap: wrap;
  margin: 0.5625rem -0.9375rem 1.875rem;
}
.section-text__brands > * {
  margin: 0 0.9375rem 1.875rem;
  width: 9.25rem;
  height: auto;
  object-fit: contain;
}
@media (max-width: 768px) {
  .section-text h2 {
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.02em;
  }
  .section-text p {
    font-size: 14px;
    line-height: 26px;
  }
  .section-text p:not(:last-child) {
    margin-bottom: 30px;
  }
  .section-text__brands {
    margin-bottom: 20px;
  }
  .section-text--big p {
    font-size: 16px;
    line-height: 28px;
  }
  .section-text--small p {
    line-height: 22px;
  }
  .section-text ul,
  .section-text ol {
    max-width: unset;
    font-size: 14px;
    line-height: 26px;
  }
  .section-text ul:not(:first-child),
  .section-text ol:not(:first-child) {
    margin-top: 20px;
  }
  .section-text ul:not(:last-child),
  .section-text ol:not(:last-child) {
    margin-bottom: 20px;
  }
  .section-text ul li::after {
    width: 25px;
  }
  .section-text ol li::before {
    font-size: 18px;
    line-height: 27px;
    font-weight: 900;
  }
  .section-text table {
    margin: 40px 0;
    min-width: 526px;
  }
  .section-text table td {
    padding-top: 20px;
  }
  .section-text table > * {
    font-size: 14px;
    line-height: 26px;
  }
  .section-text table th:not(:last-child):nth-child(2),
  .section-text table td:not(:last-child):nth-child(2) {
    width: 35.5%;
    padding-right: 7%;
  }
  .section-text table th:not(:last-child):nth-child(1),
  .section-text table td:not(:last-child):nth-child(1) {
    width: 20%;
  }
}

.section-link {
  display: inline-block;
  color: var(--black);
  text-decoration: underline !important;
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 0.3125rem;
  transition: color 0.2s ease-in-out;
}
.section-link:hover {
  color: var(--primary);
}

.section-data {
  font-weight: 500;
  font-size: max(0.875rem, 12px);
  line-height: 0.875rem;
  color: var(--text-lighter);
}
.section-data span {
  position: relative;
  margin-left: 1.25rem;
  padding-left: 4.625rem;
}
.section-data span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3.375rem;
  height: 0.25rem;
  background-color: var(--gray);
}

.section-arrow {
  display: inline-flex;
  align-items: center;
  line-height: 0;
}
.section-arrow span {
  display: inline;
  margin-right: 0.625rem;
  font-weight: 600;
  font-size: max(1rem, 10px);
  line-height: 1.125rem;
  color: var(--primary);
}
.section-arrow__img {
  width: 0.625rem;
  height: 0.625rem;
}
.section-arrow__img > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.section-arrow:hover {
  text-decoration: underline;
  text-decoration-color: var(--primary);
  text-underline-offset: 5px;
}

.page-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-head__title {
  max-width: 46.875rem;
}
.page-head__link a {
  display: inline-flex;
  font-weight: 600;
  font-size: max(1.125rem, 12px);
  line-height: 1.25rem;
  color: var(--black);
  transition: color 0.2s ease-in-out;
}
.page-head__link a:hover {
  color: var(--primary);
}
.page-head__link a:not(:last-child) {
  margin-right: 1.875rem;
}
.page-head__link a.active {
  pointer-events: none;
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 8px;
  text-decoration: underline;
  text-decoration-thickness: 4px;
}
@media (max-width: 768px) {
  .page-head {
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
  .page-head__title {
    max-width: unset;
    margin-bottom: 15px;
    padding: 0 20px;
  }
  .page-head__link {
    display: flex;
    overflow-x: auto;
    width: 100%;
    padding: 0 20px 15px;
  }
  .page-head__link a {
    flex-shrink: 0;
  }
}

.page-promo {
  position: relative;
  z-index: 1;
  background-color: #F4F5F6;
}
.page-promo__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
}
@media (min-width: 769px) {
  .page-promo__bg .page-promo__img {
    display: none;
  }
}
.page-promo__bg-img:not(.page-promo__bg--noblur)::before, .page-promo__bg-video:not(.page-promo__bg--noblur)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.75);
}
.page-promo__bg-img, .page-promo__bg-video, .page-promo__bg-slider {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.page-promo__bg-img > *, .page-promo__bg-img img, .page-promo__bg-video > *, .page-promo__bg-video img, .page-promo__bg-slider > *, .page-promo__bg-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
}
.page-promo__container {
  position: relative;
  z-index: 2;
}
.page-promo__container .swiper-slide {
  display: flex;
  height: auto;
}
.page-promo__container .swiper-slide .page-promo__title {
  max-width: 32.5rem;
  margin-bottom: 1.5rem;
}
.page-promo__container .swiper-slide .page-promo__text {
  max-width: 29rem;
}
.page-promo__container .swiper-slide .page-promo__text,
.page-promo__container .swiper-slide .page-promo__btn {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transition-delay: 0.3s;
}
.page-promo__container .swiper-slide .page-promo__btn {
  transition-delay: 0.4s;
}
.page-promo__container .swiper-slide.swiper-slide-active {
  transition-delay: 500ms !important;
}
.page-promo__container .swiper-slide.swiper-slide-active .page-promo__text,
.page-promo__container .swiper-slide.swiper-slide-active .page-promo__btn {
  opacity: 1;
  transform: translateY(0);
}
.page-promo__container .swiper.is-started .page-promo__text {
  transition-delay: 0.8s;
}
.page-promo__container .swiper.is-started .page-promo__btn {
  transition-delay: 0.9s;
}
.page-promo__content {
  min-height: min(100vh, 67.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9.625rem 0 6.5rem;
  min-width: 50%;
}
.page-promo__img {
  margin-left: 3.125rem;
}
.page-promo__img > *, .page-promo__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center bottom;
}
.page-promo__title {
  max-width: 46.6875rem;
  margin-bottom: 2.1875rem;
}
.page-promo__text {
  max-width: 44rem;
}
.page-promo__text > * {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2.1875rem;
  color: #4F4F4F;
  text-shadow: 0 0 2.5625rem #EFEFEF;
}
.page-promo__btn {
  margin-top: 2.625rem;
}
@media (max-width: 768px) {
  .page-promo__content {
    min-height: unset;
    padding: 80px 0 54px 0;
    justify-content: flex-start;
  }
  .page-promo__title {
    margin-bottom: 25px;
  }
  .page-promo__text {
    max-width: unset;
  }
  .page-promo__text > * {
    font-size: 18px;
    line-height: 32px;
  }
  .page-promo__container .swiper-slide .page-promo__title {
    margin-bottom: 25px;
  }
  .page-promo__container .swiper-slide .page-promo__img {
    display: none;
  }
  .page-promo__container .swiper-slide .page-promo__content {
    padding: 150px 0 110px 0;
  }
  .page-promo .page-promo__btn {
    margin-top: 30px;
  }
  .page-promo .page-promo__btn .btn {
    width: 100%;
  }
  .page-promo .swiper-nav-pagination {
    bottom: 50px;
    max-width: 520px;
  }
  .page-promo .page-promo__img {
    margin-left: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .page-promo .page-promo__img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(238, 238, 238, 0.8);
    z-index: 2;
  }
}

.about-content {
  padding-top: 6.25rem;
  padding-bottom: 6.875rem;
}
.about-content__videos {
  display: flex;
  align-items: flex-end;
  margin: 4.375rem 0 6.25rem;
}
.about-content__videos:not(:first-child) {
  margin-top: 4.375rem;
}
.about-content__videos:not(:last-child) {
  margin-bottom: 6.25rem;
}
.about-content__video {
  position: relative;
  width: 53.6%;
  flex-shrink: 0;
}
.about-content__video::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 64.5%;
}
.about-content__video > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-content__text {
  margin-left: 3.125rem;
  max-width: 25rem;
}
@media (max-width: 768px) {
  .about-content {
    padding: 50px 0;
  }
  .about-content__videos {
    flex-direction: column;
  }
  .about-content__videos:not(:first-child) {
    margin-top: 0;
  }
  .about-content__videos:not(:last-child) {
    margin-bottom: 3.125rem;
  }
  .about-content__video {
    width: calc(100% + 40px);
    margin: 0 -20px;
    margin-bottom: 17px;
  }
  .about-content__text {
    margin-left: 0;
    max-width: unset;
  }
}

.about-benefits {
  position: relative;
  padding: 4.375rem 0;
}
.about-benefits__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.about-benefits__bg::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25));
}
.about-benefits__bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-benefits__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px -1.25rem;
}
.about-benefits__card {
  display: flex;
  flex-direction: column;
  width: calc(33.3333333333% - 20px);
  margin: 0 10px 1.25rem;
  padding: 2.1875rem 2.1875rem;
  background-color: rgba(248, 248, 248, 0.85);
  backdrop-filter: blur(5px);
}
.about-benefits__text {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: max(1.125rem, 10px);
  line-height: 1.875rem;
  letter-spacing: -0.01em;
  color: var(--black);
}
@media (max-width: 768px) {
  .about-benefits__text {
    font-size: 16px;
    line-height: 28px;
  }
}
.about-benefits__img {
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 0.375rem;
  margin-left: auto;
  margin-top: auto;
}
.about-benefits__img > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .about-benefits {
    padding: 30px 0;
  }
  .about-benefits__container {
    flex-direction: column;
    margin: 0;
  }
  .about-benefits__card {
    width: 100%;
    margin: 0;
    padding: 20px 25px;
    flex-direction: row;
  }
  .about-benefits__card:not(:last-child) {
    margin-bottom: 15px;
  }
  .about-benefits__text {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .about-benefits__img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    margin-top: unset;
    margin-right: 0;
  }
}

.about-gallery {
  padding-top: 6.25rem;
  padding-bottom: 10.4375rem;
}
.about-gallery__text {
  break-inside: avoid;
  columns: 2;
  gap: 4.375rem;
  margin-bottom: 6.25rem;
  max-width: 64.375rem;
}
.about-gallery__text > * {
  max-width: 30rem;
  break-inside: avoid;
}
@media (max-width: 768px) {
  .about-gallery {
    padding-top: 50px;
    padding-bottom: 98px;
    overflow: hidden;
  }
  .about-gallery__text {
    columns: 1;
    margin-bottom: 50px;
  }
  .about-gallery__text > *:not(:last-child) {
    margin-bottom: 30px;
  }
  .about-gallery__text > * {
    max-width: unset;
  }
}

.catalog-blog {
  padding-top: 4.6875rem;
  padding-bottom: 10.25rem;
  overflow: hidden;
}
.catalog-blog__head {
  margin-bottom: 4.0625rem;
}
.catalog-blog__content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -50px -3.75rem;
  padding-right: 1.75rem;
}
.catalog-blog__content > * {
  display: inline-flex;
  width: calc(33.3333333333% - 100px);
  max-width: 21.25rem;
  margin: 0 50px 3.75rem;
  transition: color 0.2s ease-in-out;
}
.catalog-blog__content > *:hover .catalog-blog__title {
  color: var(--primary);
}
.catalog-blog__item {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.catalog-blog__img {
  position: relative;
  width: 21.25rem;
  flex-shrink: 0;
  margin-bottom: 1.1875rem;
}
.catalog-blog__img > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalog-blog__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 59%;
}
.catalog-blog__title {
  font-weight: 600;
  font-size: max(1.1875rem, 13px);
  max-width: 19.375rem;
  margin-top: 0.625rem;
  line-height: 1.75rem;
  color: var(--black);
  transition: color 0.2s ease-in-out;
}
.catalog-blog__pagination {
  margin-top: 6.125rem;
}
@media (max-width: 768px) {
  .catalog-blog {
    padding-top: 30px;
    padding-bottom: 144px;
  }
  .catalog-blog__head {
    margin-bottom: 50px !important;
  }
  .catalog-blog__content {
    flex-direction: column;
    margin: 0;
    padding-right: 0;
  }
  .catalog-blog__content > * {
    margin: 0;
    width: 100%;
    max-width: unset;
  }
  .catalog-blog__content > *:not(:last-child) {
    margin-bottom: 40px;
  }
  .catalog-blog__title {
    max-width: unset;
  }
  .catalog-blog__img {
    width: 100%;
    max-height: 320px;
  }
  .catalog-blog__img::before {
    padding-top: 63%;
  }
  .catalog-blog__pagination {
    margin-top: 50px;
  }
}

.blog-single {
  padding-top: 4.75rem;
  padding-bottom: 3.75rem;
}
.blog-single__info {
  margin-bottom: 3.75rem;
}
.blog-single__img {
  position: relative;
  width: 72%;
  overflow: hidden;
  margin-bottom: 3.75rem;
}
.blog-single__img > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-single__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 61.6%;
}
.blog-single__title {
  margin-bottom: 0.75rem;
}
.blog-single__text {
  margin-bottom: 5.625rem;
}
.blog-single__back {
  margin-top: 12.5rem;
}
@media (max-width: 768px) {
  .blog-single {
    padding-top: 24px;
  }
  .blog-single__title {
    padding: 0 !important;
  }
  .blog-single__info {
    margin-bottom: 23px;
  }
  .blog-single__img {
    width: 100%;
    margin-bottom: 23px;
    max-height: 350px;
  }
  .blog-single__text {
    margin-bottom: 35px;
  }
  .blog-single__back {
    margin-top: 58px;
  }
}

.for-success {
  position: relative;
  padding-top: 6.6875rem;
  padding-bottom: 5rem;
  margin-bottom: 6.25rem;
  overflow: hidden;
}
.for-success__img {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 0;
  width: 41.6%;
  bottom: 0;
  z-index: -1;
}
.for-success__img > * {
  width: auto;
  max-width: unset;
  height: 100%;
  max-height: 46.25rem;
}
.for-success__container {
  width: 46.5vw;
}
.for-success__title {
  margin-bottom: 1.5625rem;
}
.for-success__benefits {
  margin-top: 2.8125rem;
  columns: 2;
  column-gap: 5.4375rem;
  max-width: 52rem;
}
.for-success__content {
  break-inside: avoid;
}
.for-success__content:not(:last-child) {
  margin-bottom: 2.3125rem;
}
.for-success__content .section-text--small {
  max-width: 15.1875rem;
}
.for-success__subtitle {
  margin-bottom: 0.75rem;
}
@media (max-width: 768px) {
  .for-success {
    margin-bottom: 0;
    padding: 70px 0;
  }
  .for-success__container {
    width: unset;
  }
  .for-success__content:not(:last-child) {
    margin-bottom: 30px;
  }
  .for-success__img {
    z-index: -1;
    opacity: 0.2;
    width: 79.6%;
  }
  .for-success__title {
    margin-bottom: 20px;
  }
  .for-success__content .section-text--small {
    max-width: unset;
  }
  .for-success__benefits {
    max-width: 277px;
    margin-top: 50px;
    columns: 1;
  }
}

.foropt-find {
  padding-top: 1.875rem;
  padding-bottom: 5.75rem;
}
.foropt-find__container {
  display: flex;
}
.foropt-find__content {
  width: 49.5%;
}
.foropt-find__title {
  margin-bottom: 1.75rem;
}
.foropt-find__text {
  max-width: 32.5rem;
}
.foropt-find__text > * {
  font-weight: 500 !important;
  color: var(--black) !important;
}
.foropt-find__btn {
  margin-top: 3.25rem;
  position: relative;
  z-index: 2;
}
.foropt-find__img {
  position: relative;
  left: -9.6875rem;
  width: 38.4375rem;
  top: -1px;
  line-height: 0;
}
.foropt-find__img > * {
  width: 100%;
}
.foropt-find__info {
  max-width: 26.25rem;
  margin-left: 4.375rem;
}
.foropt-find__form {
  margin-top: 2.375rem;
}
.foropt-find__form .form__input {
  width: 73.9%;
}
.foropt-find__form .form__input:not(:last-child) {
  margin-bottom: 0.75rem;
}
.foropt-find__form .form__textarea {
  line-height: 0;
  width: 100%;
  margin-bottom: 1.375rem !important;
}
.foropt-find__form .form__file {
  width: 100%;
}
.foropt-find__form .form__btn {
  margin-top: 2.625rem;
}
.foropt-find__form .form__btn > * {
  min-width: 19.375rem;
}
.foropt-find__form .section-text {
  margin-bottom: 0.875rem;
}
.foropt-find__form .section-text p {
  color: var(--text-light);
  font-weight: 400;
  font-size: max(0.8125rem, 10px);
  line-height: 1.375pxrem;
}
@media (max-width: 768px) {
  .foropt-find {
    padding-top: 0;
    padding-bottom: 152px;
  }
  .foropt-find__container {
    flex-direction: column;
  }
  .foropt-find__content {
    width: 100%;
    margin-bottom: 30px;
  }
  .foropt-find__info {
    max-width: unset;
    margin-left: 0;
  }
  .foropt-find__title {
    margin-bottom: 20px;
  }
  .foropt-find__btn {
    margin-top: 30px;
    text-align: center;
  }
  .foropt-find__btn > * {
    width: 100%;
    max-width: 400px;
  }
  .foropt-find__text {
    max-width: unset;
  }
  .foropt-find__text > * {
    font-weight: 400 !important;
  }
  .foropt-find__img {
    left: unset;
    width: 92.2%;
    max-height: 300px;
    margin: 0 auto;
    overflow: hidden;
  }
  .foropt-find__img::before {
    content: "";
    width: 100%;
    display: block;
    padding-top: 102%;
  }
  .foropt-find__img img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .foropt-find__form .section-text {
    margin-bottom: 10px;
  }
  .foropt-find__form .form__input {
    width: 83.5%;
  }
  .foropt-find__form .form__textarea {
    width: 100%;
  }
  .foropt-find__form .form__file {
    width: 100%;
  }
  .foropt-find__form .form__file label {
    margin-left: 0;
  }
  .foropt-find__form .form__btn {
    margin-top: 38px;
  }
  .foropt-find__form .form__btn > * {
    min-width: unset;
  }
}

.forrozn-find {
  padding-top: 1.875rem;
  padding-bottom: 2.3125rem;
  position: relative;
}
.forrozn-find__content {
  max-width: 37.5rem;
  margin: 0 auto;
  text-align: center;
}
.forrozn-find__title {
  margin-bottom: 0.8125rem;
}
.forrozn-find__btn {
  margin-top: 3.25rem;
}
.forrozn-find__img {
  position: relative;
  height: 14.625rem;
  margin-top: 2.3125rem;
  overflow: hidden;
  width: 100%;
  background-size: auto 100%;
  background-repeat: repeat;
  background-position: center;
}
.forrozn-find__img > * {
  position: absolute;
  bottom: 0;
  top: 0;
  height: 100%;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .forrozn-find {
    padding-top: 0;
    padding-bottom: 55px;
  }
  .forrozn-find__content {
    text-align: left;
    margin: 0;
    max-width: unset;
  }
  .forrozn-find__title {
    margin-bottom: 20px;
  }
  .forrozn-find__btn {
    margin-top: 30px;
    text-align: center;
  }
  .forrozn-find__btn > * {
    width: 100%;
    max-width: 400px;
  }
  .forrozn-find__img {
    margin-top: 70px;
    height: 104px;
  }
}

.error {
  min-height: min(100vh, 67.5rem);
  position: relative;
  padding: 5.3125rem 0;
}
.error__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.error__bg img {
  width: 100%;
  height: 80%;
  object-fit: contain;
}
.error__content {
  position: relative;
  z-index: 1;
}
.error__title {
  margin-bottom: 2.5625rem;
}
.error__text {
  max-width: 17.5rem;
}
.error__img {
  display: none;
}
@media (max-width: 768px) {
  .error {
    padding-top: 24px;
    padding-bottom: 118px;
    min-height: unset;
  }
  .error__bg {
    display: none;
  }
  .error__title {
    margin-bottom: 30px;
  }
  .error__img {
    display: block;
    position: relative;
    margin-bottom: 30px;
    max-height: 300px;
  }
  .error__img::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 62.6%;
  }
  .error__img > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .error__text {
    max-width: unset;
  }
}

.banner-dist {
  padding-top: 5.75rem;
  padding-bottom: 5.625rem;
}
.banner-dist__container {
  display: flex;
  max-width: 69.125rem;
  padding-right: 5.625rem;
  margin: 0 auto;
  width: 100%;
}
.banner-dist__img {
  position: relative;
  margin-right: 7.875rem;
  width: 20.4375rem;
  flex-shrink: 0;
  overflow: hidden;
}
.banner-dist__img::before {
  content: "";
  width: 100%;
  display: block;
  padding-top: 88%;
}
.banner-dist__img > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-dist__img > *:first-child {
  z-index: 2;
  max-width: unset;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
}
.banner-dist__img > *:last-child {
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}
.banner-dist__title {
  margin-bottom: 1.25rem;
}
@media (max-width: 768px) {
  .banner-dist {
    padding: 50px 0;
  }
  .banner-dist__container {
    flex-direction: column;
    max-width: unset;
    padding-right: 0;
    margin: 0;
  }
  .banner-dist__img {
    margin-bottom: 35px;
    margin-right: 0;
    width: 211px;
  }
}

.benefits {
  padding-top: 3.9375rem;
  padding-bottom: 6.5625rem;
}
.benefits__container {
  display: flex;
  justify-content: space-between;
}
.benefits__info {
  max-width: 27.5rem;
  margin-right: 3.125rem;
}
.benefits__title {
  margin-bottom: 1.5625rem;
}
.benefits__btn {
  margin-top: 2.9375rem;
}
.benefits__content {
  display: flex;
  flex-wrap: wrap;
  max-width: 46.8125rem;
  justify-content: space-between;
  padding-top: 0.875rem;
}
.benefits__item {
  display: flex;
  max-width: 19.25rem;
  margin-bottom: 2.6875rem;
  width: calc(50% - 1.5625rem);
}
.benefits__item:nth-child(odd) {
  margin-right: 3.125rem;
}
.benefits__item:not(:last-child) {
  margin-bottom: 2.6875rem;
}
.benefits__item .section-text--small > * {
  color: var(--text-light);
}
.benefits__subtitle {
  margin-bottom: 0.75rem;
}
.benefits__item-img {
  flex-shrink: 0;
  margin-right: 1.1875rem;
  width: 3.25rem;
  height: 3.25rem;
}
.benefits__item-img > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .benefits {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .benefits__container {
    flex-direction: column;
  }
  .benefits__title {
    margin-bottom: 20px;
  }
  .benefits__info {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .benefits__btn {
    margin-top: 30px;
    text-align: center;
  }
  .benefits__btn > * {
    width: 100%;
    max-width: 450px;
  }
  .benefits__content {
    max-width: unset;
    flex-direction: column;
    margin-bottom: 0;
  }
  .benefits__item {
    max-width: unset;
    width: 100%;
    margin-bottom: 0;
  }
  .benefits__item:nth-child(odd) {
    margin-right: 0;
  }
  .benefits__item:not(:last-child) {
    margin-bottom: 30px;
  }
  .benefits__subtitle {
    margin-bottom: 12px;
  }
}

.products {
  padding-bottom: 1.25rem;
}
.products__item {
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
}
.products__item:hover .products__picture {
  opacity: 1;
  transform: translate(0, -50%);
}
.products__picture {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  height: auto;
  top: calc(50% - var(--translateY));
  width: 33%;
  z-index: 2;
}
.products__picture > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.products__picture.products__picture--left {
  left: 0;
  transform: translate(-100%, -50%);
}
.products__picture.products__picture--right {
  right: 0;
  transform: translate(100%, -50%);
}
.products__content {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 2.625rem;
  overflow: hidden;
}
.products__inner {
  position: relative;
  overflow: hidden;
}
.products__inner::before {
  content: "";
  width: 100%;
  display: block;
  padding-top: 50.1%;
}
@media (min-width: 769px) {
  .products__inner {
    max-height: 900px;
  }
}
.products__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
}
@media (min-width: 769px) {
  .products__container > *:nth-child(1) {
    grid-area: 1/1/5/2;
  }
  .products__container > *:nth-child(2) {
    grid-area: 1/2/3/3;
  }
  .products__container > *:nth-child(3) {
    grid-area: 3/2/5/3;
  }
  .products__container > *:nth-child(4) {
    grid-area: 1/3/4/4;
  }
  .products__container > *:nth-child(5) {
    grid-area: 4/3/5/4;
    min-height: 13.9375rem;
  }
  .products__container > *:nth-child(6) {
    grid-area: 1/4/3/5;
  }
  .products__container > *:nth-child(7) {
    grid-area: 3/4/5/5;
  }
}
.products__img {
  width: 100%;
  position: relative;
  flex-grow: 1;
}
.products__img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 100%;
  transform: translateX(-50%);
}
.products__text {
  z-index: 1;
  margin: 0 auto;
  max-width: 15.9375rem;
  width: 83%;
  min-height: 2.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.products__text > * {
  text-align: center;
  font-weight: 700;
  font-size: 1.1875rem;
  line-height: 1.4375rem;
  letter-spacing: -0.02em;
  color: var(--black);
}
.products--small .products__container {
  position: static;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px -1.25rem;
  grid-column-gap: unset;
  grid-row-gap: unset;
}
.products--small .products__container > * {
  width: calc(25% - 20px);
  margin: 0 10px 1.25rem;
}
.products--small .products__inner::before {
  display: none;
}
.products--small .products__item {
  position: relative;
  min-height: unset;
}
.products--small .products__item::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 111.5%;
}
.products--small .products__inner {
  max-height: unset;
}
.products--small .products__content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.products--small .products__img > img {
  max-width: 58%;
}
@media (max-width: 768px) {
  .products__container {
    display: flex;
    flex-direction: column;
    position: static;
  }
  .products__inner::before {
    display: none;
  }
  .products__content {
    flex-direction: row;
    padding-bottom: 0;
  }
  .products__item {
    padding-right: 20px;
  }
  .products__text {
    position: static;
    display: flex;
    align-items: center;
    max-width: unset;
    justify-content: unset;
    text-align: left;
    padding-left: 20px;
  }
  .products__text > * {
    text-align: left;
  }
  .products__picture {
    transform: translate(0, -50%) !important;
    opacity: 1;
  }
  .products__img {
    position: relative;
    flex-shrink: 0;
    width: 35.7%;
    max-width: 200px;
    max-height: 273px;
  }
  .products__img > img {
    width: 92%;
    height: auto;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: unset;
  }
  .products__img::before {
    content: "";
    width: 100%;
    display: block;
    padding-top: 155%;
  }
  .products--small .products__container {
    margin: 0;
  }
  .products--small .products__container > * {
    width: 100%;
    margin: 0;
  }
  .products--small .products__container > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .products--small .products__item {
    max-height: 273px;
  }
  .products--small .products__item::before {
    padding-top: 55.3%;
  }
}

.search {
  margin-bottom: 6.8125rem;
}
.search__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px -1.25rem;
}
.search__container .product-card {
  width: calc(20% - 20px);
  margin: 0 10px 1.25rem;
}
@media (min-width: 1601px) {
  .search__container .product-card {
    width: calc(16.6666666667% - 20px);
  }
  .search__container .search__field {
    width: calc(100% - (16.6666666667% + 30px) * 2);
  }
}
@media (max-width: 1600px) {
  .search__container .product-card:nth-child(9) {
    display: none;
  }
}
.search__field {
  width: calc(100% - (20% + 30px) * 2);
  margin: 0 30px 1.25rem;
  text-align: center;
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search__info {
  padding-top: 2.125rem;
  text-align: center;
  max-width: 37.5rem;
  margin: 0 auto;
}
.search__title {
  margin-bottom: 0.625rem;
}
.search__text > * {
  font-weight: 500 !important;
  color: var(--black) !important;
}
.search__form {
  display: flex;
  width: 100%;
  max-width: 1024px;
  margin: 2.4375rem auto 0.9375rem;
}
@media (min-width: 769px) {
  .search__form {
    box-shadow: 0 0.625rem 1.5625rem rgba(0, 0, 0, 0.07);
    border-radius: 1.125rem;
  }
}
.search__form > * {
  flex-grow: 1;
}
@media (min-width: 769px) {
  .search__form > * input,
  .search__form > * textarea,
  .search__form > * .output_text {
    border-radius: 0;
    width: 100%;
  }
  .search__form > *:first-child input,
  .search__form > *:first-child textarea,
  .search__form > *:first-child .output_text {
    border-radius: 1.125rem 0 0 1.125rem;
  }
  .search__form > *:last-child {
    width: auto;
  }
  .search__form > *:last-child input,
  .search__form > *:last-child textarea,
  .search__form > *:last-child .output_text {
    border-radius: 0 1.125rem 1.125rem 0;
    width: auto;
  }
}
.search__btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 991px) and (min-width: 769px) {
  .search .search__form {
    flex-wrap: wrap;
    justify-content: center;
  }
  .search .search__form .input--select {
    width: 33.3333333333%;
  }
  .search .search__form > :nth-last-child(2) {
    border-radius: 0 1.125rem 1.125rem 0;
  }
  .search .search__container {
    margin: 0 -5px -1.25rem;
  }
  .search .search__container .product-card {
    width: calc(20% - 10px);
    margin: 0 5px 1.25rem;
  }
}
@media (max-width: 768px) {
  .search {
    margin-bottom: 70px;
  }
  .search__field {
    width: 100%;
    margin: 0;
  }
  .search__title {
    margin-bottom: 20px;
  }
  .search__info {
    max-width: unset;
    padding-top: 50px;
    text-align: left;
    margin: 0;
  }
  .search__form {
    margin-top: 28px;
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;
  }
  .search__form > * {
    border-radius: 18px;
  }
  .search__form > *:first-child {
    border-radius: 18px;
  }
  .search__form > *:last-child {
    margin: 30px 0;
    border-radius: 18px;
    max-width: 400px;
    width: 100%;
  }
  .search__container {
    margin: 0;
  }
  .search__container .product-card {
    display: none;
  }
  .search__slider .product-card {
    margin: 0 33px;
  }
  .search__slider .product-card__img {
    max-height: 350px;
  }
  .search__slider .product-card__img::before {
    padding-top: 111.8%;
  }
  .search__slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    cursor: pointer;
    height: 20px;
  }
  .search__slider-btn > * {
    height: 100%;
    width: auto;
  }
  .search__slider-btn--prev {
    left: 0;
  }
  .search__slider-btn--next {
    right: 0;
  }
}

.news {
  padding-bottom: 7.0625rem;
  overflow: hidden;
}
.news__head {
  display: flex;
  align-items: center;
  margin-bottom: 2.125rem;
}
.news__head .section-arrow {
  position: relative;
  top: 0.5625rem;
}
.news__title {
  margin-right: 2.1875rem;
}
.news__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3.125rem -1.875rem;
  max-width: 82.5rem;
}
.news__container > * {
  margin: 0 3.125rem 1.875rem;
  flex-grow: 1;
  flex-basis: 0;
}
@media (max-width: 768px) {
  .news {
    padding-bottom: 95px;
  }
  .news__head {
    margin-bottom: 30px;
  }
  .news__container {
    max-width: unset;
    margin: 0;
    flex-direction: column;
  }
  .news__container .section-arrow {
    margin: 0;
    justify-content: flex-end;
  }
}

.catalogue-product {
  padding-top: 4.6875rem;
  padding-bottom: 4.0625rem;
}
.catalogue-product__container {
  display: flex;
  justify-content: space-between;
}
.catalogue-product__content {
  max-width: 41.25rem;
}
.catalogue-product__title {
  margin-bottom: 1.875rem;
}
.catalogue-product__text > * {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  line-height: 2.1875rem !important;
}
.catalogue-product__link {
  max-width: 21.875rem;
}
.catalogue-product__link .btn {
  margin-top: 1.0625rem;
  width: 100%;
}
@media (max-width: 768px) {
  .catalogue-product {
    padding-top: 30px;
    padding-bottom: 70px;
  }
  .catalogue-product__container {
    flex-direction: column;
  }
  .catalogue-product__content {
    max-width: unset;
  }
  .catalogue-product__text > * {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 26px !important;
  }
  .catalogue-product__link {
    margin-top: 20px;
    max-width: unset;
  }
  .catalogue-product__link a {
    max-width: 233px;
    padding: 9px 14px;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }
}

.product-destination {
  padding-bottom: 10.9375rem;
  margin-top: 6.875rem;
}
.product-destination__info {
  margin-bottom: 2.8125rem;
}
.product-destination__info .section-text > * {
  color: var(--black);
}
.product-destination__title {
  margin-bottom: 1.875rem;
}
.product-destination .products {
  padding-bottom: 0;
}
@media (max-width: 768px) {
  .product-destination {
    margin-top: 70px;
    padding-bottom: 130px;
  }
  .product-destination__title {
    margin-bottom: 20px;
  }
  .product-destination__info {
    margin-bottom: 30px;
  }
}

.crown-category .container {
  display: flex;
}
.crown-category:not(:last-child) {
  margin-bottom: 1.875rem;
}
.crown-category--box .crown-category__bg {
  z-index: 1;
}
.crown-category__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 55%;
  max-width: 100%;
  line-height: 0;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
.crown-category__bg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left bottom;
}
.crown-category__container {
  position: relative;
  padding: 3.4375rem 4.1875rem 3.3125rem 3.75rem;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #EDEDED;
  min-height: 16.25rem;
  transition: box-shadow 0.3s ease-in-out;
}
.crown-category__container:hover {
  box-shadow: 0 8px 50px rgba(19, 20, 21, 0.07);
}
.crown-category__container:not(:last-child) {
  margin-right: 1.25rem;
}
@media (min-width: 769px) {
  .crown-category__container:hover .crown-category__bg {
    opacity: 0.05;
  }
  .crown-category__container:hover .crown-category__text {
    opacity: 1;
  }
  .crown-category__container.has-info:hover .crown-category__bg {
    opacity: 0.05;
  }
  .crown-category__container.has-info:hover .crown-category__info {
    opacity: 1;
  }
}
.crown-category__content {
  z-index: 2;
  display: block;
}
.crown-category__info {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.crown-category__title:not(:last-child) {
  margin-bottom: 1.875rem;
}
.crown-category__text {
  padding-top: 1.875rem;
  position: relative;
  max-width: 25rem;
  transition: opacity 0.3s ease-in-out;
}
@media (min-width: 769px) {
  .crown-category__text {
    opacity: 0;
  }
}
.crown-category__text::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0.5rem;
  width: 8.75rem;
  background-color: var(--primary);
}
.crown-category__list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 57.6%;
  margin: 0 -15px -3.4375rem;
}
.crown-category__list + .crown-category__bg {
  width: 40%;
}
.crown-category__item {
  position: relative;
  text-align: center;
  width: calc(25% - 30px);
  margin: 0 15px 3.4375rem;
}
@media (min-width: 769px) {
  .crown-category__item:hover .crown-category__item-title::after {
    opacity: 1;
  }
}
.crown-category__item-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.crown-category__item-img {
  position: relative;
}
.crown-category__item-img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 83%;
}
.crown-category__item-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.crown-category__item-title {
  font-weight: 600;
  font-size: max(1rem, 10px);
  line-height: 1.25rem;
  position: relative;
  padding-top: 0.625rem;
}
.crown-category__item-title::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: var(--primary);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.crown-category__info {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
}
.crown-category__info .crown-category__text {
  padding-top: 0;
  margin-bottom: 20px;
  max-width: unset;
}
.crown-category__info .crown-category__text::after {
  content: none;
}
.crown-category__info .crown-category__btn {
  margin-top: auto;
}
@media (max-width: 768px) {
  .crown-category:not(:last-child) {
    margin-bottom: 20px;
  }
  .crown-category .container {
    flex-direction: column;
  }
  .crown-category__container {
    padding: 35px 9px 40px;
    flex-direction: column;
    min-height: unset;
  }
  .crown-category__container:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .crown-category__container.has-info .crown-category__content {
    margin-bottom: 0;
  }
  .crown-category__bg {
    top: unset;
    width: 100%;
    height: 140px;
  }
  .crown-category__bg img {
    object-position: right bottom;
  }
  .crown-category--box .crown-category__container {
    box-shadow: none;
  }
  .crown-category__content {
    max-width: unset;
    margin-bottom: 52px;
    padding: 0 18px;
  }
  .crown-category__title:not(:last-child) {
    margin-bottom: 20px;
  }
  .crown-category__text {
    padding-top: 0;
    max-width: 100%;
  }
  .crown-category__text::after {
    display: none;
  }
  .crown-category__list {
    width: 100%;
    margin: 0 0 -34px;
  }
  .crown-category__list + .crown-category__bg {
    display: none;
  }
  .crown-category__item {
    width: 33.3333333333%;
    margin: 0;
    margin-bottom: 34px;
  }
  .crown-category__item-img {
    max-height: 130px;
  }
  .crown-category__item-img::before {
    padding-top: 79.5%;
  }
  .crown-category__item-title {
    font-size: 14px;
    line-height: 18px;
    padding-top: 0;
  }
  .crown-category__info {
    opacity: 1;
  }
  .crown-category__info .crown-category__text {
    margin-bottom: 30px;
  }
}

.contact {
  padding-top: 4.5625rem;
}
.contact__title {
  margin-bottom: 4.1875rem;
}
.contact__container {
  display: flex;
  margin-bottom: 3.5rem;
}
.contact__place {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 5rem;
}
.contact__place li:not(:last-child) {
  margin-right: 1.875rem;
}
.contact__place li.is-active .contact__subtitle::after {
  width: 6.25rem;
}
.contact__place address {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.01em;
  margin-bottom: 0.9375rem;
  cursor: pointer;
}
.contact__subtitle {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: max(1rem, 12px);
  line-height: 1.875rem;
  letter-spacing: -0.01em;
  color: var(--text-lighter);
  margin-bottom: 0.625rem;
}
.contact__subtitle::after {
  content: "";
  display: block;
  margin-left: 17px;
  max-width: 100%;
  height: 0.25rem;
  width: 0;
  background-color: var(--primary);
  transition: width 0.3s ease-in-out;
}
.contact__mail {
  margin-bottom: 2.125rem;
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 8px;
  text-decoration: underline;
}
.contact__mail > * {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.01em;
  color: var(--black);
  transition: color 0.2s ease-in-out;
}
.contact__mail:hover > * {
  color: var(--primary);
}
.contact__plan {
  margin-top: -0.625rem;
  margin-bottom: 0.9375rem;
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 5px;
  text-decoration: underline;
}
.contact__plan > * {
  font-weight: 500;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
  color: var(--black);
  transition: color 0.2s ease-in-out;
}
.contact__plan:hover > * {
  color: var(--primary);
}
.contact__info {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
  color: var(--text-light);
}
.contact__phone {
  flex-shrink: 0;
}
.contact__phone .contact__subtitle::after {
  display: none;
}
.contact__phone-number:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.contact__phone-number a {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.05em;
  color: var(--black);
  transition: color 0.2s ease-in-out;
}
.contact__phone-number a:hover {
  color: var(--primary);
}
.contact__phone-number span {
  margin-top: 0.3125rem;
}
.contact__map {
  height: 560px;
  width: 100%;
}
@media (max-width: 768px) {
  .contact {
    padding-top: 24px;
  }
  .contact__title {
    margin-bottom: 50px;
  }
  .contact__container {
    flex-direction: column;
    margin-bottom: 70px;
  }
  .contact__place {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .contact__place li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .contact__place address {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .contact__mail {
    margin-bottom: 30px;
  }
  .contact__mail > * {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .contact__btn > * {
    width: 100%;
    max-width: 400px;
  }
  .contact__plan {
    margin-top: -5px;
  }
  .contact__phone-number:not(:last-child) {
    margin-bottom: 10px;
  }
}

.product-list {
  padding-top: 1.25rem;
  padding-bottom: 6.75rem;
}
.product-list__container {
  margin-top: 4.0625rem;
  display: flex;
  justify-content: space-between;
}
.product-list__aside {
  max-width: 17.9375rem;
  flex-shrink: 0;
  width: 100%;
  margin-right: 2.625rem;
}
.product-list__content {
  width: 100%;
}
.product-list__top {
  margin-bottom: 0.9375rem;
  display: flex;
  align-items: center;
}
.product-list__top > *:not(:last-child) {
  margin-right: 16px;
}
.product-list__selected {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
}
.product-list__selected.is-visible {
  opacity: 1;
}
.product-list__selected-inner {
  margin-right: 0.625rem;
}
.product-list__selected-inner > *:not(:last-child) {
  margin-right: 0.625rem;
}
.product-list__selected .btn svg {
  margin-left: 0.3125rem;
  cursor: pointer;
  width: 9px;
  height: 9px;
}
.product-list__sort {
  margin-left: auto;
}
.product-list__sort .dropdown {
  background: none !important;
  cursor: default;
}
.product-list__sort .dropdown .product-list__sort .dropdown ul li {
  cursor: pointer;
}
.product-list__sort .dropdown.is-open .output_text, .product-list__sort .dropdown:hover .output_text {
  background-color: rgba(42, 46, 50, 0.05);
}
.product-list__sort .dropdown .output_text {
  position: relative;
  font-size: 14px;
  line-height: normal;
  transition: background-color 0.3s ease-in-out;
  margin: -0.4375rem -0.9375rem -0.5rem -0.9375rem;
  padding: 0.4375rem 1.875rem 0.5rem 2.25rem;
}
.product-list__sort .dropdown .output_text::before, .product-list__sort .dropdown .output_text::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.product-list__sort .dropdown .output_text::before {
  top: 52%;
  width: 0.9375rem;
  height: 0.9375rem;
  left: 0.9375rem;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none"%3E%3Crect width="15" height="2" fill="%23333333"/%3E%3Crect y="4" width="10" height="2" fill="%23333333"/%3E%3Crect y="8" width="5" height="2" fill="%23333333"/%3E%3C/svg%3E');
}
.product-list__sort .dropdown .output_text::after {
  width: 0.625rem;
  height: 0.625rem;
  right: 0.9375rem;
  background-image: url('data:image/svg+xml,%3Csvg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 1L5 5L9 1" stroke="%23333333"/%3E%3C/svg%3E%0A');
}
.product-list__sort .dropdown ul {
  cursor: default;
  display: none;
  opacity: 1;
  visibility: visible;
  transition: none;
  box-shadow: 0px 1px 23px 0px rgba(30, 33, 36, 0.13);
  padding-top: 0.625rem;
}
.product-list__sort .dropdown ul li {
  padding: 0.4375rem 1rem;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}
.product-list__sort .dropdown ul li.is-selected {
  background: var(--primary);
  color: var(--white);
  pointer-events: none;
}
.product-list__sort .dropdown ul li:hover {
  background-color: rgba(42, 46, 50, 0.05);
}
.product-list__cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -0.625rem;
  position: relative;
  z-index: 1;
}
.product-list__cards .product-card {
  width: calc(25% - 10px);
  margin: 0 5px 0.625rem;
}
@media (min-width: 1600px) {
  .product-list__cards .product-card {
    width: calc(20% - 10px);
  }
}
.product-list__pagination {
  margin-top: 4.375rem;
}
.product-list__descr {
  border-top: 4px solid var(--gray);
  padding-top: 2.25rem;
  margin-top: 7.875rem;
  display: flex;
}
.product-list__descr > * {
  max-width: 30rem;
}
.product-list__descr > *:not(:last-child) {
  margin-right: 3.125rem;
}
.product-list__descr .section-text p:not(:last-child) {
  margin-bottom: 1.125rem;
}
.product-list__toggle {
  display: none;
}
.product-list__filters > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.product-list__filter {
  position: relative;
  display: flex;
  flex-direction: column;
}
.product-list__filter.is-open .product-list__filter-name::after {
  transform: rotate(180deg);
}
.product-list__filter.is-empty .product-list__filter-name {
  cursor: default;
}
.product-list__filter-name {
  font-size: remF(22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.75rem;
  border-bottom: 0.25rem solid #F4F4F4;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  position: relative;
}
.product-list__filter-name:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0.5625rem;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
  background-image: url('data:image/svg+xml,%3Csvg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 1L7 7L13 1" stroke="%23333333" stroke-width="2"/%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s ease-in-out;
}
.product-list__filter-content {
  display: none;
  padding-top: 0.9375rem;
}
.product-list__filter-content > :not(:last-child) {
  margin-bottom: 0.625rem;
}
.product-list__filter-slider {
  bottom: 0.4375rem;
  position: absolute;
  left: 1.0625rem;
  right: 1.0625rem;
  height: 0.25rem;
}
.product-list__filter-slider .ui-slider-handle {
  width: 1.0625rem;
  height: 1.0625rem;
  background: var(--primary);
  border-radius: 50%;
  outline: none !important;
  top: 50%;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.product-list__filter-slider .ui-slider-handle:nth-child(2) {
  transform: translate(-100%, -50%);
}
.product-list__filter-slider .ui-slider-handle:nth-child(3) {
  transform: translate(0%, -50%);
}
.product-list__filter-slider .ui-slider-range {
  background: var(--primary);
}
.product-list__filter-slider + .product-list__filter-name {
  padding-bottom: 1rem;
  margin-bottom: 0.4375rem;
}
.product-list__filter-output {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
}
.product-list__btn {
  margin-top: 3.25rem;
}
.product-list__nothing {
  padding: 0 0 3.125rem;
}
@media (max-width: 1260px) {
  .product-list__cards .product-card {
    width: calc(33.3333333333% - 10px);
    margin: 0 5px 0.625rem;
  }
}
@media (max-width: 768px) {
  .product-list {
    padding-top: 24px;
    padding-bottom: 133px;
    position: relative;
  }
  .product-list__container {
    flex-direction: column;
  }
  .product-list__cards {
    margin: 0;
  }
  .product-list__cards .product-card {
    width: 100%;
    margin: 0;
  }
  .product-list__cards .product-card:not(:last-child) {
    margin-bottom: 10px;
  }
  .product-list__pagination {
    margin-top: 50px;
    margin-bottom: 86px;
  }
  .product-list__descr {
    padding-top: 30px;
    flex-direction: column;
  }
  .product-list__descr > * {
    max-width: unset;
  }
  .product-list__descr > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .product-list__aside {
    max-width: unset;
    width: unset;
    margin-right: 0;
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 2;
    display: none;
    padding-bottom: 23px;
    top: 45px;
    padding-top: 5px;
  }
  .product-list__aside::after {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    bottom: 0;
    transform: translateY(100%);
    height: 99999px;
    background: rgba(238, 238, 238, 0.8);
    backdrop-filter: blur(5px);
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
    opacity: 0;
  }
  .product-list__aside.is-visible::after {
    opacity: 1;
  }
  .product-list__content {
    z-index: 1;
  }
  .product-list__container {
    position: relative;
    margin-top: 25px;
  }
  .product-list__top {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .product-list__toggle {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  .product-list__toggle img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    flex-shrink: 0;
    margin-right: 4px;
  }
  .product-list__sort {
    order: 1;
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    z-index: 1000;
  }
  .product-list__sort .dropdown {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    right: -14px;
    max-width: calc(100% + 14px);
    width: max-content;
  }
  .product-list__sort .dropdown .output_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .product-list__sort .dropdown ul {
    max-width: calc(100vw - 40px);
    font-size: 14px;
    line-height: normal;
  }
  .product-list__count {
    width: 100%;
    margin-right: 0 !important;
    order: 2;
    margin-top: 20px;
    font-size: 14px;
    line-height: normal;
  }
  .product-list__selected {
    order: 3;
    margin-right: 0 !important;
    margin-top: 7px;
    display: block;
  }
  .product-list__selected:not(.is-visible) {
    display: none;
  }
  .product-list__selected .btn {
    white-space: break-spaces;
    display: inline-block;
  }
  .product-list__selected-inner {
    display: inline;
    margin-right: 5px;
  }
  .product-list__btn .btn {
    width: 100%;
  }
  .product-list__filters {
    position: relative;
  }
  .product-list__btn {
    margin-top: 32px;
    position: sticky;
    bottom: 0;
    padding: 20px;
    background: var(--white);
    margin-left: -20px;
    margin-right: -20px;
    z-index: 99;
  }
  .product-list__aside {
    padding-bottom: 0;
  }
}

.product {
  padding-top: 1.625rem;
  padding-bottom: 3.75rem;
}
.product .product-card__favorite {
  transition: background-color 0.3s ease-in-out;
}
.product .product-card__favorite svg path {
  fill: var(--white);
}
.product .product-card__favorite.is-added {
  background: var(--primary);
}
.product .product-card__favorite.is-added svg path {
  stroke: var(--white);
}
.product .product-card__favorite.is-added > span {
  color: var(--white);
}
.product .product-card__favorite.is-added:hover > span {
  color: var(--black);
}
.product__container {
  display: flex;
  justify-content: space-between;
}
.product__left {
  width: 28.8%;
  margin-right: 5rem;
}
.product__form {
  width: calc(71.2% - 5rem);
}
.product__gallery-arrows {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  height: 0;
  align-items: center;
}
.product__gallery-arrows > * {
  width: 0.625rem;
  height: 1.25rem;
  cursor: pointer;
}
.product__gallery-arrows > *.swiper-button-disabled {
  pointer-events: none;
}
.product__gallery-arrows > *.swiper-button-disabled svg path {
  stroke: var(--text-white);
}
.product__gallery-arrows > * svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product__gallery-main {
  margin-bottom: 3.375rem;
  width: 100%;
  position: relative;
}
.product__gallery-main .product-card__tags {
  position: absolute;
  left: 0;
  top: 2.25rem;
  z-index: 2;
}
.product__gallery-main .product-card__tags > * {
  font-weight: 800;
  font-size: 1.4375rem;
  line-height: 1.375rem;
  padding: 0.5rem 1.0625rem;
}
.product__gallery-main .product__gallery-arrows {
  opacity: 0;
}
.product__gallery-main__item {
  position: relative;
}
.product__gallery-main__item > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product__gallery-main__item::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 126.8%;
}
.product__gallery-next {
  position: relative;
}
.product__gallery-next .swiper-thumbs {
  width: 82%;
}
.product__gallery-next .swiper {
  padding: 0 1px;
}
.product__gallery-next__item {
  position: relative;
  cursor: pointer;
  transition: border 0.2s ease-in;
  border: 2px solid #F2F2F2;
  outline: 1px solid transparent;
  transition: border-color 0.3s ease-in-out, outline-color 0.3s ease-in-out;
}
.product__gallery-next__item.swiper-slide-thumb-active {
  border: 2px solid var(--primary);
  outline: 1px solid transparent;
}
.product__gallery-next__item > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product__gallery-next__item::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.product__gallery-next__item:hover {
  border-color: var(--primary);
  outline-color: var(--primary);
}
.product__info {
  display: flex;
  align-items: center;
  margin-bottom: 1.375rem;
}
.product__info > *:not(:last-child) {
  margin-right: 1.875rem;
}
.product__info .product-card__favorite {
  position: static;
  width: unset;
  height: unset;
  display: flex;
  align-items: center;
  padding: 0.1875rem 0.9375rem;
  border: 1px solid var(--primary);
  border-radius: 0.625rem;
}
.product__info .product-card__favorite span {
  font-weight: 600;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
  color: var(--black);
  transition: color 0.3s ease-in-out;
}
.product__info .product-card__favorite svg {
  width: 0.875rem;
  height: 0.875rem;
  object-fit: contain;
  margin-right: 0.625rem;
}
.product__info .product-card__favorite:hover span {
  color: var(--primary);
}
.product__info-link {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary);
  padding: 0.1875rem 0.9375rem;
  border-radius: 0.625rem;
  color: var(--black);
  transition: color 0.3s ease-in-out;
}
.product__info-link .product-card__favorite {
  position: static;
  display: flex;
  margin-right: 0.625rem;
  width: 0.875rem;
  height: 0.875rem;
}
.product__info-link span {
  font-weight: 600;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
}
.product__info-link:hover {
  color: var(--primary);
}
.product__code {
  font-weight: 600;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
  color: var(--text);
}
.product__code span {
  font-weight: 400;
}
.product__code--small {
  color: var(--black);
}
.product__code--small span {
  font-size: max(0.75rem, 10px);
  margin-right: 0.625rem;
  color: var(--text);
}
.product__title {
  max-width: 40rem;
  margin-top: 0.125rem;
  margin-bottom: 0.625rem;
}
.product__choises {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2.8125rem;
}
.product__choises-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #4F4F4F;
  margin-bottom: 3px;
}
.product__choises-item:not(:last-child) {
  margin-right: 2.5rem;
}
.product__options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.product__options li {
  margin: 0 5px 0.625rem;
}
.product__options--colors .input--radio input:checked + .input--radio__btn span:last-child {
  opacity: 1;
}
.product__options--colors .input--radio__btn {
  padding: 0 0 0 0.28125rem;
  width: 2.25rem;
  height: 2.25rem;
  transition: width 0.3s ease-in-out;
  justify-content: flex-start;
  overflow: hidden;
}
.product__options--colors .input--radio__btn span:first-child {
  display: block;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  flex-shrink: 0;
}
.product__options--colors .input--radio__btn span:last-child {
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
  padding: 0 1rem 0 0.625rem;
  flex-shrink: 0;
}
.product__variants {
  display: flex;
  align-items: flex-start;
  margin: 0 -10px 2.8125rem;
  transition: opacity 0.3s ease-in-out;
}
.product__variants li {
  width: calc(50% - 20px);
  margin: 0 10px;
}
.product__variants li .product__variants-btns {
  margin-left: 3.1875rem;
}
.product__variants li:first-child .product__variants-row {
  max-width: 363px;
}
.product__variants li:first-child .product__variants-btns {
  max-width: 310px;
}
.product__variants-row {
  display: flex;
}
.product__variants-row .input--radio {
  width: 100%;
}
.product__variants-row .input--radio__check {
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 1.5625rem;
}
.product__variants-row .product-card__content {
  text-align: left;
  margin-left: 3.1875rem;
  position: relative;
}
.product__variants-row .product-card__price {
  margin-bottom: 0.1875rem;
  display: flex;
  flex-direction: column;
}
.product__variants-row .product-card__price--old {
  position: static;
  right: unset;
  top: unset;
  bottom: -1.25rem;
  order: 1;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
}
.product__variants-row .product-card__price--new {
  font-size: 1.875rem;
  line-height: 1.875rem;
}
.product__variants-row .product-card__package > * {
  text-align: unset;
  font-weight: 400;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
}
.product__variants-btns, .product__variants .quantity {
  display: none;
}
.product__variants-btns {
  width: 100%;
  margin-top: 1.25rem;
}
.product__variants-btns > *:not(:first-child) {
  display: none;
}
.product__variants-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.5rem;
}
.product__variants-status .product__status > * {
  max-width: 5.8125rem;
  text-align: right;
  font-weight: 400;
  font-size: max(0.875rem, 10px);
  line-height: 0.875rem;
}
.product__variants.is-loading {
  opacity: 0.5;
  cursor: progress;
}
.product__variants.is-loading > * {
  pointer-events: none;
}
.product__similiar {
  padding-top: 1.4375rem;
  margin-bottom: 2.8125rem;
  border-top: 2px solid var(--gray);
}
.product__similiar-title {
  margin-bottom: 0.6875rem;
}
.product__similiar-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px -1.25rem;
}
.product__similiar-container > * {
  width: calc(50% - 20px);
  margin: 0 10px 1.25rem;
}
.product__similiar-btn {
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
}
.product__table {
  border-top: 4px solid var(--gray);
  border-bottom: 2px solid var(--gray);
  margin-bottom: 1.875rem;
  padding-top: 2.1875rem;
  padding-bottom: 1rem;
}
.product__table ul {
  columns: 2;
  column-gap: 1.25rem;
  margin-top: 2.1875rem;
}
.product__table ul li {
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
}
.product__table ul li > * {
  font-weight: 400;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
  color: var(--text);
}
.product__table ul li span:nth-child(2) {
  font-weight: 600;
  text-align: right;
  padding-left: 1.25rem;
}
.product__table ul li:nth-child(odd) {
  background-color: #F3F3F3;
}
.product__table ul li:nth-child(even) {
  background-color: var(--white);
}
.product__sketch {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
  color: var(--black);
}
.product__sketch img {
  width: 1rem;
  height: 1.3125rem;
  object-fit: cover;
  margin-right: 0.625rem;
}
.product__sketch:not(:last-child) {
  margin-right: 2.5rem;
}
.product .section-text > * {
  font-weight: 300;
  max-width: unset;
}
.product__row {
  display: flex;
  margin-top: auto;
}
@media (max-width: 1180px) {
  .product .product__gallery-next {
    padding: 0 15px;
  }
}
@media (max-width: 768px) {
  .product {
    padding-bottom: 50px;
  }
  .product__container {
    flex-direction: column;
  }
  .product__left {
    width: 100%;
    margin-right: 0;
  }
  .product__title {
    margin-top: 5px;
    max-width: unset;
  }
  .product__title > * {
    font-size: 30px;
  }
  .product__info {
    margin: 0 -10px -10px;
    flex-wrap: wrap;
  }
  .product__info > * {
    margin: 0 10px 10px !important;
  }
  .product__code--small span {
    margin-right: 5px;
  }
  .product__gallery {
    margin-top: 40px;
  }
  .product__gallery-main {
    margin-bottom: 30px;
  }
  .product__gallery-main .product-card__tags > * {
    font-size: 20px;
    padding: 6px 17px 6px 21px;
  }
  .product__gallery-main .product__gallery-arrows {
    opacity: 1;
    z-index: 2;
  }
  .product__gallery-main__item {
    max-height: 350px;
  }
  .product__gallery-main__item::before {
    padding-top: 109.4%;
  }
  .product__gallery-next {
    display: none;
  }
  .product__similiar {
    padding-top: 19px;
    margin-bottom: 35px;
  }
  .product__similiar-title {
    margin-bottom: 5px;
  }
  .product__similiar-container {
    margin: 0;
  }
  .product__similiar-container > * {
    width: 100%;
    margin: 0;
  }
  .product__similiar-container > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .product__table {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .product__table ul {
    margin-top: 16px;
    columns: 1;
  }
  .product__table ul li {
    padding-left: 0;
    padding-right: 14px;
  }
  .product__sketch:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 25px;
  }
  .product .product__choises-item:not(:last-child) {
    margin-right: 0;
  }
  .product .product__options li {
    margin: 0 5px 10px;
    white-space: nowrap;
  }
  .product .product__options {
    margin-bottom: 0;
    overflow-x: auto;
    padding-top: 1px;
    flex-wrap: nowrap;
  }
  .product .product__choises {
    margin-bottom: 38px;
  }
  .product .product__variants {
    flex-direction: column;
    margin-bottom: 32px;
  }
  .product .product__variants li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .product .product__variants li {
    min-width: 100%;
  }
  .product .product__variants-row .input--radio__check {
    font-size: 20px;
    line-height: 25px;
  }
  .product .product__variants-info {
    margin-top: 3px;
  }
  .product .product__variants-row .product-card__price--new {
    font-size: 25px;
    line-height: 30px;
  }
  .product .product__variants-row .product-card__price--old {
    font-size: 12px;
    line-height: 16px;
  }
  .product .product__variants-row .product-card__package > * {
    font-size: 12px;
    line-height: 14px;
  }
  .product .product__variants--available p {
    font-size: 12px;
    line-height: 12px;
  }
  .product .product__variants-btns {
    width: calc(100% - 43px);
    margin-top: 5px;
    margin-left: 43px;
  }
  .product .product__variants-row .product-card__content {
    margin-left: 43px;
  }
  .product .input--radio__check {
    padding-left: 43px;
  }
  .product .product__choises-item {
    width: 100%;
  }
  .product .product__form {
    width: 100%;
  }
  .product .product__variants {
    margin: 0 0 32px;
  }
  .product .product__variants li {
    width: 100%;
    margin: 0;
  }
  .product .product__variants li:first-child .product__variants-row {
    max-width: 100%;
  }
  .product .product__variants li .product__variants-btns {
    margin-left: 43px;
  }
  .product .product__variants li:first-child .product__variants-btns {
    max-width: 100%;
  }
}

.card-post:hover .card-post__title {
  color: var(--primary);
}
.card-post__inner {
  margin-top: 0.625rem;
}
.card-post__title {
  font-weight: 600;
  font-size: max(1.1875rem, 10px);
  line-height: 1.75rem;
  margin-bottom: 0.8125rem;
  color: var(--black);
  transition: color 0.3s ease-in-out;
}
.card-post__text {
  font-weight: 400;
  font-size: max(0.9375rem, 10px);
  line-height: 1.5625rem;
  color: var(--text);
}
@media (max-width: 768px) {
  .card-post {
    margin: 0;
    flex-grow: unset;
    flex-basis: unset;
  }
  .card-post:not(:last-child) {
    margin-bottom: 30px;
  }
  .card-post__inner {
    padding-right: 30px;
  }
}

.similiar-products {
  padding-bottom: 5.8125rem;
}
.similiar-products__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -0.625rem;
}
.similiar-products__container .product-card {
  width: calc(20% - 10px);
  margin: 0 5px 0.625rem;
}
.similiar-products__title {
  margin-bottom: 2.1875rem;
}
@media (max-width: 768px) {
  .similiar-products {
    padding-bottom: 62px;
  }
  .similiar-products__title {
    margin-bottom: 15px;
  }
  .similiar-products__container {
    margin: 0;
  }
  .similiar-products__container .product-card {
    width: 100%;
    margin: 0;
  }
  .similiar-products__container .product-card:not(:last-child) {
    margin-bottom: 10px;
  }
}

.cart {
  padding-top: 5.3125rem;
  padding-bottom: 5.625rem;
}
.cart__container {
  max-width: 58.375rem;
  margin: 0 auto;
}
.cart__title {
  margin-bottom: 2.6875rem;
}
.cart__body {
  margin-bottom: 2.5625rem;
}
.cart__bottom {
  padding-top: 1.6875rem;
  border-top: 4px solid var(--gray);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.cart__price {
  text-align: right;
}
.cart__price--min {
  display: flex;
  align-items: flex-start;
  max-width: 23.9375rem;
  margin-bottom: 1.1875rem;
}
.cart__price--min img {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  object-fit: contain;
}
.cart__price--min span {
  font-weight: 700;
}
.cart__form {
  padding-top: 3.25rem;
  border-top: 0.25rem solid #F4F4F4;
  display: flex;
}
.cart__form-title {
  margin-bottom: 2.1875rem;
}
.cart__form-subtitle {
  margin-bottom: 1.875rem;
}
.cart__form-text {
  margin-left: 4.0625rem;
  flex-basis: 0;
  flex-grow: 1;
  margin-top: 8.4375rem;
}
.cart__form-inner {
  width: 57%;
}
.cart__form-privacy {
  margin-top: 1rem;
}
.cart__form-privacy.section-text p {
  color: var(--text-light);
}
.cart__form .form > .section-text {
  margin-bottom: 10px;
}
.cart__form .form > .section-text p {
  color: #828282;
}
.cart__form .form__btn .btn {
  width: auto;
  min-width: 19.375rem;
}
.cart-item {
  display: flex;
  align-items: center;
}
.cart-item:not(:last-child) {
  margin-bottom: 1.5625rem;
}
.cart-item .product-card__content {
  margin-left: unset;
  margin-right: 1.875rem;
}
@media (min-width: 769px) {
  .cart-item .product-card__content {
    flex-shrink: 0;
    flex-grow: 1;
  }
}
.cart-item .product-card__price {
  margin-bottom: 0;
}
.cart-item .product-card__price--new {
  font-size: 1.25rem;
}
.cart-item .product-card__price--old {
  top: -1.0625rem;
  font-size: max(0.75rem, 10px);
}
.cart-item .product-card__package > * {
  font-size: max(0.75rem, 10px);
}
.cart-item .quantity__input {
  width: 2.5625rem;
  font-size: 1.25rem;
}
.cart-item .quantity__btn {
  width: 1rem;
  height: 1rem;
}
.cart-item__img {
  display: block;
  width: 5.9375rem;
  height: 5.9375rem;
  margin-right: 1.3125rem;
  flex-shrink: 0;
}
.cart-item__img > * {
  width: 100%;
  height: 100% !important;
  object-fit: contain;
}
.cart-item__info {
  max-width: 23.6875rem;
  width: 100%;
  margin-right: 2.5rem;
}
.cart-item__info span {
  display: inline-block;
  margin-top: 2px;
  font-weight: 500;
  font-size: max(0.875rem, 10px);
  line-height: 1rem;
}
.cart-item__title > * {
  transition: color 0.3s ease-in-out;
}
.cart-item__title:not(div):hover > * {
  color: var(--primary);
}
.cart-item__total {
  margin-left: auto;
  margin-right: 2.875rem;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.375rem;
}
@media (min-width: 769px) {
  .cart-item__total {
    min-width: 20%;
    padding-left: 1.5625rem;
    text-align: right;
    white-space: nowrap;
  }
}
.cart-item__close {
  display: inline-flex;
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
}
.cart-item__close > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart-item__close > * path {
  transition: stroke 0.2s ease-in-out;
}
.cart-item__close:hover svg path {
  stroke: var(--primary);
}
.cart-item:not(.cart-item--smallest) .cart-item__info + .quantity {
  margin-left: auto;
}
.cart-item:not(.cart-item--smallest) .quantity + .cart-item__close {
  margin-left: auto;
}
.cart-item.cart-item--smallest {
  padding: 0.625rem 1.1875rem 0.625rem 0.625rem;
  color: var(--black);
}
.cart-item.cart-item--smallest .cart-item__img {
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 0.625rem;
}
.cart-item.cart-item--smallest .cart-item__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart-item.cart-item--smallest .cart-item__title,
.cart-item.cart-item--smallest .cart-item__title > * {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
}
.cart-item.cart-item--smallest .cart-item__title:last-child,
.cart-item.cart-item--smallest .cart-item__title:last-child > * {
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.cart-item.cart-item--smallest .product-card__content {
  max-width: 14.125rem;
  text-align: left;
  margin-right: 0;
  flex-grow: 1;
  margin-left: auto;
}
.cart-item.cart-item--smallest .cart-item__total {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.5625rem;
  margin-left: 0;
  margin-right: 0;
  min-width: unset;
  padding: 0;
  text-align: left;
}
.cart-item.cart-item--smallest .cart-item__quantity {
  text-align: right;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
  flex-shrink: 0;
  margin-left: 0.9375rem;
  align-self: flex-start;
}
.cart-item--small:not(:last-child) {
  margin-bottom: 1.25rem;
}
.cart-item--small .cart-item__img {
  width: 2rem;
  height: 3.125rem;
  margin-right: 0.4375rem;
}
.cart-item--small .cart-item__info {
  max-width: 21rem;
  margin-right: 4.125rem;
}
.cart-item--small .cart-item__title > * {
  font-weight: 400;
  font-size: max(1.125rem, 10px);
  line-height: 1.5rem;
}
.cart-item--small .cart-item__total {
  margin-right: 0;
  font-weight: 600;
  font-size: max(1.125rem, 10px);
  line-height: 1.0625rem;
}
.cart-item--small .cart-item__count {
  font-weight: 400;
  font-size: max(1.125rem, 10px);
  line-height: 1.0625rem;
}
.cart.cart--wholesale {
  padding-bottom: 10rem;
}
@media (max-width: 768px) {
  .cart {
    padding-top: 24px;
    padding-bottom: 76px;
  }
  .cart__title {
    margin-bottom: 33px;
  }
  .cart__body {
    margin-bottom: 20px;
  }
  .cart__bottom {
    padding-top: 23px;
    flex-direction: column;
  }
  .cart__price {
    order: -1;
    margin-bottom: 64px;
  }
  .cart__price--min {
    max-width: unset;
  }
  .cart__form {
    padding-top: 45px;
    flex-direction: column;
  }
  .cart__form-inner {
    width: 100%;
  }
  .cart__form-title {
    margin-bottom: 16px;
  }
  .cart__form-privacy {
    margin-top: 20px;
  }
  .cart__form .form__btn .btn {
    width: 100%;
    min-width: unset;
  }
  .cart__form-text {
    margin-left: 0;
    margin-top: 10px;
  }
  .cart__form-subtitle {
    margin-bottom: 30px;
  }
  .cart__form .form {
    margin-top: 30px;
  }
  .cart__form .form > .section-text {
    margin-top: 10px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: unset;
    justify-content: unset;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest):not(:last-child) {
    margin-bottom: 20px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .cart-item__img {
    width: 60px;
    height: 60px;
    margin-right: 28px;
    margin-left: 20px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .cart-item__close {
    position: absolute;
    left: 0;
    top: 10px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .cart-item__title > * {
    font-size: 16px;
    line-height: 22px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .cart-item__total {
    font-size: 16px;
    align-self: center;
    text-align: right;
    margin-right: 0;
    width: auto;
    margin-left: auto;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .cart-item__info {
    max-width: unset;
    width: calc(100% - 108px);
    margin-right: 0;
    margin-bottom: 3px;
    min-height: 60px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .product-card__price--new {
    font-size: 16px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .product-card__price--old {
    font-size: 11px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .product-card__package > * {
    font-weight: 500;
    font-size: 11px;
    text-align: left;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .product-card__content {
    margin-right: 0;
    padding-top: 17px;
    width: 108px;
    text-align: left;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .product-card__price--old {
    right: unset;
    left: 0;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .quantity {
    margin: 0;
    align-self: center;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .quantity__input {
    font-size: 16px;
  }
  .cart-item:not(.cart-item--small):not(.cart-item--smallest) .quantity__btn {
    width: 1rem;
    height: 1rem;
  }
  .cart-item--small {
    flex-wrap: wrap;
    position: relative;
    padding-left: 60px;
    min-height: 50px;
    align-items: center;
  }
  .cart-item--small:not(:last-child) {
    margin-bottom: 15px;
  }
  .cart-item--small .cart-item__info {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .cart-item--small .cart-item__img {
    position: absolute;
    margin-right: 0;
    width: 50px;
    left: 0;
    top: 0;
  }
  .cart-item--small .cart-item__title > * {
    font-size: 16px;
  }
  .cart-item--small .cart-item__total {
    font-size: 16px;
  }
  .cart-item--small .cart-item__count {
    font-size: 16px;
  }
  .cart.cart--wholesale {
    padding-bottom: 110px;
  }
}

.main-banner {
  margin-bottom: 1.875rem;
}
.main-banner .container.container--full {
  padding: 0;
}
.main-banner__container {
  display: flex;
  position: relative;
  overflow: hidden;
}
@media (min-width: 769px) {
  .main-banner__container {
    height: 35.625rem;
  }
}
.main-banner__item {
  width: calc(50% - 10px);
  position: absolute;
  top: 0;
  bottom: 0;
  transition: width 0.3s linear;
  z-index: 1;
  overflow: hidden;
  background: linear-gradient(359.39deg, rgba(255, 255, 255, 0.8) 16.5%, rgba(255, 255, 255, 0) 52.26%), #F8F8F8;
}
.main-banner__item::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background: rgba(238, 238, 238, 0.8);
  backdrop-filter: blur(5px);
  transition: opacity 0.3s ease-in-out;
  width: 100vw;
}
.main-banner__item:first-child {
  left: 0;
}
.main-banner__item:first-child .main-banner__item-bg > * {
  right: -28.125rem;
}
.main-banner__item:first-child .main-banner__item-content {
  left: var(--container-margin);
  right: 8.75rem;
}
.main-banner__item:first-child .main-banner__item-hidden {
  left: calc(50vw - 10px);
}
.main-banner__item:first-child .btn {
  left: 100%;
}
.main-banner__item:first-child::after {
  right: 0;
  transform: translateX(100%);
}
.main-banner__item:last-child {
  right: 0;
}
.main-banner__item:last-child .main-banner__item-bg > * {
  left: -28.125rem;
}
.main-banner__item:last-child .main-banner__item-content {
  left: 8.75rem;
  right: var(--container-margin);
}
.main-banner__item:last-child .main-banner__item-hidden {
  right: calc(50vw - 10px);
}
.main-banner__item:last-child .btn {
  right: 100%;
}
.main-banner__item:last-child::after {
  left: 0;
  transform: translateX(-100%);
}
@media (min-width: 769px) {
  .main-banner__item:hover {
    width: calc(50% + 27.5rem);
  }
  .main-banner__item:hover::after {
    opacity: 1;
  }
  .main-banner__item:hover:first-child .main-banner__item-content {
    right: 26.8125rem;
  }
  .main-banner__item:hover:first-child .main-banner__item-bg > * {
    right: 0;
  }
  .main-banner__item:hover:last-child .main-banner__item-content {
    left: 26.25rem;
  }
  .main-banner__item:hover:last-child .main-banner__item-bg > * {
    left: 0;
  }
  .main-banner__item:hover .title-link .btn img {
    opacity: 0;
  }
  .main-banner__item:hover .title-link .btn > *:not(img) {
    opacity: 1;
  }
  .main-banner__item:hover .main-banner__item-hidden {
    opacity: 1;
    transition: opacity 0.3s linear 0.3s;
  }
  .main-banner__item:last-child .main-banner__item-hidden {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .main-banner__item:last-child .main-banner__item-title {
    flex-direction: row-reverse;
    text-align: right;
  }
  .main-banner__item:last-child .main-banner__item-title .title-link {
    margin-right: 4.375rem;
    margin-left: 0;
    flex-direction: row-reverse;
  }
  .main-banner__item:last-child .main-banner__item-title .title-link .btn img {
    transform: rotate(180deg) translate(50%, 50%);
  }
  .main-banner__item-hidden .section-text {
    margin-top: auto;
    margin-bottom: 6.25rem;
  }
}
.main-banner__item-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}
.main-banner__item-bg > * {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  max-width: unset;
  width: auto;
  transition: left 0.3s linear, right 0.3s linear;
}
.main-banner__item-content {
  position: absolute;
  bottom: 3.5625rem;
  right: 0;
  z-index: 3;
  transition: left 0.3s linear, right 0.3s linear;
}
.main-banner__item-title {
  display: flex;
  align-items: flex-end;
}
.main-banner__item-title .title-h3 {
  flex-shrink: 0;
}
.main-banner__item-title .title-link {
  min-height: 3.375rem;
  max-width: 100%;
  flex-grow: 1;
  margin-left: 4.375rem;
  transition: max-width 0.5s ease-in-out;
}
.main-banner__item-title .title-link .btn {
  position: absolute;
  width: 3.375rem;
  padding: 1rem 1.625rem;
  overflow: hidden;
  border-radius: 1.875rem;
  transition: border-radius 0.3s ease-in-out, width 0.3s ease-in-out;
}
.main-banner__item-title .title-link .btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out;
  width: 45%;
  height: 45%;
  object-fit: contain;
}
.main-banner__item-title .title-link .btn > *:not(img) {
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
}
.main-banner__item-hidden {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 27.5rem;
  padding: 7.0625rem 1.875rem 3.5625rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.2s linear;
  z-index: 2;
}
.main-banner__item-hidden .section-text {
  max-width: 20.625rem;
}
@media (max-width: 768px) {
  .main-banner {
    margin-bottom: 0;
  }
  .main-banner__container {
    flex-direction: column;
  }
  .main-banner__item:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .main-banner__item {
    width: 100%;
    position: relative;
  }
  .main-banner__item::after {
    backdrop-filter: none;
    opacity: 1;
  }
  .main-banner__item-bg {
    z-index: 1;
  }
  .main-banner__item-hidden {
    background: rgba(232, 232, 232, 0.6901960784);
    background-blend-mode: multiply;
  }
  .main-banner__item-content {
    display: none;
  }
  .main-banner__item-bg__inner::before {
    padding-top: 0;
  }
  .main-banner__item-bg__inner {
    max-height: unset;
  }
  .main-banner__item-hidden {
    max-width: 100%;
    padding: 100px 22px 40px;
    min-height: 530px;
    position: relative;
    justify-content: flex-end;
  }
  .main-banner__item-hidden br {
    display: none;
  }
  .main-banner__item-hidden [class*=title-] {
    margin-bottom: 15px;
  }
  .main-banner__item-hidden .section-text {
    max-width: unset;
  }
  .main-banner__item-hidden__btn {
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  .main-banner__item-hidden__btn > * {
    max-width: 400px;
    width: 100%;
  }
  .main-banner__item-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .main-banner__item-hidden > * {
    opacity: 1;
  }
  .main-banner .main-banner__item:first-child .main-banner__item-hidden {
    left: 0;
  }
  .main-banner .main-banner__item-hidden {
    opacity: 1;
    z-index: 7;
    width: 100%;
  }
  .main-banner .main-banner__container::before {
    content: none;
  }
  .main-banner .main-banner__item:last-child .main-banner__item-hidden {
    right: 0;
  }
}

.orders {
  padding-top: 4.6875rem;
  padding-bottom: 8.5625rem;
}
.orders__head {
  margin-bottom: 5.25rem;
}
.orders__accordion {
  max-width: 40.125rem;
  margin: 0 auto;
}
.orders__accordion .info-open {
  display: none;
}
@media (max-width: 768px) {
  .orders {
    padding-top: 30px;
    padding-bottom: 100px;
  }
  .orders__head {
    margin-bottom: 48px;
  }
  .orders__accordion {
    max-width: unset;
  }
  .orders__title {
    margin-bottom: 15px;
    padding-left: 20px;
  }
}

.register {
  padding-top: 4.75rem;
  padding-bottom: 9.375rem;
  min-height: min(100vh, 1080px);
  display: flex;
  align-items: center;
}
.register .success-message {
  display: none;
  margin-bottom: 6.25rem;
}
.register__title {
  margin-bottom: 3.4375rem;
}
.register__container {
  max-width: 40.625rem;
  margin: 0 auto;
}
.register__form-row {
  margin-bottom: 2.8125rem;
}
.register__form-row .input--select.is-open {
  border-bottom-right-radius: 0;
}
.register__btn {
  max-width: 19.375rem;
  margin-bottom: 1rem;
}
.register__btn > * {
  width: 100%;
}
.register .section-text {
  max-width: 29.0625rem;
}
.register .section-text:not(:last-child) {
  margin-bottom: 0.625rem;
}
@media (max-width: 768px) {
  .register {
    padding-top: 24px;
    padding-bottom: 100px;
  }
  .register__title {
    margin-bottom: 57px;
  }
  .register__row {
    margin-bottom: 50px;
  }
  .register__btn {
    margin-bottom: 20px;
    text-align: center;
    max-width: unset;
  }
  .register__btn > * {
    width: 100%;
    max-width: 400px;
  }
  .register .section-text {
    max-width: unset;
  }
}

.account {
  padding-top: 4.6875rem;
  padding-bottom: 12.5625rem;
}
.account__head {
  margin-bottom: 3.4375rem;
}
.account__container {
  margin: 0 auto;
  max-width: 40.625rem;
}
.account__form .show-on-success {
  display: none;
}
.account__form-row {
  position: relative;
  z-index: 2;
  margin-bottom: 3.75rem;
}
.account__form-row .section-text {
  margin-bottom: 0.625rem;
}
.account__form-row#payment {
  position: relative;
  z-index: 1 !important;
}
.account__form-title {
  margin-bottom: 2.1875rem;
}
.account__form-btn {
  padding-top: 2.8125rem;
  border-top: 4px solid var(--gray);
  display: flex;
  align-items: center;
}
.account__form-btn > *:not(:last-child) {
  margin-right: 0.9375rem;
}
.account__form-btn .btn {
  max-width: 13.5625rem;
  width: 100%;
}
@media (max-width: 768px) {
  .account {
    padding-top: 24px;
    padding-bottom: 110px;
  }
  .account__head {
    margin-bottom: 48px;
  }
  .account__title {
    padding-left: 20px;
    margin-bottom: 15px;
  }
  .account__form-title {
    margin-bottom: 20px;
  }
  .account__form-row {
    margin-bottom: 50px;
  }
  .account__form-row .section-text {
    padding-top: 10px;
  }
  .account__form-btn {
    padding-top: 50px;
    text-align: center;
  }
  .account__form-btn > * {
    width: 100%;
    max-width: 400px;
  }
}

.search-results {
  padding-top: 5.3125rem;
  padding-bottom: 10.5rem;
}
.search-results__title {
  margin-bottom: 4.0625rem;
}
.search-results__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.625rem;
}
.search-results__text {
  font-weight: 400;
  font-size: max(0.875rem, 10px);
  line-height: 1.0625rem;
}
.search-results__container {
  margin-bottom: 4.6875rem;
  display: flex;
  flex-direction: column;
}
.search-results__cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -0.625rem;
}
.search-results__cards > * {
  width: calc(20% - 10px);
  margin: 0 5px 0.625rem;
}
@media (max-width: 1080px) {
  .search-results__cards > * {
    width: calc(25% - 10px);
  }
}
@media (max-width: 768px) {
  .search-results {
    padding-top: 24px;
    padding-bottom: 78px;
  }
  .search-results__title {
    margin-bottom: 13px;
  }
  .search-results__head {
    flex-direction: column;
    margin-bottom: 19px;
  }
  .search-results__text {
    margin-right: auto;
    margin-bottom: 50px;
  }
  .search-results__container {
    margin-bottom: 50px;
  }
  .search-results__cards {
    margin: 0;
  }
  .search-results__cards > * {
    width: 100%;
    margin: 0;
  }
  .search-results__cards > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .search-results .dropdown-click {
    margin-left: auto;
  }
}

.favourites {
  padding-top: 5.3125rem;
  padding-bottom: 8.3125rem;
}
.favourites__title {
  margin-bottom: 1.3125rem;
}
.favourites .section-text p {
  max-width: 63.125rem;
}
.favourites__container {
  margin-top: 1.625rem;
  margin-bottom: 4.6875rem;
  display: flex;
  flex-direction: column;
}
.favourites .dropdown-click {
  margin-bottom: 1.625rem;
  margin-left: auto;
}
.favourites__cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -0.625rem;
}
.favourites__cards > * {
  width: calc(20% - 10px);
  margin: 0 5px 0.625rem;
}
@media (max-width: 1080px) {
  .favourites__cards > * {
    width: calc(25% - 10px);
  }
}
@media (max-width: 768px) {
  .favourites {
    padding-top: 24px;
    padding-bottom: 137px;
  }
  .favourites__title {
    margin-bottom: 30px;
  }
  .favourites__container {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .favourites__cards {
    margin: 0;
  }
  .favourites__cards > * {
    width: 100%;
    margin: 0;
  }
  .favourites__cards > *:not(:last-child) {
    margin-bottom: 10px;
  }
}

.checkout {
  padding-bottom: 7.25rem;
  padding-top: 4.6875rem;
}
.checkout__container {
  display: flex;
  justify-content: space-between;
}
.checkout__col {
  width: calc(50% - 4.0625rem);
}
.checkout__title {
  margin-bottom: 2.5rem;
}
.checkout__subtitle {
  margin-bottom: 1.5625rem;
}
.checkout__text {
  margin-top: 2.1875rem;
  margin-bottom: 2.0625rem !important;
}
.checkout__block:not(:last-child) {
  margin-bottom: 3.75rem;
}
.checkout__mobile {
  display: none;
}
.checkout__radio .wcus-checkout-fields {
  margin-top: 10px;
  margin-left: 43px;
  width: calc(100% - 43px);
  position: relative;
}
.checkout__radio .wcus-checkout-fields > h3 {
  color: #828282;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 20px;
}
.checkout__radio .wcus-checkout-fields .input__arrow {
  position: absolute;
  right: 10px;
  bottom: 17px;
  width: 10px;
  height: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
  line-height: 0;
}
.checkout__radio .wcus-checkout-fields .input__arrow > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.checkout__radio .input {
  display: flex;
}
.checkout__radio .form-row {
  padding: 0;
  margin: 0;
}
.checkout__radio-item:not(:last-child) {
  margin-bottom: 1.5625rem;
}
.checkout__radio-item + .input:not(:last-child) {
  margin-bottom: 1.5625rem;
}
.checkout__radio-item a {
  outline: none !important;
  font-weight: 500;
  color: var(--black);
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 4px;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}
.checkout__radio-item a:hover {
  color: var(--primary);
}
.checkout__subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: var(--black);
  margin-bottom: 1.875rem;
}
.checkout__subtotal > *:last-child {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.checkout__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 20px;
  color: var(--black);
  margin-bottom: 47px;
  padding-top: 25px;
  margin-top: 30px;
  border-top: 4px solid #F4F4F4;
}
.checkout__total > *:last-child {
  font-weight: 700;
  font-size: 30px;
  line-height: 22px;
}
.checkout__review {
  padding: 30px 40px 40px;
  border: 4px solid #F8F8F8;
  position: relative;
}
.checkout__review .cart-item.cart-item--small:not(:last-child) {
  margin-bottom: 10px;
}
.checkout__review .cart-item.cart-item--small .cart-item__img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.checkout__review .cart-item.cart-item--small .cart-item__count,
.checkout__review .cart-item.cart-item--small .cart-item__total,
.checkout__review .cart-item.cart-item--small .cart-item__title > * {
  font-size: 15px;
  line-height: 19px;
}
.checkout__review-list {
  padding-bottom: 24px;
  margin-bottom: 13px;
  border-bottom: 2px solid #F4F4F4;
}
.checkout__review-back {
  position: absolute;
  background: #fff;
  right: 30px;
  padding: 0 10px;
  top: -17px;
}
.checkout__review-back img {
  margin-right: 8px;
}
.checkout__review-info > * {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
}
.checkout__review-info > *:not(:last-child) {
  margin-bottom: 1.25rem;
}
.checkout__review-info > * > *:first-child > * {
  color: #828282;
}
.checkout__review-info > * > *:nth-child(2) {
  text-align: right;
  font-weight: 600;
}
.checkout__btn > button {
  width: 100%;
}
.checkout__btn .hidden {
  display: none;
}
.checkout__descr {
  margin-top: 16px;
}
.checkout__descr .section-text p {
  color: #828282;
}
.checkout__descr .section-text--small p {
  font-size: 13px !important;
}
.checkout__code.checkout__total {
  padding-top: 14px;
  margin-bottom: -16px;
  display: flex;
  flex-direction: column;
}
.checkout__code .woocommerce-form-coupon-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.checkout__code .woocommerce-form-coupon-toggle:not(.is-open) + * {
  display: none !important;
}
.checkout__code .checkout_coupon {
  display: none;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  width: 100%;
}
.checkout__code-input {
  position: relative;
  padding: 16px 0 !important;
}
.checkout__code-input input {
  width: 100%;
  height: 46px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 18px;
  border: 1px solid var(--text-white);
  background: #FFF;
  padding: 0 144px 0 16px;
}
.checkout__code-input .btn {
  position: absolute;
  top: 20px;
  right: 4px;
  bottom: 20px;
}
.checkout__code-error {
  font-size: 11px !important;
  line-height: normal !important;
  color: #EB5757;
  text-align: left;
  width: 100%;
  font-weight: normal !important;
}
.checkout .form-row.place-order {
  position: absolute;
  left: -9999px;
}
@media (max-width: 768px) {
  .checkout {
    padding-bottom: 30px;
    padding-top: 24px;
  }
  .checkout__col {
    width: 100%;
  }
  .checkout__col-inner {
    overflow-y: auto;
    height: 100%;
    margin-right: -15px;
    padding-right: 15px;
  }
  .checkout__total {
    font-size: 16px;
    margin-bottom: 20px;
    padding-top: 10px;
  }
  .checkout__total:first-child {
    margin: 0 0 11px;
    border: none;
    padding: 0;
  }
  .checkout__total > :last-child {
    font-size: 25px;
    line-height: 40px;
  }
  .checkout__total a:not(.btn) {
    color: var(--primary);
    padding-left: 14px;
    position: relative;
  }
  .checkout__total a:not(.btn)::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    background-image: url('data:image/svg+xml,%3Csvg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 8L7.24329 3.82588C6.84605 3.3845 6.15395 3.3845 5.75671 3.82588L2 8" stroke="%23FEB11C" stroke-width="2" stroke-linecap="square"/%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .checkout__mobile {
    display: block;
    padding-bottom: 24px;
  }
  .checkout__mobile-btn .btn {
    width: 100%;
  }
  .checkout__review {
    padding: 0;
    border: none;
  }
  .checkout__review-back {
    display: none;
  }
  .checkout__review-list {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .checkout__review-info > :not(:last-child) {
    margin-bottom: 15px;
  }
  .checkout__descr {
    margin-top: 20px;
  }
  .checkout .woocommerce-checkout-review-order {
    display: none;
    padding: 26px 0 53px;
  }
  .checkout .woocommerce-checkout-review-order .checkout__subtitle {
    position: relative;
    margin-bottom: 32px;
  }
  .checkout .woocommerce-checkout-review-order .checkout__subtitle::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    right: 0;
    background-image: url('data:image/svg+xml,%3Csvg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3L11.7474 11.1592C11.3496 11.6067 10.6504 11.6067 10.2526 11.1592L3 3" stroke="%23FEB11C" stroke-width="4" stroke-linecap="square"/%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .checkout .woocommerce-checkout-review-order-table {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--White, #FFF);
    box-shadow: 0px -8px 50px 0px rgba(19, 20, 21, 0.15);
    padding: 10px 20px 0;
    max-height: calc(100% - 80px);
    transition: height 0.3s ease-in-out 0.3s;
    z-index: 10000;
    display: flex;
    flex-direction: column;
  }
  .checkout .woocommerce-checkout-review-order-table::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    transform: translateY(-100%);
    background: rgba(238, 238, 238, 0.8);
    backdrop-filter: blur(5px);
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out 0.6s, opacity 0.3s ease-in-out 0.6s;
  }
  .checkout .woocommerce-checkout-review-order-table.is-expanded::before {
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
  }
}

.is-hidden {
  display: none !important;
}

.success {
  display: flex;
  align-items: flex-start;
  color: var(--black);
}
.success .section-text p {
  color: #000;
}
.success__img {
  width: 3.75rem;
  flex-shrink: 0;
  margin-right: 1.875rem;
}
.success__content {
  padding-top: 0.4375rem;
}
.success__title:not(:last-child) {
  margin-bottom: 1.875rem;
}
.success-message {
  position: relative;
  padding-left: 5.625rem;
}
.success-message::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3.75rem;
  height: 3.4375rem;
  background-image: url("data:image/svg+xml,%3Csvg width='63' height='59' viewBox='0 0 63 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='29.5' cy='29.5' r='27.5' stroke='%23FEB11C' stroke-width='4'/%3E%3Cpath d='M15 27.5L29 41.5L61.5 9' stroke='%23FEB11C' stroke-width='4'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.success-message__btn, .success-message__text {
  margin-top: 1.875rem;
}
.success-message__btn {
  display: flex;
  align-items: center;
}
.success-message__btn img {
  flex-shrink: 0;
  margin-right: 10px;
}
.success-message__btn a:not(.btn) {
  color: inherit;
  transition: color 0.3s ease-in-out;
  text-decoration: underline !important;
  text-decoration-color: var(--primary) !important;
  text-underline-offset: 6px;
  text-decoration-thickness: 1.5px !important;
}
.success-message__btn a:not(.btn):hover {
  color: var(--primary);
}
@media (max-width: 768px) {
  .success {
    flex-direction: column;
    text-align: center;
  }
  .success__img {
    margin-right: auto;
    line-height: 0;
    margin-left: auto;
    margin-bottom: 26px;
  }
  .success__title:not(:last-child) {
    margin-bottom: 20px;
  }
  .success__content {
    padding-top: 0;
  }
  .success-message {
    padding-left: 0;
    padding-top: 85px;
    text-align: center;
  }
  .success-message::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .success-message .section-text--big p {
    font-size: 14px;
    line-height: 26px;
  }
  .success-message__btn, .success-message__text {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 79%;
  }
}

.footer {
  padding-top: 2.5625rem;
  padding-bottom: 1.875rem;
  background-color: var(--black);
  color: var(--text-white);
}
.footer__logo {
  margin-bottom: 1.875rem;
}
.footer__logo a {
  height: 4.5625rem;
  display: block;
  max-width: 100%;
}
.footer__logo a > * {
  height: 100%;
  width: auto;
  object-fit: contain;
}
.footer__container {
  display: flex;
  justify-content: space-between;
}
.footer__block {
  min-width: 33.55%;
}
.footer__block > *:not(:last-child) {
  margin-bottom: 3rem;
}
.footer__row {
  display: flex;
  margin: 0 -0.9375rem;
  justify-content: space-between;
}
.footer__row > * {
  margin: 0 0.9375rem;
  max-width: 13.75rem;
}
.footer__list li:not(:last-child) {
  margin-bottom: 0.625rem;
}
.footer__list > * {
  font-weight: 300;
  font-size: max(0.8125rem, 10px);
  line-height: 1.25rem;
}
.footer__list a {
  color: var(--text-white);
  transition: color 0.2s ease-in-out;
}
.footer__list a:hover {
  color: var(--primary);
}
.footer__title:not(:last-child) {
  margin-bottom: 0.75rem;
}
.footer__title a {
  color: var(--text-white);
  transition: color 0.2s ease-in-out;
  display: block;
  width: max-content;
  max-width: 100%;
}
.footer__title a:hover {
  color: var(--primary);
}
.footer__contact {
  display: flex;
  min-width: 21.625rem;
  padding-top: 0.4375rem;
}
.footer__phone:not(:last-child) {
  margin-right: 1.625rem;
}
.footer__phone a {
  transition: color 0.2s ease-in-out;
}
.footer__phone a:hover {
  color: var(--primary);
}
.footer__phone > * {
  font-weight: 500;
  font-size: max(1.1875rem, 12px);
  line-height: 1.25rem;
  color: var(--text-light);
}
.footer__phone span {
  display: block;
  font-weight: 300;
  font-size: max(0.8125rem, 10px);
  line-height: 1.25rem;
}
.footer__socials {
  height: 100%;
}
.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.5rem;
  padding-top: 1.25rem;
  border-top: 1px solid var(--text);
}
.footer__bottom > *:not(.footer__bottom--img) {
  flex-basis: 0;
  flex-grow: 1;
}
.footer__bottom > *:not(.footer__bottom--img):last-child {
  display: flex;
  justify-content: flex-end;
}
.footer__bottom span {
  display: inline-block;
  font-weight: 300;
  font-size: max(0.8125rem, 10px);
  line-height: 1.25rem;
}
.footer__bottom--img {
  line-height: 0;
  display: flex;
}
.footer__bottom--img > * {
  width: 3rem;
  height: auto;
  object-fit: contain;
}
.footer__bottom--img > *:not(:last-child) {
  margin-right: 0.5625rem;
}
.footer__bottom--create {
  cursor: pointer;
  padding-right: 2.3125rem;
  align-items: center;
  display: flex;
  color: inherit;
}
.footer__bottom--create img {
  height: 1.8125rem;
  object-fit: contain;
}
.footer__bottom--create span {
  margin-left: 1.25rem;
  max-width: max(9.0625rem, 107px);
}
.footer__list {
  margin-top: 22px;
}
.footer__nav--mobile {
  margin-right: 25px;
}
@media (max-width: 768px) {
  .footer {
    padding-top: 30px;
    padding-bottom: 19px;
  }
  .footer__container {
    flex-direction: column;
  }
  .footer__logo {
    margin-bottom: 14px;
  }
  .footer__logo a {
    height: 75px;
  }
  .footer__block:nth-child(1) {
    margin-bottom: 26px;
  }
  .footer__block:not(:last-child) {
    margin-bottom: 30px;
  }
  .footer__block:not(.footer__nav--mobile) .footer__row > * {
    flex-grow: 1;
    flex-basis: 0;
  }
  .footer__block:not(.footer__nav--mobile) .footer__row > * .footer__title {
    max-width: 83%;
  }
  .footer__nav--mobile .footer__row:nth-child(1) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .footer__nav--mobile .footer__row:nth-child(1) > * {
    flex-basis: 50%;
    flex-grow: 1;
    max-width: unset;
  }
  .footer__nav--mobile .footer__info:nth-child(1) {
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .footer__row {
    margin: 0;
    line-height: 0;
  }
  .footer__row > * {
    margin: 0;
    max-width: unset;
  }
  .footer__contact {
    flex-shrink: 0;
    flex-direction: column;
    min-width: unset;
    margin-right: 44px;
  }
  .footer__title:not(:last-child) {
    margin-bottom: 20px;
  }
  .footer__phone {
    max-width: 164px;
  }
  .footer__phone:not(:last-child) {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .footer__info:not(:last-child) {
    padding-right: 5px;
  }
  .footer__bottom {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 37px;
    padding-top: 24px;
  }
  .footer__bottom span:nth-child(1) {
    order: 1;
    margin-bottom: 60px;
  }
  .footer__bottom--create {
    order: 2;
    padding-right: 0;
  }
  .footer__bottom--img {
    margin-bottom: 25px;
  }
  .footer__list li:not(:last-child) {
    margin-bottom: 10px;
  }
  .footer__nav--mobile {
    margin-right: 0;
  }
  .footer .footer__bottom-block {
    order: 2;
  }
}

.gallery__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.25rem -2.5rem;
}
.gallery__link {
  position: relative;
  display: inline-block;
  margin: 0 1.25rem 2.5rem;
  width: calc(33.3333333333% - 2.5rem);
  line-height: 0;
}
.gallery__link::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70%;
}
.gallery__link > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .gallery {
    overflow: hidden;
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
  .gallery__container {
    margin: 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    padding: 0 20px 15px;
  }
  .gallery__link {
    margin: 0;
    width: 213px;
    flex-shrink: 0;
  }
  .gallery__link:not(:last-child) {
    margin-right: 10px;
  }
  .gallery__link::before {
    padding-top: 70%;
  }
}

.product-card {
  border: 1px solid #EDEDED;
  flex-shrink: 0;
  position: relative;
  transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.product-card.is-favorite .product-card__favorite svg path {
  fill: var(--primary);
  stroke: var(--primary);
}
.product-card.is-loading {
  cursor: progress;
}
.product-card.is-loading > * {
  pointer-events: none;
}
@media (min-width: 769px) {
  .product-card.is-loading {
    box-shadow: 0px 8px 50px rgba(19, 20, 21, 0.07);
  }
  .product-card.is-loading .product-card__buttons {
    opacity: 1;
    visibility: visible;
  }
}
.product-card.is-unavailable .product-card__link {
  opacity: 0.35;
}
@media (any-hover: hover) {
  .product-card.is-unavailable .product-card__link {
    transition: opacity 0.3s ease-in-out;
  }
  .product-card.is-unavailable:hover .product-card__link {
    opacity: 1;
  }
}
.product-card.is-unavailable .product-card__row > .btn {
  width: 100%;
}
.product-card.is-unavailable .product-card__row {
  justify-content: center;
}
.product-card .quantity {
  flex-grow: 1;
  justify-content: center;
}
.product-card__top {
  position: absolute;
  left: 0;
  right: 1.25rem;
  top: 1.25rem;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  height: 0;
}
.product-card__tags {
  flex-direction: column;
  display: flex;
}
.product-card__favorite {
  width: 1.4375rem;
  height: 1.4375rem;
  position: absolute;
  right: 0;
  cursor: pointer;
}
.product-card__favorite:not(.is-added) .cross-line {
  display: none;
}
.product-card__favorite svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-card__favorite svg path {
  transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
}
.product-card__favorite:hover svg path {
  stroke: var(--primary);
}
.product-card__link {
  display: block;
  position: relative;
  padding: 1.25rem 1.25rem 1.0625rem;
  z-index: 2;
}
.product-card__link:hover .product-card__title {
  color: var(--primary);
}
.product-card__img {
  position: relative;
  max-width: 13.375rem;
  margin: 0 auto 1.25rem;
}
.product-card__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  z-index: -1;
}
.product-card__img::before {
  content: "";
  width: 100%;
  display: block;
  padding-top: 111.8%;
}
.product-card__info {
  text-align: center;
}
.product-card__about {
  margin-top: 1.375rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-card__spec {
  margin-right: 0.625rem;
}
.product-card__spec li {
  display: flex;
  align-items: center;
}
.product-card__spec li:not(:last-child) {
  margin-bottom: 0.4375rem;
}
.product-card__spec li span {
  display: inline-block;
  font-weight: 300;
  font-size: max(0.75rem, 10px);
  line-height: 1rem;
  color: var(--text-light);
}
.product-card__spec li img {
  width: 0.875rem;
  height: 1rem;
  object-fit: contain;
  margin-right: 0.3125rem;
}
.product-card__content {
  margin-left: auto;
  text-align: right;
}
.product-card__price {
  position: relative;
  margin-bottom: 0.6875rem;
}
.product-card__price--old {
  position: absolute;
  right: 0;
  top: -1.375rem;
  font-weight: 600;
  font-size: max(0.8125rem, 10px);
  line-height: 1.375rem;
  text-decoration-line: line-through;
  color: var(--red);
}
.product-card__price--new {
  font-weight: 700;
  font-size: 1.5625rem;
  line-height: 1.375rem;
  color: var(--black);
}
.product-card__package > * {
  font-weight: 500;
  font-size: max(0.8125rem, 10px);
  line-height: 0.9375rem;
  text-align: right;
  color: var(--text);
}
.product-card__buttons {
  position: absolute;
  transform: translateY(100%);
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 1.25rem 1.25rem;
  background: var(--white);
  box-shadow: 0px 0.5rem 3.125rem rgba(19, 20, 21, 0.07);
  opacity: 0;
  z-index: 1;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.product-card__buttons a:not(.btn) {
  display: flex;
  justify-content: center;
  margin-top: 0.9375rem;
  font-weight: 500;
  font-size: max(0.8125rem, 10px);
  line-height: 1.25rem;
  text-align: center;
}
.product-card__buttons::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  top: -4.25rem;
  height: 4.25rem;
  z-index: 1;
  background-color: var(--white);
}
.product-card__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px -0.625rem;
}
.product-card__row > * {
  margin: 0 10px 0.625rem;
}
.product-card__row .btn {
  flex-grow: 1;
}
.product-card__unavailable {
  text-align: center;
}
.product-card__unavailable:not(:last-child) {
  margin-bottom: 1.125rem;
}
.product-card__unavailable > * {
  font-weight: 400;
  font-size: max(0.875rem, 10px);
  line-height: 0.875rem;
  color: #EB5757;
}
.product-card__title {
  margin-bottom: 0.4375rem;
  color: var(--black);
  font-weight: 700;
  font-size: max(1rem, 10px);
  line-height: 1.25rem;
  transition: color 0.3s ease-in-out;
}
.product-card__text {
  font-weight: 600;
  font-size: max(0.75rem, 10px);
  line-height: 14px;
  color: var(--text-lighter);
}
@media (min-width: 769px) {
  .product-card:hover {
    box-shadow: 0px 8px 50px rgba(19, 20, 21, 0.07);
  }
  .product-card:hover .product-card__buttons {
    opacity: 1;
    visibility: visible;
  }
  .product-card.product-card--small .product-card__img::before {
    padding-top: 82%;
  }
  .product-card.product-card--small .product-card__img {
    margin-bottom: 24px;
  }
  .product-card.product-card--small .product-card__title {
    margin-bottom: 4px;
  }
  .product-card.product-card--small .product-card__link {
    padding: 1.25rem 1.25rem 14px;
  }
}
@media (max-width: 768px) {
  .product-card__img {
    max-height: 300px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.similiar-card {
  padding: 0;
}
.similiar-card .product-card__top {
  pointer-events: none;
}
.similiar-card .product-card__tags .tag {
  font-size: 10px;
  line-height: 10px;
  padding: 0.25rem 0.625rem;
}
.similiar-card__link {
  display: flex;
  padding: 1.4375rem 0.9375rem 1.3125rem 1.8125rem;
}
.similiar-card__link:hover .similiar-card__title {
  color: var(--primary);
}
.similiar-card__img {
  position: relative;
  margin-right: 1rem;
  width: 16.1%;
  flex-shrink: 0;
}
.similiar-card__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.similiar-card__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 128.4%;
}
.similiar-card__info {
  display: flex;
  margin-bottom: 0.625rem;
}
.similiar-card__info .product-card__price {
  white-space: nowrap;
}
.similiar-card__title {
  margin-right: 0.4375rem;
  max-width: 11.375rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--black);
  transition: color 0.3s ease-in-out;
}
.similiar-card__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.similiar-card__content .product-card__price {
  margin-bottom: 1px;
}
.similiar-card__content .product-card__price--new {
  text-align: right;
  font-weight: 600;
  font-size: 1.125rem;
}
.similiar-card__content .product-card__price--old {
  top: -0.9375rem;
}
.similiar-card__content .product-card__package > * {
  font-weight: 300;
  font-size: max(0.75rem, 10px);
  color: var(--text-light);
}
.similiar-card__content .product-card__spec {
  display: flex;
  margin-top: auto;
  align-items: center;
  margin-right: 0;
}
.similiar-card__content .product-card__spec li:not(:last-child) {
  margin-right: 1.4375rem;
  margin-bottom: 0;
}
.similiar-card .product-card__buttons::after {
  top: -1.25rem;
  height: 20px;
}
@media (max-width: 1024px) {
  .similiar-card .product-card__spec li:not(:last-child) {
    margin-right: 0.75rem;
  }
}
@media (max-width: 768px) {
  .similiar-card .product-card__top {
    top: 15px;
  }
  .similiar-card__title {
    max-width: unset;
  }
  .similiar-card__link {
    padding: 11px 15px 11px 20px;
  }
  .similiar-card__img {
    width: 21.2%;
    max-height: 125px;
  }
  .similiar-card__content {
    width: 100%;
  }
  .similiar-card__content .product-card__spec {
    margin-top: unset;
    align-items: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .similiar-card__content .product-card__spec li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5px;
  }
  .similiar-card__content .product-card__content {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
}

.form {
  display: flex;
  flex-direction: column;
}
.form__error {
  display: none;
  font-size: 11px;
  color: #EB5757;
  line-height: 16px;
  margin-top: 14px;
}
.form.ajax-error .form__error {
  display: block;
}
.form__row {
  display: flex;
  margin: 0 -0.625rem;
}
.form__row:not(:last-child) {
  margin-bottom: 1.25rem;
}
.form__row--gap {
  margin-top: 2.8125rem;
}
.form__row + .form__error {
  margin-top: 0;
}
.form__input {
  margin: 0 0.625rem;
}
.form__input--2 {
  width: 31.4%;
}
.form__input--3 {
  width: 46.9%;
}
.form__input--4 {
  width: 62.8%;
}
.form__input--5 {
  width: 79.2%;
}
.form__input--6 {
  width: 100%;
}
.form__input--hidden {
  display: none;
}
.form__btn {
  margin-top: 2.0625rem;
}
.form__btn .btn {
  width: 100%;
}
.form__file {
  position: relative;
}
.form__file label {
  display: flex;
  justify-content: flex-end;
}
.form__file label span {
  display: inline-flex;
}
.form__success {
  display: none;
}
.form__success .cart__price--min {
  padding-top: 1.25rem;
}
.form__success .cart__price--min img {
  width: 1.5625rem;
  height: 1.875rem;
  margin-right: 0.6875rem;
}
@media (max-width: 768px) {
  .form__btn {
    text-align: center;
  }
  .form__btn > * {
    width: 100%;
    max-width: 400px;
  }
  .form__row {
    flex-direction: column;
    margin: 0;
  }
  .form__row:not(:last-child) {
    margin-bottom: 10px;
  }
  .form__row > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .form__input {
    margin: 0;
  }
  .form__input--2 {
    width: 50%;
  }
  .form__input--3 {
    width: 83.5%;
  }
  .form__input--4 {
    width: 83.5%;
  }
  .form__input--5 {
    width: 100%;
  }
}

.accordion {
  border-bottom: 4px solid var(--gray);
  padding-bottom: 1.5625rem;
}
.accordion:not(:last-child) {
  margin-bottom: 4.25rem;
}
.accordion__head {
  position: relative;
  cursor: pointer;
}
.accordion__img {
  position: absolute;
  left: -3.5625rem;
  top: 0;
  width: 2.4375rem;
  height: 2rem;
}
.accordion__img > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.accordion__btn {
  position: absolute;
  right: -8.125rem;
  top: 0.1875rem;
  font-weight: 600;
  font-size: max(0.9375rem, 10px);
  line-height: 1.125rem;
  color: var(--primary);
  cursor: pointer;
}
.accordion__btn img {
  margin-left: 0.4375rem;
  width: 0.625rem;
  height: 0.625rem;
  object-fit: contain;
  transition: transform 0.2s ease-in-out;
}
.accordion__name {
  display: flex;
  align-items: center;
  margin-bottom: 1.5625rem;
}
.accordion__name time {
  font-weight: 400;
  font-size: max(0.9375rem, 10px);
  line-height: 1.25rem;
  color: var(--text-light);
}
.accordion__title {
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.25rem;
  margin-right: 0.4375rem;
}
.accordion__tags {
  padding: 0.3125rem 0.625rem;
  color: var(--white);
  background-color: #56CCF2;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  margin-left: auto;
}
.accordion__tags--done {
  background-color: #27AE60;
}
.accordion__info {
  display: flex;
  align-items: center;
}
.accordion__info .total {
  margin-bottom: 0;
  margin-left: auto;
  font-weight: 400;
  font-size: max(0.9375rem, 10px);
  line-height: 1.25rem;
  white-space: nowrap;
}
.accordion__info .total span {
  font-size: 1.375rem;
  line-height: 1.375rem;
}
.accordion__text {
  font-weight: 400;
  font-size: max(0.9375rem, 10px);
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accordion__text:first-child {
  overflow: visible;
}
.accordion__text:not(:last-child) {
  margin-right: 0.9375rem;
}
.accordion__text .total {
  white-space: nowrap;
}
.accordion__body {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  box-sizing: content-box;
  display: none;
}
.accordion__bottom .total {
  justify-content: space-between;
  margin-bottom: 0;
  font-weight: 400;
  font-size: max(0.9375rem, 10px);
  line-height: 1.25rem;
}
.accordion__bottom .total span {
  font-size: 1.375rem;
  line-height: 1.375rem;
}
.accordion__delivery {
  border-top: 2px solid var(--gray);
  border-bottom: 2px solid var(--gray);
  padding-top: 0.9375rem;
  padding-bottom: 1.3125rem;
  margin-bottom: 1.4375rem;
}
.accordion__delivery-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.9375rem;
}
.accordion__delivery-price {
  font-weight: 600;
  font-size: max(1.125rem, 10px);
  line-height: 1.25rem;
}
.accordion__delivery address {
  font-weight: 400;
  font-size: max(0.8125rem, 10px);
  line-height: 1.375rem;
  color: var(--text-light);
}
.accordion__delivery .section-link {
  margin-top: 0.125rem;
  display: flex;
  align-items: center;
  color: var(--text-light);
  transition: color 0.2s ease-in-out;
  font-size: 13px;
  line-height: 18px;
}
.accordion__delivery .section-link span {
  font-weight: 500;
  font-size: max(0.8125rem, 10px);
  line-height: 1.125rem;
}
.accordion__delivery .section-link::before {
  content: "";
  margin-right: 0.375rem;
  width: 0.625rem;
  height: 0.875rem;
  background-image: url("../img/ico-sketch.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.accordion__delivery .section-link:hover {
  color: var(--primary);
}
.accordion.open .accordion__body {
  opacity: 1;
}
.accordion.open .accordion__btn img {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .accordion {
    padding-bottom: 20px;
  }
  .accordion__head {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .accordion__body {
    padding-top: 5px;
  }
  .accordion__img {
    position: static;
    margin-right: 15px;
  }
  .accordion__btn {
    position: static;
    margin-left: auto;
    width: 20px;
    height: 16px;
  }
  .accordion__btn img {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .accordion__title {
    margin-right: 0;
    margin-bottom: 6px;
  }
  .accordion__name {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .accordion__name time {
    margin-bottom: 8px;
  }
  .accordion__tags {
    margin-left: unset;
    margin-right: unset;
    font-size: 15px;
    padding: 3px 15px;
  }
  .accordion__text {
    font-size: 14px;
  }
  .accordion__info {
    flex-wrap: wrap;
    padding-left: 55px;
  }
  .accordion__info .accordion__text {
    margin-bottom: 3px;
  }
  .accordion__info .total {
    margin-left: unset;
    margin-top: 12px;
    justify-content: space-between;
    width: 100%;
  }
  .accordion__info .total span {
    font-size: 22px;
    line-height: 22px;
  }
  .accordion__info.info-open {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 0;
  }
  .accordion__info.info-open .accordion__text {
    margin-bottom: 0;
  }
  .accordion__info.info-open .total {
    margin-top: 0;
    width: unset;
  }
  .accordion__info.info-open .total span {
    margin-left: 0;
  }
  .accordion__delivery {
    margin-bottom: 20px;
  }
  .accordion__delivery > .accordion__text {
    display: inline-block;
    width: 66.6%;
    margin-right: 0;
  }
  .accordion__delivery-street {
    width: 66.6%;
  }
  .accordion__delivery-price {
    font-size: 16px;
    line-height: 17px;
  }
  .accordion__delivery-row {
    margin-bottom: 15px;
  }
  .accordion__delivery .section-link span {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .accordion__delivery address {
    font-size: 12px;
  }
}