.about-content{
    padding-top: rem(100);
    padding-bottom: rem(110);
    &__videos{
        display: flex;
        align-items: flex-end;
        margin: rem(70) 0 rem(100);
        &:not(:first-child){
            margin-top: rem(70);
        }
        &:not(:last-child){
            margin-bottom: rem(100);
        }
    }
    &__video{
        position: relative;
        width: 53.6%;
        flex-shrink: 0;
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 64.5%;
        }
        >*{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__text{
        margin-left: rem(50);
        max-width: rem(400);
    }


    @media(max-width: 768px){
        padding: 50px 0;
        &__videos{
            flex-direction: column;
            &:not(:first-child){
                margin-top: 0;
            }
            &:not(:last-child){
                margin-bottom: rem(50);
            }
        }
        &__video{
            width: calc(100% + 40px);
            margin: 0 -20px;
            margin-bottom: 17px;
        }
        &__text{
            margin-left: 0;
            max-width: unset;
        }
    }
}