.menu{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    height: 100%;
    background-color: var(--white);
    transform: translateX(-100%);
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    &.is-active{
        transform: translateX(0);
    }
    &__inner{
        padding: 90px 20px 35px;
        display: flex;
        flex-direction: column;
        .header__btn img, .header__btn svg {
            width: 35px;
            height: 35px;
        }
    }
    &__btns{
        margin-bottom: 40px;
        .header__btn{
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: var(--black);
            padding-left: 8px;
        }
        .header__btn svg:not(:last-child){
            margin-right: 6px;
        }
    }
    &__nav{
        padding-top: 30px;
        border-top: 4px solid var(--gray);
        &:not(:last-child){
            padding-bottom: 40px;
        }
        ul{
            flex-direction: column;
            li{
                margin: 0;
                padding: 0;
                padding-left: 17px;
                a{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    color: var(--black);
                    span{
                        margin-left: 37px;
                    }
                }
                &:not(:last-child){
                    margin-bottom: 30px;
                }
            }
            ul {
                margin-top: 23px;
                margin-left: 23px;
            }
        }
        .header__btn svg:not(:last-child){
            margin-right: -22px;
        }
        &--small{
            ul{
                li{
                    &:first-child{
                        margin-bottom: 20px;
                    }
                    &.is-small{
                        a{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                        }
                        &:not(:last-child){
                            margin-bottom: 14px;
                        }
                    }
                    &.is-active{
                        a{
                            pointer-events: none;
                            position: relative;
                            padding-right: 15px;
                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                top: 52%;
                                transform: translateY(-50%);
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background: var(--primary);
                            }

                        }
                    }
                }
            }
        }
    }





    .header__menu-inner {
        padding: 10px 0 0;
        display: none;
    }

    .header__menu-row > * {
        width: 37%;
    }

    .header__menu-item__img::before {
        padding-top: 171%;
    }

    .header__menu-item__img {
        margin-bottom: 10px;
    }

    .header__menu-row {
        margin: 0;
        justify-content: space-around;
        &:not(:last-child){
            margin-bottom: 20px;
        }
        .header__menu-item::before {
            bottom: -32px;
        }
    }

    .header__menu-row .header__menu-item {
        padding-bottom: 0;
    }

    .header__menu-sub {
        display: none;
        padding: 55px 0 0;
        justify-content: space-around;
        .header__menu-item {
            width: 44%;
            margin: 0;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            &:last-child:nth-child(odd){
                margin-right: auto;
            }
        }
        .header__menu-item__img {
            width: 50%;
            flex-shrink: 0;
            margin-bottom: 0;
            &::before {
                padding-top: 80%;
            }
        }
        .header__menu-item__title{
            text-align: left;
        }
    }

    .header__menu-subs > * {
        padding-top: 55px;
    }

    .header__menu-item {
        max-width: 215px;
    }

    .header__menu-row > * {
        flex-grow: unset;
        flex-basis: unset;
        margin: 0;
    }


    .header__menu-use {
        padding-top: 50px;
        border-top: none;
        flex-direction: column;

        .section-arrow {
            margin-top: 30px;
            margin-left: 40px;
            img{
                transition: transform .3s ease-in-out;
            }
            &.is-open{
                .open-text{
                    display: none;
                }
                img{
                    transform: rotate(180deg);
                }
            }
            &:not(.is-open){
                .close-text{
                    display: none;
                }
            }
        }
    }

    .header__menu-content {
        width: 100%;
        margin-right: 0;
        padding-left: 40px;
    }

    .header__menu-list {
        display: none;
        padding-top: 30px;
        padding-left: 20px;
    }




    .menu__nav ul li a {
        padding: 0;
        display: flex;
        align-items: center;
        width: max-content;
        max-width: 100%;
    }
    .menu__inner .header__btn img, .menu__inner .header__btn svg{
        width: 25px;
        height: 25px;
    }
    .menu__nav .header__btn svg:not(:last-child) {
        margin-right: 11.5px;
    }


    @media (max-width: 768px) {
        .header__menu-item__img::before {
            padding-top: 91%;
        }

        .header__menu-sub {
            justify-content: space-between;
        }
        .header__menu-subs > * {
            padding-top: 40px;
        }

        .header__menu-sub .header__menu-item {
            width: calc(50% - 17px);
            margin: 0 8.5px;
            align-items: flex-start;
        }

        .header__menu-sub .header__menu-item__img {
            width: calc(50% - 10px);
            margin-right: 10px;
        }

        .header__menu-sub .header__menu-item__title {
            display: flex;
            align-items: center;
            min-height: 30px;
        }

    }
}

