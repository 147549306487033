.product-destination{
    padding-bottom: rem(175);
    margin-top: rem(110);
    &__info{
        margin-bottom: rem(45);
        .section-text >*{
            color: var(--black);
        }
    }
    &__title{
        margin-bottom: rem(30);
    }
    .products{
        padding-bottom: 0;
    }

    @media(max-width: 768px){
        margin-top: 70px;
        padding-bottom: 130px;
        &__title{
            margin-bottom: 20px;
        }
        &__info{
            margin-bottom: 30px;
        }
    }
    
}