.catalogue-product{
    padding-top: rem(75);
    padding-bottom: rem(65);
    &__container{
        display: flex;
        justify-content: space-between;
    }
    &__content{
        max-width: rem(660);
    }
    &__title{
        margin-bottom: rem(30);
    }
    &__text >*{
        font-size: rem(20) !important;
        font-weight: 500 !important;
        line-height: rem(35) !important;
    }
    &__link{
        max-width: rem(350);
        .btn{
            margin-top: rem(17);
            width: 100%;
        }
    }

    @media(max-width: 768px){
        padding-top: 30px;
        padding-bottom: 70px;
        &__container{
            flex-direction: column;
        }
        &__content{
            max-width: unset;
        }
        &__text >*{
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 26px !important;
        }
        &__link{
            margin-top: 20px;
            max-width: unset;
            a{
                max-width: 233px;
                padding: 9px 14px;
                font-weight: 800;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}