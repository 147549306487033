

@function rem($size) {
  $remSize: $size / $base-value;

  @return #{$remSize}rem;
}


// $header-height: 93px;
$base-value: 16;



html {
  box-sizing: border-box;
  // scroll-padding-top: $header-height;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  padding: 0 70px;

  &.container{
    &--small{
      padding-left: rem(140);
    }

   &--big{
      max-width: 100%;
      padding: 0 rem(40);
    }

    &--full{
      max-width: rem(2500);
      padding: 0 rem(20);
    }
  }

  @media(max-width: 768px){
    padding: 0 20px;
    &.container{
      &--small{
        padding-left: 20px;
      }
     &--big{
        padding: 0 20px;
      }

    //   &--full{
    //     max-width: 100%;
    //     padding: 0 rem(20);
    //   }
    }
  }
}

.status{
  &-available{
    color: #27AE60;
    .product__status{
      color: #27AE60;
    }
  }
  &-not-available{
    color: #EB5757;
    .product__status{
      color: #EB5757;
    }
  }
  &-wait{
    color: #F2994A;
    .product__status{
      color: #F2994A;
    }
  }
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: Max(rem(16), 12px);
  font-family: 'Inter' , sans-serif;
  font-weight: 400;
  line-height: rem(28);
  color: var(--black);
  &.menu-open{
    overflow: hidden;
    .header{
      background-color: #fff;
    }
  }
}

.title-big >*{
  font-family: 'Inter';
  font-size: rem(50);
  line-height: rem(55);
  font-weight: 600;
  color: var(--black);

}

.title-h1 >*{
  font-family: 'Inter';
  font-size: rem(45);
  line-height: rem(60);
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--black);
}

.title-h2 >*{
  font-family: 'Inter';
  font-size: rem(35);
  line-height: rem(45);
  font-weight: 600;
  color: var(--black);
}

.title-h3 >*{
  font-family: 'Inter';
  font-size: rem(30);
  line-height: rem(35);
  font-weight: 700;
  color: var(--black);
}

.title-h4 >*{
  font-family: 'Inter';
  font-size: rem(22);
  line-height: rem(30);
  font-weight: 700;
  color: var(--black);
}

.title-h5 >*{
  font-family: 'Inter';
  font-size: rem(20);
  line-height: rem(25);
  font-weight: 700;
  color: var(--black);
}

.title-h6 >*{
  font-family: 'Inter';
  font-size: Max(rem(15), 12px);
  line-height: rem(20);
  font-weight: 500;
}


.btn{
  display: inline-flex;
  outline: 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: rem(14.5) rem(27.5) rem(16);
  font-weight: 800;
  font-size: Max(rem(20), 12px);
  line-height: rem(20);
  color: var(--white);
  background-color: var(--primary);
  border-radius: rem(20);
  border: 1px solid var(--primary);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &--medium{
    padding: rem(12) rem(20);
    font-size: rem(18);
    line-height: rem(20);
  }
  &--small{
    padding: rem(9) rem(15);
    font-size: Max(rem(16), 10px);
    border-radius: rem(14);
  }
  &--smallest {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 2px 12px;
    border-radius: 16px;
    border: 1px solid var(--v1color, #FEB11C);
  }
  &--white{
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(--black);
    &:not(span):hover{
      background-color: var(--primary) !important;
      color: var(--white) !important;
    }
  }
  &--gray{
    background-color: var(--text-white);
    border-color: var(--text-white);
    color: var(--black);
    &:not(span):hover{
      background-color: var(--primary) !important;
      color: var(--white) !important;
    }
  }
  &.disabled,
  &.disabled:hover,
  &[disabled],
  &[disabled]:hover{
    background: var(--text-white);
    border-color: var(--text-white);
    cursor: not-allowed;
    color: #fff;
  }
  &:not(span):hover{
    color: var(--black);
    background-color: transparent;
  }

}

.socials{
  display: flex;
  li{
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child){
      margin-right: rem(10);
    }
    a{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: rem(32);
      height: rem(32);
      svg path{
        transition: fill 0.2s ease-in-out;
      }
      >*{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover{
        svg path{
          fill: var(--primary);
        }
      }
    }
  }

  @media(max-width: 768px){
    flex-wrap: wrap;
    margin: 0 -5px -10px !important;
    li{
      margin: 0 5px 10px !important;
    }
  }
}

.arrow-back{
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: rem(32);
  width: max-content;
  max-width: 100%;
  &__img{
    width: rem(54);
    height: rem(22);
    margin-right: rem(20);
    transition: transform 0.2s ease-in-out;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.2s ease-in-out;
    }
  }
  span{
    font-weight: 500;
    line-height: rem(28);
    text-decoration-color: var(--primary) !important;
    text-underline-offset: rem(9);
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    &:not(:hover){
      color: var(--black);
    }
  }
  @media(min-width: 769px){
    &:hover{
      span{
        color: var(--primary);
      }
      img{
        transform: translateX(-19%);
      }
    }
  }

  @media(max-width: 768px){
    &__img{
      margin-right: 13px;
      width: 51px;
    }
    span{
      font-size: 14px;
      line-height: 30px;
    }
  }

}

input[type='radio']{
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.input{
  position: relative;
  input,
  textarea,
  &.input--file,
  .zen-ui-select__value{
    border: 1px solid var(--text-white);
    border-radius: rem(18);
    font-weight: 500;
    font-size: Max(rem(16), 12px);
    line-height: rem(27);
    width: 100%;
    height: 100%;
    outline: none;
    padding: rem(9) rem(15);
    color: var(--black);
    background: var(--white);
    &::placeholder{
      opacity: 1 !important;
      color: var(--black) !important;
    }
  }
  // input[type='number'] {
  //   -moz-appearance: textfield;
  // }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  input[type='file']{
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  textarea {
    resize:none;
    height: rem(112);
    &:focus{
        outline: none;
    }
  }
  .select2{
    display: none;
  }
  &.input--file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(4) rem(4) rem(4) rem(15);
    label{
      flex-shrink: 0;
      margin-left: rem(19);
    }
  }
  &.input--radiocheck {
    border: none;
    input {
      position: absolute;
      left: -9999px;
      &:checked+label::after{
        opacity: 1;
      }
    }
    label {
      padding-left: rem(43);
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(28);
      min-height: rem(30);
      display: flex;
      align-items: center;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: rem(30);
        height: rem(30);
        border-radius: 50%;
        border: 1px solid var(--black);
      }
      &::after{
        content: '';
        position: absolute;
        left: rem(7);
        top: rem(7);
        width: rem(16);
        height: rem(16);
        border-radius: 50%;
        background: var(--primary);
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
    }

  }
  &--radio{
    position: relative;
    display: block;
    color: var(--black);
    &__btn{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid transparent;
      outline: 1px solid var(--black);
      border-radius: rem(40);
      font-weight: 600;
      font-size: rem(20);
      line-height: rem(20);
      padding: rem(7.4) rem(14.9) rem(7) rem(14);
      transition: outline-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    }
    &__check{
      position: relative;
      padding-left: rem(51);
      &::after{
          content: "";
          position: absolute;
          width: rem(30);
          height: rem(30);
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border: 1px solid var(--black);
          border-radius: rem(18);
      }
      &::before{
          content: "";
          position: absolute;
          left: rem(7);
          top: 50%;
          transform: translateY(-50%);
          width: rem(16);
          height: rem(16);
          background-color: var(--primary);
          border-radius: 50%;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
      }
    }
    input:checked + .input--radio__check::before{
      opacity: 1;
    }
    input:checked + *, .input--radio__btn:hover{
      border-color:  var(--primary);
      outline-color: var(--primary);
    }
  }
  &--checkbox {
    position: relative;
    display: block;
    cursor: pointer;
    input {
      position: absolute;
      left: -9999px;
      &:checked + span::before {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }
    > span {
      position: relative;
      padding-left: rem(35);
      display: flex;
      align-items: center;
      min-height: rem(25);
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(18);
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: rem(25);
        height: rem(25);
        border: 1px solid var(--black);
        background-image: url('data:image/svg+xml,%3Csvg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6 10.9091L11.4737 16L19 9" stroke="white" stroke-width="3"/%3E%3C/svg%3E ');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
      }
    }
  }


  &--select{
    position: relative;
    display: flex;
    align-items: center;

    &.is-open{
      input,
      textarea,
      .output_text{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .input__arrow{
        transform: rotate(-180deg);
      }
    }
  }


  .woocommerce-input-wrapper input{
    height: rem(46);
  }
  .zen-ui-select__options {
    max-height: unset;
    padding: 0;
    border: none;
  }
  .zen-ui-select__value.disabled {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .zen-ui-select__dropdown,
  &__dropdown{
    display: none;
    position: absolute;
    transform: translateY(100%);
    text-align: left;
    max-height: rem(200);
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 1px 23px rgba(30, 33, 36, 0.13);
    left: 0;
    bottom: 1px;
    border: 1px solid var(--text-white);
    right: 0;
    top: unset;
    &.active{
      display: block;
    }
    .group-title {
      pointer-events: none;
      color: #828282;
    }
    .group-item {
      padding-left: rem(30);
    }
    .zen-ui-select__option,
    li{
      cursor: pointer;
      padding: rem(7) rem(10);
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      &.is-selected{
        pointer-events: none;
        color: var(--primary);
      }
      &:not(:last-child){
        border-bottom: 1px solid (var(--text-white));
      }
      &:hover{
        background-color: var(--primary);
        color: var(--white);
      }
    }

    .zen-ui-select__option--current {
      background: var(--primary) !important;
      color: var(--white) !important;
    }

  }
  .zen-ui-select__option::first-letter,
  .zen-ui-select__value-text::first-letter {
    text-transform: uppercase;
  }

  &__arrow{
    position: absolute;
    right: rem(11);
    transition: transform 0.2s ease-in-out;
    pointer-events: none;
  }
  &--required{
    position: relative;
    &::after{
      content: "*";
      position: absolute;
      right: rem(-11);
      top: 0;
      width: rem(11);
      height: rem(15);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #EB5757;
    }
  }
  .woocommerce-input-wrapper.is-error,
  &.is-error{
    margin-bottom: rem(7);
    display: block;
    position: relative;
    input,
    textarea,
    .output_text,
    .zen-ui-select__value{
      border: 1px solid #EB5757;
    }
    input::placeholder{
      color: #EB5757 !important;
    }
    .input__error{
      display: block;
    }
  }
  &__error {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(100%);
    font-size: 11px;
    bottom: -3px;
    line-height: 11px;
    color: #EB5757;
    display: none;
    z-index: -2;
  }


  @media(max-width: 768px){
    &--select{
      width: 100%;
      &:not(:last-child){
        margin-bottom: 10px;
      }
      &.is-open{
        &:first-child{
          border-bottom-right-radius: 0;
        }
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

}

.woocommerce-order.register .success-message {
  display: block;
  .section-link {
    display: inline-flex;
    img, svg {
      width: auto;
      max-width: unset;
      object-fit: contain;
      margin-right: 10px;
    }
  }

}

.hover-stroke{
  transition: stroke .3s ease-in-out;
}
.hover-fill{
  transition: fill .3s ease-in-out;
}
.mobile-hide{
  @media (max-width: 768px){
    display: none !important;
  }
}
.mobile-show{
  @media (min-width: 769px){
    display: none !important;
  }
}

@media(max-width: 768px){
   .title-big >*{
     font-weight: 600;
     font-size: 32px;
     line-height: 42px;
     letter-spacing: -0.02em;
   }

  .title-h1 >*{
    font-size: 32px;
    line-height: 42px;
  }

  .title-h2 >*{
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.02em;
  }

  .title-h3 >*{
    font-weight: 600;
    font-size: 19px;
    line-height: 25px;
  }

  // .title-h4 >*{
  //   font-family: 'Inter';
  //   font-size: rem(22);
  //   line-height: rem(30);
  //   font-weight: 700;
  //   color: var(--black);
  // }

  .title-h5 >*{
    font-size: 19px;
    font-weight: 600;
  }

  // .title-h6 >*{
  //   font-family: 'Inter';
  //   font-size: rem(15);
  //   line-height: rem(20);
  //   font-weight: 500;
  // }
}

.woocommerce-error,
.woocommerce-message,
.woocommerce-notices-wrapper,
.woocommerce>.woocommerce-form-coupon-toggle,
.woocommerce-additional-fields>h3{
  display: none!important;
}

[data-show-mini-cart]{
  position: relative;
}
.mini-cart {
  display: none;
  position: absolute;
  bottom: -10px;
  right: -5px;
  z-index: 10001;
  background: #FFF;
  box-shadow: 0px rem(8) rem(50) 0px rgba(19, 20, 21, 0.07);
  padding: rem(18) rem(25) rem(36);
  width: rem(370);
  max-width: calc(100vw - 30px);
  transform: translateY(100%);
  cursor: default;
  &.cart-empty{
    .mini-cart__btn,
    .mini-cart__total{
      display: none;
    }
  }
  &.mini-cart--wholesale{
    .mini-cart__total>*{
      display: none;
    }
  }
  &:not(.cart-empty){
    .mini-cart__list-empty{
      display: none;
    }
    .mini-cart__list-not-empty{
      display: block;
    }
  }
  &__list {
    overflow-y: auto;
    max-height: rem(200);
    padding-right: rem(20);
    margin-right: rem(-20);
    line-height: 0;
    scrollbar-color: var(--primary) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 0;
    }
    &-empty{
      font-size: max(1rem, 12px);
      font-weight: 400;
      line-height: 1.75rem;
      color: var(--black);
      text-align: left;
      display: block;
    }
    &-not-empty{
      display: none;
      text-align: left;
      font-size: rem(18);
      font-weight: normal;
      color: var(--black);
    }
  }
  &__title {
    color: var(--black);
    text-align: left;
    margin-bottom: rem(28);
  }
  &__total {
    margin-top: rem(17);
    padding-top: rem(20);
    border-top: rem(4) solid #F8F8F9;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: var(--black);
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: rem(20);
  }
  &__btn {
    margin-top: rem(20);
    .btn {
      width: 100%;
    }
  }

  .cart-item {
    &:not(:last-child) {
      margin-bottom: rem(15);
    }
    &.cart-item--smallest {
      padding: 0;
      .cart-item__total {
        padding: 0;
        text-align: left;
      }
    }
    &__title {
      line-height: rem(18);
    }
  }
  .product-card__price--new {
    font-size: rem(25);
    line-height: rem(25);
  }
}

.section-title{
  display: inline-flex;
  align-items: center;
  &::after{
    content: '';
    display: block;
    width: rem(346);
    height: rem(4);
    background-color: var(--primary);
  }
  &__img{
    width: rem(54);
    height: rem(54);
    background-color: var(--primary);
    border-radius: rem(30);
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
    >*{
      width: rem(20);
      height: rem(20);
      object-fit: contain;
    }
  }
}

.burger{
  width: 20px;
  height: 18px;
  position: relative;
  margin-right: 10px;
  >*{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease-in-out;
  }
  >*:last-child{
    opacity: 0;
  }
  &.is-active{
    >*:first-child{
      opacity: 0;
    }
    >*:last-child{
      opacity: 1;
    }
  }
}

.breadcrumbs{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  li{
    font-weight: 400;
    font-size: rem(45);
    line-height: rem(55);
    color: var(--black);
    &:not(:last-child){
      margin-right: rem(6);
      padding-right: rem(35);
      position: relative;
      &::after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: rem(16);
        height: rem(16);
        background-image: url(./../img/ico-breadcrumbs.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    a{
      color: var(--black);
      font-weight: 600;
      line-height: rem(60);
      letter-spacing: -0.02em;
      transition: color 0.3s ease-in-out;
      &:hover{
        color: var(--primary);
      }
    }
  }
  @media(max-width: 768px){
    li{
        font-size: 32px;
        line-height: 45px;
    }
  }
}

.tag{
  font-weight: 700;
  font-size: Max(rem(18), 10px);
  line-height: rem(22);
  text-transform: uppercase;
  color: var(--white);
  padding: rem(4) rem(12) rem(4) rem(18);
  background-color: var(--red);
  border-top-right-radius: rem(16);
  pointer-events: none;
  &:not(:last-child){
    margin-bottom: rem(5);
  }
  &--new{
    background-color: #29AF08;
  }
}

.quantity{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__btn{
    width: rem(19);
    height: rem(40);
    display: flex;
    align-items: center;
    >*{
      width: rem(14);
      height: rem(14);
      object-fit: contain;
    }
    &:last-child{
      justify-content: flex-end;
    }
  }
  &__input{
    width: rem(35);
    flex-shrink: 0;
    min-height: rem(40);
    min-width: rem(35);
    font-weight: 600;
    border-radius: 0;
    font-size: rem(25);
    line-height: rem(40);
    text-align: center;
    color: var(--black);
  }
}

.total{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: rem(22);
  font-weight: 400;
  font-size: rem(20);
  line-height: rem(20);
  text-align: right;
  color: var(--black);
  span{
    margin-left: rem(11);
    font-weight: 700;
    font-size: rem(30);
    line-height: rem(55);
  }
  @media(max-width: 768px){
    font-size: 14px;
    line-height: 26px;
    span{
      font-size: 25px;
      line-height: 40px;
    }
  }
}

.dropdown{
  position: relative;
  padding: rem(7) rem(15) rem(8);
  margin-right: 0 !important;
  transition: background-color .3s ease-in-out;
  cursor: pointer;
  @media (min-width: 769px) {
      &:not(.dropdown-click):hover {
        background-color: rgba(42, 46, 50, 0.05);
        ul {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  ul {
    position: absolute;
    z-index: 99;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    text-align: right;
    width: max-content;
    background: #FFFFFF;
    box-shadow: 0px 1px 23px rgba(30, 33, 36, 0.13);
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    &.active{
      z-index: 1000;
      opacity: 1 !important;
      visibility: visible !important;
    }
    a{
      display: block;
      padding: rem(7) rem(16) rem(8);
      font-weight: 400;
      font-size: Max(rem(14), 10px);
      line-height: rem(20);
      color: var(--black);
      transition: background-color .3s ease-in-out;
      @media (min-width: 769px) {
        &:hover {
            background-color: rgba(42, 46, 50, 0.05);
        }
      }
      &.is-active{
        background-color: var(--primary);
        color: #fff;
      }
    }

    .separated {
      margin-top: 10px;
      padding-top: 2px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 1rem;
        height: 2px;
        width: 106px;
        max-width: 100%;
        background: #F4F4F4;
    }
    }
  }
}

.fancybox__slide {
  padding: 20px;
}

.popup{
  width: rem(495);
  max-width: 100%;
  background: #FFFFFF;
  box-shadow: 0 8px 50px rgba(19, 20, 21, 0.15);
  padding: rem(56) rem(30) rem(62);
  .form {
    margin: rem(16) auto 0;
    max-width: rem(300);
    &__row:not(:last-child) {
      margin-bottom: 12px;
    }
    &__input {
      width: calc(100% + 0.625rem);
    }
  }
  .f-button {
    top: rem(15)!important;
    right: rem(15)!important;
    width: rem(22);
    height: rem(22);
    border: none !important;
    outline: none !important;
    background: transparent!important;
    --f-button-bg: transparent!important;
    svg {
      width: rem(22);
      height: rem(22);
      stroke: #828282;
    }
  }
  &__title {
    margin-bottom: rem(30);
  }

  &.popup--centered{
    text-align: center;
    .input {
      input {
        text-align: center;
      }
      &__error{
        text-align: center;
      }
    }
  }
  &:not(.popup--centered){
    width: rem(640);

    .form {
      max-width: 100%;
      margin-left: 0;
      &__input:not(.form__textarea) {
        max-width: 310px;
      }
    }
    .popup__inner{
      max-width: 420px;
      margin: 0 auto;
      width: 100%;
    }

  }

  @media (max-width: 768px) {
    .popup {
      padding: 56px 20px 28px;
      &__title {
        margin-bottom: 20px;
      }
      &__inner .success {
        margin-top: -20px;
        margin-bottom: -20px;
      }
    }
  }
}

.dropdown-click{
  display: inline-flex;
  align-items: center;
  padding: 0;
  img{
    flex-shrink: 0;
    &:first-child{
      width: rem(15);
      height: rem(10);
      object-fit: contain;
      margin-right: rem(7);
    }
    &:last-child{
      width: rem(12);
      height: rem(12);
      object-fit: contain;
      margin-left: rem(7);
    }
  }
  ul{
    bottom: rem(-4);
  }
  span{
    font-weight: 400;
    font-size: Max(rem(14), 12px);
    line-height: rem(20);
  }

}

.pagination{
  display: flex;
  align-items: center;
  >*{
    display: block;
  }
  .page-numbers{
    font-weight: 600;
    font-size: Max(rem(16), 10px);
    line-height: rem(25);
    color: var(--black);
    transition: color 0.3s ease-in-out;
    &:hover{
      color: var(--primary);
    }
    &:not(:last-child){
      margin-right: rem(39);
    }
    &:last-child{
      position: relative;
      margin-left: rem(186);
      &::before{
        content: '';
        position: absolute;
        left:  rem(-186);
        top: 50%;
        transform: translateY(-50%);
        width: rem(146);
        height: rem(4);
        background-color: var(--gray);
        pointer-events: none;
      }
    }
    &.current{
      padding: rem(4.3) rem(12.2);
      background-color: var(--primary);
      color: var(--white);
      margin-right: rem(27);
    }
    &.next, .prev{
      display: none;
    }
  }
  @media(max-width: 768px){
    .page-numbers{
      &:not(:last-child){
        margin-right: 30px;
      }
      &:last-child{
        flex-grow: 1;
        margin-left: 0;
        text-align: right;
        display: flex;
        align-items: center;
        &::before{
          position: static;
          transform: none;
          width: auto;
          flex-grow: 1;
          margin-right: 16px;
        }
      }
      &.current{
        margin-right: 18px;
      }
    }
  }
}


.fancybox__container{
  --fancybox-bg: rgba(238, 238, 238, 0.8);
}
.fancybox__backdrop{
  backdrop-filter: blur(5px);
}
.fancybox__container {
  z-index: 10001;
}

.popup-search{
  height: 46px;
  width: calc(100% - 40px);
  padding: 0;
  background: transparent;
  position: absolute;
  top: 11px;
  left: 20px;
  right: 20px;
  // z-index: 10001;
  &__inner{
    padding: 0;;
    height: 100%;
  }
  &__content{
    height: 100%;
    .header__search-form{
      position: relative;
      // width: 100%;
      right: 0;
      &.is-open{
        width: 100%;
      }
      input{
        width: 100%;
      }
    }
  }
  .is-close-btn{
    display: none;
  }



}

/* Елемент | http://localhost:3000/favourites.html */

// a.page-numbers:nth-child(5) {
//   flex-grow: 1;
// }

/* main.css | http://localhost:3000/css/main.css?browsersync=1685206053940 */

// .pagination .page-numbers:last-child {
//   /* margin-left: 11.625rem; */
//   margin-left: 0;
//   text-align: right;
//   display: flex;
//   align-items: center;
// }

// .pagination .page-numbers:last-child::before {
//   /* transform: translateY(-50%); */
//   /* width: 9.125rem; */
//   transform: none;
//   width: auto;
//   flex-grow: 1;
//   margin-right: 16px;
// }



// /* main.css | http://localhost:3000/css/main.css */

// .popup-search {
//   /* width: 100%; */

// }

// .popup-search__inner {
//   /* padding: 11px 20px; */
//   padding: 0;
// }

// .header__search-form.is-open {
//   /* width: 31.4375rem; */
//   width: 100%;
// }

// .popup-search__content .header__search-form {
//   right: 0;
// }

// /* fancybox.css | https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/fancybox/fancybox.css */




.swiper-nav-pagination {
  position: absolute;
  left: 0;
  bottom: rem(66);
  display: flex;
  align-items: center;
  max-width: rem(450);
  width: 100%;
  justify-content: space-between;
}
.swiper-button-next, .swiper-button-prev {
  width: rem(25);
  height: rem(25);
  margin-top: 0;
  line-height: 0;
  position: static;
  flex-shrink: 0;
  outline: none!important;
  border: none!important;
}
.swiper-pagination{
  position: relative;
  margin: 0 rem(20);
  bottom: 0 !important;
  display: flex;
  width: 100%;
  height: rem(4)!important;
  &-bullet {
    margin: 0 rem(5);
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: rem(14);
    background: var(--text-white);
    opacity: 1;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      background-color: var(--primary);
      width: 0;
      z-index: 2;
      border-radius: inherit;
    }
  }
  &.can-play{
    .swiper-pagination-bullet-active {
      transition-duration: 3000ms;
      pointer-events: none;
      &::before {
        transition: width 3000ms linear!important;
        transition-duration: inherit!important;
        width: 100%;
      }
    }
  }
}
.swiper-button-prev::after, .swiper-rtl .swiper-button-next::after,
.swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
  content: none;
}
.swiper-nav {
  path{
    transition: stroke .3s ease-in-out;
  }
  &:hover{
    path{
      stroke: var(--primary);
    }
  }
  & > * {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.image-parallax{
  overflow: hidden;
}

.title-link {
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: rem(4);
    background: var(--primary);
    border-radius: rem(12);
  }
  &__ico{
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(54);
    height: rem(54);
    flex-shrink: 0;
    background: #FEB11C;
    border: 1px solid rgba(251, 179, 95, 0.5);
    border-radius: 50%;
    margin-left: rem(-54);
    &>*{
      width: 45%;
      height: 45%;
      object-fit: contain;
    }
  }
}
.woocommerce form .form-row-last, .woocommerce-page form .form-row-last,
.woocommerce form .form-row-first, .woocommerce-page form .form-row-first{
  float: unset!important;
}
.woocommerce form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-first, .woocommerce-page form .form-row-last{
  width: 100%!important;
}
#add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment{
  background: transparent;
}
#add_payment_method #payment div.payment_box>*:not(:last-child),
.woocommerce-cart #payment div.payment_box>*:not(:last-child),
.woocommerce-checkout #payment div.payment_box>*:not(:last-child) {
  margin-bottom: rem(20);
}
#add_payment_method #payment div.payment_box, .woocommerce-cart #payment div.payment_box, .woocommerce-checkout #payment div.payment_box {
  padding: 5px 0 5px rem(43);
  margin: 0;
  background-color: transparent;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #828282;
}
#add_payment_method #payment div.payment_box::before, .woocommerce-cart #payment div.payment_box::before, .woocommerce-checkout #payment div.payment_box::before {
  content: none;
}


@media (max-width: 1280px){
  html{
    font-size: 14px;
  }
}
@media (max-width: 1080px){
  html{
    font-size: 13px;
  }
}
@media (max-width: 991px){
  html{
    font-size: 10px;
  }
}
@media (max-width: 768px){
  html{
    font-size: 16px;
  }
}













*,
*::before,
*::after {
  box-sizing: inherit;
}

img, svg {
	max-width: 100%;
	height: auto;
  object-fit: cover;
}


/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}



/* Links */

a, a:link, a:visited  {
    // color: inherit;
    text-decoration: none;
    // display: inline-block;
}


a:hover  {
    color: inherit;
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
	font-weight: inherit;
}

address {
  font-style: normal;
}

ul {
    list-style: none;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}





// @media(max-width: 768px){
//   .page__body{
//     font-size: 16px ;
//   }
//   .main__title >*{
//     font-size: 40px;
//     line-height: 52px;
//   }
//   .title-1 >*{
//     font-size: 35px;
//     line-height: 47px;
//   }
//   .title-2 >*{
//     font-size: 30px;
//     line-height: 40px;
//   }
//   .title-3 >*{
//     font-size: 26px;
//     line-height: 38px;
//   }
//   .title-4 >*{
//     font-size: 22px;
//     line-height: 34px;
//   }

// }
