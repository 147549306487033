.for-success{
    position: relative;
    padding-top: rem(107);
    padding-bottom: rem(80);
    margin-bottom: rem(100);
    overflow: hidden;
    &__img{
        position: absolute;
        right: 0;
        top: 0;
        line-height: 0;
        width: 41.6%;
        bottom: 0;
        z-index: -1;
        >*{
            width: auto;
            max-width: unset;
            height: 100%;
            max-height: rem(740);
        }
    }
    &__container{
        width: 46.5vw;
    }
    &__title{
        margin-bottom: rem(25);
    }
    &__benefits{
        margin-top: rem(45);
        columns: 2;
        column-gap: rem(87);
        max-width: rem(832);
    }
    &__content {
        break-inside: avoid;
        &:not(:last-child){
            margin-bottom: rem(37);
        }
        .section-text--small{
            max-width: rem(243);
        }
    }
    &__subtitle{
        margin-bottom: rem(12);
    }


    @media(max-width: 768px){
        margin-bottom: 0;
        padding: 70px 0;
        &__container{
            width: unset;
        }
        &__content{
            &:not(:last-child){
                margin-bottom: 30px;
            }
        }
        &__img{
            z-index: -1;
            opacity: 0.2;
            width: 79.6%;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__content .section-text--small{
            max-width: unset;

        }
        &__benefits{
            max-width: 277px;
            margin-top: 50px;
            columns: 1;
        }
    }
}


// @media(max-width: 768px){
//     .foropt-success {
//         padding: 70px 0;
//     }
// }

  
  