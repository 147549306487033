.accordion{
    border-bottom: 4px solid var(--gray);
    padding-bottom: rem(25);
    &:not(:last-child){
        margin-bottom: rem(68);
    }
    &__head{
        position: relative;
        cursor: pointer;
    }
    &__img{
        position: absolute;
        left: rem(-57);
        top: 0;
        width: rem(39);
        height: rem(32);
        >*{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__btn{
        position: absolute;
        right: rem(-130);
        top: rem(3);
        font-weight: 600;
        font-size: Max(rem(15), 10px);
        line-height: rem(18);
        color: var(--primary);
        cursor: pointer;
        img{
            margin-left: rem(7);
            width: rem(10);
            height: rem(10);
            object-fit: contain;
            transition: transform 0.2s ease-in-out;
        }
    }
    &__name{
        display: flex;
        align-items: center;
        margin-bottom: rem(25);
        time{
            font-weight: 400;
            font-size: Max(rem(15), 10px);
            line-height: rem(20);
            color: var(--text-light);
        }
    }
    &__title{
        font-weight: 600;
        font-size: rem(22);
        line-height: rem(20);
        margin-right: rem(7);
    }
    &__tags{
        padding: rem(5) rem(10);
        color: var(--white);
        background-color: #56CCF2;
        text-transform: uppercase;
        font-weight: 900;
        font-size: rem(17);
        line-height: rem(20);
        margin-left: auto;
        &--done{
            background-color: #27AE60;
        }
    }
    &__info{
        display: flex;
        align-items: center;
        .total{
            margin-bottom: 0;
            margin-left: auto;
            font-weight: 400;
            font-size: Max(rem(15), 10px);
            line-height: rem(20);
            white-space: nowrap;
            span{
                font-size: rem(22);
                line-height: rem(22);
            }
        }
    }
    &__text{
        font-weight: 400;
        font-size: Max(rem(15), 10px);
        line-height: rem(20);
        overflow: hidden;
        text-overflow: ellipsis;
        &:first-child {
            overflow: visible;
        }
        &:not(:last-child){
            margin-right: rem(15);
        }
        .total {
            white-space: nowrap;
        }
    }
    &__body{
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transition: all 0.4s ease-in-out;
        box-sizing: content-box;
        display: none;
    }
    &__bottom{
        .total{
            justify-content: space-between;
            margin-bottom: 0;
            font-weight: 400;
            font-size: Max(rem(15), 10px);
            line-height: rem(20);
            span{
                font-size: rem(22);
                line-height: rem(22);
            }
        }
    }
    &__delivery{
        border-top: 2px solid var(--gray);
        border-bottom: 2px solid var(--gray);
        padding-top: rem(15);
        padding-bottom: rem(21);
        margin-bottom: rem(23);
        &-row{
            display: flex;
            justify-content: space-between;
            margin-bottom: rem(15);
        }
        &-price{
            font-weight: 600;
            font-size: Max(rem(18), 10px);
            line-height: rem(20);
        }
        address{
            font-weight: 400;
            font-size: Max(rem(13), 10px);
            line-height: rem(22);
            color: var(--text-light);
        }
        .section-link{
            margin-top: rem(2);
            display: flex;
            align-items: center;
            color: var(--text-light);
            transition: color 0.2s ease-in-out;
            font-size: 13px;
            line-height: 18px;
            span{
                font-weight: 500;
                font-size: Max(rem(13), 10px);
                line-height: rem(18);
            }
            &::before{
                content: '';
                margin-right: rem(6);
                width: rem(10);
                height: rem(14);
                background-image: url("../img/ico-sketch.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &:hover{
                color: var(--primary);
            }
        }
    }
    &.open {
        .accordion__body{
            opacity: 1;
        }
        .accordion__btn{
            img{
                transform: rotate(180deg);
            }
        }
    }


    @media(max-width: 768px){
        padding-bottom: 20px;
        &__head{
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
        }
        &__body{
            padding-top: 5px;
        }
        &__img{
            position: static;
            margin-right: 15px;
        }
        &__btn{
            position: static;
            margin-left: auto;
            width: 20px;
            height: 16px;
            img{
                width: 100%;
                height: 100%;
                margin-left: 0;
            }
        }
        &__title{
            margin-right: 0;
            margin-bottom: 6px;
        }
        &__name{
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;
            time{
                margin-bottom: 8px;
            }
        }
        &__tags{
            margin-left: unset;
            margin-right: unset;
            font-size: 15px;
            padding: 3px 15px;
        }
        &__text{
            font-size: 14px;
        }
        &__info{
            flex-wrap: wrap;
            padding-left: 55px;
            .accordion__text{
                margin-bottom: 3px;
            }
            .total{
                margin-left: unset;
                margin-top: 12px;       
                justify-content: space-between;
                width: 100%;
                span{
                    font-size: 22px;
                    line-height: 22px;
                }
            }
            &.info-open{
                flex-wrap: nowrap;
                justify-content: space-between;
                padding-left: 0;
                .accordion__text{
                    margin-bottom: 0;
                }
                .total{
                    margin-top: 0;
                    width: unset;
                    span{
                        margin-left: 0;
                    }
                }
            }
        }
        &__delivery{
            margin-bottom: 20px;
            &>.accordion__text{
                display: inline-block;
                width: 66.6%;
                margin-right: 0;
            }
            &-street{
                width: 66.6%;
            }
            &-price{
                font-size: 16px;
                line-height: 17px;
            }
            &-row{
                margin-bottom: 15px;
            }
            .section-link{
                span{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
            address{
                font-size: 12px;
            }
        }
    }
}

