.header{
    position: relative;
    z-index: 10000;
    transition: background-color .3s ease-in-out;

    &__logo {
        display: block;
        max-width: rem(294);
        width: 22.6%;
        flex-shrink: 0;
        margin-top: rem(27);
        margin-right: rem(36);
    }
    &__container {
        display: flex;
    }
    &__content {
        width: 100%;
        padding: 0 0 rem(8);
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(7) 0;
        &:first-child{
            position: relative;
            padding: 0;
            z-index: 1001;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: calc(100% + var(--container-margin));
                background: rgba(247, 247, 248, 0.85);
                backdrop-filter: blur(2px);
                z-index: -1;
            }
            .header__btns>*:not(:last-child){
                margin-right: rem(10);
            }
        }
        &:not(:last-child){
            margin-bottom: rem(8);
        }
    }
    &__nav {
        line-height: rem(20);
        &>ul{
            display: flex;
            a{
                font-weight: 500;
                font-size: rem(13);
                line-height: rem(20);
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #4F4F4F;
                transition: background-color .3s ease-in-out;
                display: inline-block;
                padding: rem(7) rem(20) rem(8);
                position: relative;
                @media (min-width: 769px) {
                    &:hover {
                        background-color: rgba(42, 46, 50, 0.05);
                    }
                }
            }
            &>li{
                &.is-active.is-small {
                    position: relative;
                    a{
                        padding-right: rem(15);
                    }
                    a::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        height: rem(8);
                        width: rem(8);
                        border-radius: 50%;
                        background: var(--primary);
                    }
                }
                &.is-active:not(.is-small) a::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    height: rem(4);
                    background: var(--primary);
                }
            }

        }
    }
    &__btn {
        outline: none;
        border: none;
        background: none;
        border-radius: 0;
        font-weight: 500;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #4F4F4F;
        display: flex;
        align-items: center;
        font-size: rem(13);
        line-height: rem(20);
        transition: color .3s ease-in-out;
        position: relative;
        z-index: 99;

        img,svg{
            width: rem(20);
            height: rem(20);
            object-fit: contain;
            line-height: 0;
            flex-shrink: 0;
            position: relative;
            z-index: 2;
            &+*:not(.header-message):not(.cart-empty){
                margin-left: rem(2.5);
            }
        }
        &.mini-cart-target{
            color: var(--primary);

            .hover-fill {
                fill: var(--primary);
            }

            .hover-stroke {
                stroke: var(--primary);
            }
        }
        &.header-catalog-btn {
            margin-left: rem(-7);
            &+.breadcrumbs{
                transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
            }
            &.is-open+.breadcrumbs{
                opacity: 0;
                visibility: hidden;
            }
        }
        @media (min-width: 769px) {
            &:not(.header__dropdown):hover {
                color: var(--primary);
            }
            &>svg:hover{
                .hover-fill {
                    fill: var(--primary);
                }

                .hover-stroke {
                    stroke: var(--primary);
                }
            }
        }
    }
    &__btns {
        display: flex;
        align-items: center;
        &>*:not(:last-child){
            margin-right: rem(28);
        }
        &:not(.header__btns--big) {
            margin-right: rem(-15);
        }
        &.header__btns--big{
            .header__btn{
                font-weight: 700;
                font-size: rem(22);
                line-height: rem(30);
                color: var(--black);
                @media (min-width: 769px) {
                    &:hover {
                        color: var(--primary);
                    }
                }
                svg, img{
                    width: rem(30);
                    height: rem(30);
                    &+*:not(.header-message):not(.cart-empty){
                        margin-left: rem(5);
                    }
                }
            }
        }
    }
    &__static {
        height: rem(121);
    }
    &~.main .page-promo:first-child{
        margin-top: rem(-121);
    }
    &__fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: rgba(247, 247, 248, 0.85);
        backdrop-filter: blur(2px);
        transition: transform .4s ease-in-out;
        transform: translateY(-100%);
        .header {
            &__logo {
                max-width: none;
                width: auto;
                margin: rem(18) 3.4% rem(18) 0;
                height: rem(54);

                & > * {
                    height: 100%;
                    object-fit: contain;
                    width: auto;
                }
            }
            &__nav {
                > ul {
                    margin: 0 rem(-14);

                    & > li {
                        margin: rem(-13) rem(14) 0;
                        padding-top: rem(13);
                        position: relative;

                        &.is-active::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            height: 0.25rem;
                            background: var(--primary);
                        }

                        & > a {
                            padding: 0;
                            background: transparent;
                            transition: color .3s ease-in-out;
                            @media (min-width: 769px) {
                                &:hover {
                                    color: var(--primary);
                                }
                            }

                            &::before {
                                content: none !important;
                            }
                        }
                    }
                }
            }
            &__row {
                padding: rem(6.5) 0;

                &:first-child::before {
                    content: none;
                }

                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }


    }
    &__search{
        position: relative;
        &-form{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -2px;
            z-index: -1;
            width: rem(32);
            opacity: 0;
            transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
            input{
                height: rem(46);
                width: 100%;
                background: var(--white);
                border: 1px solid var(--text-white);
                border-radius: rem(18);
                padding: rem(13) rem(108) rem(13) rem(45);
            }
            button{
                position: absolute;
                right: rem(3);
                top: 50%;
                transform: translateY(-50%);
            }
            svg{
                position: absolute;
                left: rem(15);
                top: 50%;
                transform: translateY(-50%);
                width: rem(25);
                height: rem(25);
                pointer-events: none;
            }
            &.is-open{
                display: block;
                opacity: 1;
                width: rem(503);
                z-index: 100;
                transition-delay: .3s;
                &+.header__search-btn{
                    opacity: 0;
                    transition-delay: 0s;
                }
            }
            &.dropdown-open{
                .header__search-list{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &-list {
            position: absolute;
            left: 0;
            right: 0;
            bottom: rem(23);
            transform: translateY(100%);
            background: #fff;
            box-shadow: 0px rem(8) rem(50) rgba(19, 20, 21, 0.15);
            z-index: -1;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
            padding: rem(40) rem(27) rem(25) rem(14);
            &>ul{
                width: rem(462);
                overflow-y: auto;
                max-height: rem(290);
                &>li:not(:last-child){
                    margin-bottom: rem(10);
                }
            }
        }
        &-item {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: rem(15);
            line-height: rem(20);
            color: var(--black);
            transition: color .3s ease-in-out;
            img{
                width: rem(40);
                height: rem(40);
                object-fit: contain;
                flex-shrink: 0;
                margin-right: rem(7);
            }
            @media (min-width: 769px) {
                &:hover {
                    color: var(--primary);
                }
            }
        }
        &-btn{
            transition: opacity .3s ease-in-out .3s, color 0.3s ease-in-out;
        }
    }
    &__menu{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #FFFFFF;
        box-shadow: 0px rem(8) rem(50) rgba(19, 20, 21, 0.15);
        z-index: -1;
        padding: rem(104) 0 rem(58);
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        transition: opacity .5s ease-in-out, visibility .5s ease-in-out, transform .5s ease-in-out;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100vh;
            z-index: -2;
            transform: translateY(100%);
            background: rgba(238, 238, 238, 0.8);
            backdrop-filter: blur(5px);
            transition: opacity .5s ease-in-out;
            opacity: 0;
            pointer-events: none;
        }
        &.is-open {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            &::after {
                opacity: 1;
            }
            .header__menu-inner{
                opacity: 1;
                transition: opacity .3s ease-in-out .5s;
            }
        }
        @media (min-width: 769px) {
            &-inner {
                overflow-y: auto;
                max-height: calc(100vh - 170px);
                padding: 0 20px;
                margin: 0 -20px;
                opacity: 0;
                transition: opacity .2s ease-in-out 0s;
            }
        }
        &-row {
            display: flex;
            justify-content: space-between;
            margin: 0 rem(-15) rem(7);
            &>*{
                flex-grow: 1;
                flex-basis: 0;
                margin: 0 rem(15);
            }
            .header__menu-item{
                padding-bottom: rem(14);
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%, -20px);
                    background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 2.55737L10.75 10.8074L19 2.55737' stroke='%23E0E0E0' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: rem(21);
                    height: rem(14);
                    opacity: 0;
                    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
                }
                &.is-selected{
                    &::before{
                        opacity: 1;
                        transform: translate(-50%, 0);
                    }
                }
            }
        }
        &-subs{
            position: relative;
            overflow: hidden;
            height: rem(0);
            transition: height .4s ease-in-out;
            &>*{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
        }
        &-sub{
            display: none;
            padding: 0 rem(3) rem(34);
            flex-wrap: wrap;
            opacity: 0;
            transition: opacity .3s ease-in-out;
            .header__menu-item{
                width: rem(73);
                margin: 0 rem(25);
                padding-bottom: rem(16);
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    width: rem(8);
                    height: rem(8);
                    border-radius: 50%;
                    background: var(--primary);
                    transition: opacity .3s ease-in-out;
                    opacity: 0;
                }
                @media (min-width: 769px) {
                    &:hover::after {
                        opacity: 1;
                    }
                }
                &__img {
                    border-bottom: none;
                    margin-bottom: 0;
                }
                &__title {
                    font-size: rem(13);
                    line-height: rem(16);
                    color: #000;
                }
            }
        }
        &-item{
            max-width: rem(175);
            cursor: pointer;
            display: block;
            &:hover,
            &.is-selected{
                .header__menu-item__img{
                    border-bottom-color: var(--primary);
                }
            }
            &__img{
                position: relative;
                border-bottom: rem(4) solid transparent;
                margin-bottom: rem(8);
                transition: border-bottom-color .3s ease-in-out;
                &>*{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center bottom;
                }
                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                }
            }
            &__title{
                font-weight: 600;
                font-size: rem(16);
                line-height: rem(20);
                text-align: center;
                color: var(--black);
            }
        }
        &-use {
            padding-top: rem(38);
            border-top: rem(4) solid #F4F4F4;
            display: flex;
        }
        &-content {
            width: 23%;
            margin-right: rem(31);
            flex-shrink: 0;
        }
        &-text {
            margin-top: rem(12);
            font-weight: 400;
            font-size: rem(13);
            line-height: rem(22);
            color: #828282;
            max-width: rem(253);
        }
        &-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            li{
                margin: 0 rem(10) rem(10) 0;
            }
            a{
                display: inline-block;
                padding: rem(9) rem(22) rem(10);
                background: #F8F8F8;
                border-radius: rem(15);
                font-weight: 500;
                font-size: rem(16);
                line-height: rem(20);
                color: var(--black);
                transition: background-color .3s ease-in-out;
                @media (min-width: 769px) {
                    &:hover {
                        background-color: var(--primary);
                    }
                }
            }
        }
    }

    &-message {
        display: none;
        position: absolute;
        top: rem(-4);
        right: rem(-16);
        z-index: 1;
        background: #fff;
        box-shadow: 0px rem(8) rem(50) 0px rgba(19, 20, 21, 0.15);
        color: var(--black);
        min-width: 310px;
        width: max-content;
        max-width: calc(100vw - 30px);
        cursor: default;
        &__btn {
            padding: rem(12) rem(17) rem(17);
            .btn{
                width: 100%;
            }
        }
        &__title {
            font-size: rem(18);
            font-weight: 700;
            line-height: rem(25);
            padding: rem(9) rem(40) rem(8) rem(18);
            text-align: left;
        }
        &__text {
            font-size: rem(14);
            line-height: rem(18);
            font-weight: 400;
            max-width: rem(315);
            text-align: left;
            padding: 0 rem(17);
            color: var(--text);
        }

        @media (max-width: 768px) {
            top: -10px;
            right: -7px;
        }
    }
    &-wholesale{
        &.wholesale-empty{
            display: none;
        }
    }
    &-regular{
        &.cart-empty{
            .cart-not-empty{
                display: none;
            }
        }
        &:not(.cart-empty){
            .cart-empty{
                display: none;
            }
        }
    }


    .breadcrumbs {
        margin-left: -21px;
        li {
            color: var(--text-light);
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            a {
                color: inherit;
                line-height: inherit;
                letter-spacing: inherit;
                &:hover{
                    color: var(--primary);
                }
            }
            &:not(:last-child){
                margin-right: 7px;
                padding-right: 16px;
                &::after {
                    width: 8px;
                    height: 8px;
                }
            }
            &:first-child a {
                display: none;
            }
        }
    }


    &.header--fixed {
        .header__fixed {
            transform: translateY(0);
        }
    }
    &.header--big {
        .header__static {
            .header__logo {
                max-width: rem(320);
                width: 16.7%;
                margin-top: 0;
                margin-right: 0;
                position: absolute;
                top: rem(57);
                left: 50%;
                transform: translateX(-50%);
            }
            .header__row:first-child::before {
                width: calc(100% + var(--container-margin) * 2);
                left: calc(-1 * var(--container-margin));
            }
            .header__nav{
                margin: 0 rem(-20);
            }
            .header__row:not(:last-child) {
                margin-bottom: rem(19);
            }
        }
        .header__search-list > ul{
            width: rem(339);
        }
        .header__search-form.is-open {
            width: rem(380);
        }
    }


    @media(max-width: 768px){
        &__search-list > ul{
            width: auto;
        }
        &~.main .page-promo:first-child{
            margin-top: -66px;
        }
        &__btns {
            &>*:not(:last-child){
                margin-right: 10px;
            }
        }
        &__static{
            height: auto;
        }
        &__btn {    
            img,svg{
                width: 28px;
                height: 28px;
            }
        }
        &-mobile{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 12px;
            padding-bottom: 10px;
            &__search{
                cursor: pointer;
            }
            &__logo{
                height: 40px;
                width: auto;
                margin-bottom: -10px;
                img{
                    width: auto;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .burger {
                margin-top: 4px;
            }
        }
        .header-wholesale .mini-cart {
            right: -43px;
        }
    }

}


  