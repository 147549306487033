.product-list{
  padding-top: rem(20);
  padding-bottom: rem(108);
  &__container{
    margin-top: rem(65);
    display: flex;
    justify-content: space-between;
  }
  &__aside{
    max-width: rem(287);
    flex-shrink: 0;
    width: 100%;
    margin-right: rem(42);
  }
  &__content{
    width: 100%;
  }
  &__top{
    margin-bottom: rem(15);
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
  &__selected {
    opacity: 0;
    transition: opacity .3s ease-in-out;
    display: flex;
    align-items: center;
    &.is-visible{
      opacity: 1;
    }
    &-inner {
      margin-right: rem(10);
      &>*:not(:last-child){
        margin-right: rem(10);
      }
    }
    .btn svg{
      margin-left: rem(5);
      cursor: pointer;
      width: 9px;
      height: 9px;
    }
  }
  &__sort {
    margin-left: auto;
    .dropdown{
      background: none!important;
      cursor: default;


      .product-list__sort .dropdown ul li {
        cursor: pointer;
      }
      &.is-open,
      &:hover{
        .output_text{
          background-color: rgba(42, 46, 50, 0.05);
        }
      }
      .output_text{
        position: relative;
        font-size: 14px;
        line-height: normal;
        transition: background-color .3s ease-in-out;
        margin: rem(-7) rem(-15) rem(-8) rem(-15);
        padding: rem(7) rem(30) rem(8) rem(36);
        &::before,
        &::after{
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        &::before{
          top: 52%;
          width: rem(15);
          height: rem(15);
          left: rem(15);
          background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none"%3E%3Crect width="15" height="2" fill="%23333333"/%3E%3Crect y="4" width="10" height="2" fill="%23333333"/%3E%3Crect y="8" width="5" height="2" fill="%23333333"/%3E%3C/svg%3E');
        }
        &::after{
          width: rem(10);
          height: rem(10);
          right: rem(15);
          background-image: url('data:image/svg+xml,%3Csvg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 1L5 5L9 1" stroke="%23333333"/%3E%3C/svg%3E%0A');
        }
      }
      ul{
        cursor: default;
        display: none;
        opacity: 1;
        visibility: visible;
        transition: none;
        box-shadow: 0px 1px 23px 0px rgba(30, 33, 36, 0.13);
        padding-top: rem(10);
        li{
          padding: rem(7) rem(16);
          transition: background-color 0.3s ease-in-out;
          cursor: pointer;
          &.is-selected {
            background: var(--primary);
            color: var(--white);
            pointer-events: none;
          }
          &:hover{
            background-color: rgba(42, 46, 50, 0.05);
          }
        }
      }
    }
  }
  &__cards{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px rem(-10);
    position: relative;
    z-index: 1;
    .product-card{
      width: calc(100% / 4 - 10px);
      margin: 0 5px rem(10);
    }
    @media (min-width: 1600px) {
      .product-card{
        width: calc(100% / 5 - 10px);
      }
    }
  }
  &__pagination{
    margin-top: rem(70);
  }
  &__descr{
    border-top: 4px solid var(--gray);
    padding-top: rem(36);
    margin-top: rem(126);
    display: flex;
    >*{
      max-width: rem(480);
      &:not(:last-child){
        margin-right: rem(50);
      }
    }
    .section-text{
      p:not(:last-child){
        margin-bottom: rem(18);
      }
    }
  }
  &__toggle{
    display: none;
  }
  &__filters{
    &>*:not(:last-child){
      margin-bottom: rem(40);
    }
  }
  &__filter{
    position: relative;
    display: flex;
    flex-direction: column;
    &.is-open{
      .product-list__filter-name::after{
        transform: rotate(180deg);
      }
    }
    &.is-empty{
      .product-list__filter-name{
        cursor: default;
      }
    }
    &-name{
      font-size: remF(22);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      justify-content: space-between;
      padding-bottom: rem(12);
      border-bottom: rem(4) solid #F4F4F4;
      cursor: pointer;
      transition: color .3s ease-in-out;
      position: relative;
      &:not(:last-child)::after{
        content: '';
        position: absolute;
        top: rem(9);
        right: 0;
        width: rem(12);
        height: rem(12);
        background-image: url('data:image/svg+xml,%3Csvg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 1L7 7L13 1" stroke="%23333333" stroke-width="2"/%3E%3C/svg%3E');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: transform .3s ease-in-out;
      }
    }
    &-content{
      display: none;
      padding-top: rem(15);
      > :not(:last-child) {
        margin-bottom: rem(10);
      }
    }
    &-slider{
      bottom: rem(7);
      position: absolute;
      left: rem(17);
      right: rem(17);
      height: rem(4);
      .ui-slider-handle {
        width: rem(17);
        height: rem(17);
        background: var(--primary);
        border-radius: 50%;
        outline: none !important;
        top: 50%;
        margin-left: 0;
        transform: translate(-50%, -50%);
        &:nth-child(2) {
          transform: translate(-100%, -50%);
        }
        &:nth-child(3) {
          transform: translate(0%, -50%);
        }
      }
      .ui-slider-range {
        background: var(--primary);
      }
      & + .product-list__filter-name {
        padding-bottom: rem(16);
        margin-bottom: rem(7);
      }
    }
    &-output{
      display: flex;
      align-items: center;
      font-size: rem(16);
      font-weight: 400;
      line-height: rem(18);
    }
  }
  &__btn{
    margin-top: rem(52);
  }
  &__nothing {
    padding: 0 0 rem(50);
  }



  @media(max-width: 1260px){
    &__cards{
      .product-card{
        width: calc(100% / 3 - 10px);
        margin: 0 5px rem(10);
      }
    }
  }
  @media(max-width: 768px){
    padding-top: 24px;
    padding-bottom: 133px;
    position: relative;
    &__container{
      flex-direction: column;
    }
    &__cards{
      margin: 0;
      .product-card{
        width: 100%;
        margin: 0;
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
    }
    &__pagination{
      margin-top: 50px;
      margin-bottom: 86px;
    }
    &__descr{
      padding-top: 30px;
      flex-direction: column;
      >*{
        &:not(:last-child){
          margin-right: 0;
          margin-bottom: 40px;
        }
        max-width: unset;
      }
    }

    &__aside {
      max-width: unset;
      width: unset;
      margin-right: 0;
      position: absolute;
      left: 0;
      right: 0;
      background-color: white;
      z-index: 2;
      display: none;
      padding-bottom: 23px;
      top: 45px;
      padding-top: 5px;
      &::after {
        content: '';
        position: absolute;
        left: -20px;
        right: -20px;
        bottom: 0;
        transform: translateY(100%);
        height: 99999px;
        background: rgba(238, 238, 238, 0.8);
        backdrop-filter: blur(5px);
        transition: opacity 0.5s ease-in-out;
        pointer-events: none;
        opacity: 0;
      }
      &.is-visible::after {
        opacity: 1;
      }
    }
    &__content {
      z-index: 1;
    }
    &__container {
      position: relative;
      margin-top: 25px;
    }
    &__top {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
    &__toggle{
      display: flex;
      align-items: center;
      font-weight: 600;
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        flex-shrink: 0;
        margin-right: 4px;
      }
    }
    &__sort {
      order: 1;
      flex-basis: 0;
      flex-grow: 1;
      position: relative;
      z-index: 1000;
      .dropdown {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        right: -14px;
        max-width: calc(100% + 14px);
        width: max-content;
        .output_text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        ul {
          max-width: calc(100vw - 40px);
          font-size: 14px;
          line-height: normal;
        }
      }
    }
    &__count {
      width: 100%;
      margin-right: 0 !important;
      order: 2;
      margin-top: 20px;
      font-size: 14px;
      line-height: normal;
    }
    &__selected {
      order: 3;
      margin-right: 0 !important;
      margin-top: 7px;
      display: block;
      &:not(.is-visible){
        display: none;
      }
      .btn {
        white-space: break-spaces;
        display: inline-block;
      }
      &-inner {
        display: inline;
        margin-right: 5px;
      }
    }
    &__btn {
      .btn {
        width: 100%;
      }
    }



    &__filters {
      position: relative;
    }
    &__btn {
      margin-top: 32px;
      position: sticky;
      bottom: 0;
      padding: 20px;
      background: var(--white);
      margin-left: -20px;
      margin-right: -20px;
      z-index: 99;
    }
    &__aside {
      padding-bottom: 0;
    }

  }
}

