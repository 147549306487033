.page-promo{
    position: relative;
    z-index: 1;
    background-color: #F4F5F6;
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        overflow: hidden;
        @media (min-width: 769px) {
            .page-promo__img {
                display: none;
            }
        }
        &-img,
        &-video {
            &:not(.page-promo__bg--noblur)::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                background: rgba(255, 255, 255, 0.75);
            }
        }
        &-img,
        &-video,
        &-slider {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            &>*, img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center bottom;
            }
        }
    }
    &__container{
        position: relative;
        z-index: 2;
        .swiper{
            &-slide{
                display: flex;
                height: auto;
                .page-promo__title {
                    max-width: rem(520);
                    margin-bottom: rem(24);
                }
                .page-promo__text {
                    max-width: rem(464);
                }
                .page-promo__text,
                .page-promo__btn {
                    opacity: 0;
                    transform: translateY(40px);
                    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
                    transition-delay: .3s;
                }
                .page-promo__btn{
                    transition-delay: .4s;
                }

                &.swiper-slide-active{
                    transition-delay: 500ms!important;
                    .page-promo__text,
                    .page-promo__btn {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            &.is-started{
                .page-promo__text {
                    transition-delay: .8s;
                }
                .page-promo__btn{
                    transition-delay: .9s;
                }
            }
        }
    }
    &__content{
        min-height: Min(100vh, rem(1080));
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: rem(154) 0 rem(104);
        min-width: 50%;
    }
    &__img {
        margin-left: rem(50);
        &>*, img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center bottom;
        }
    }

    &__title {
        max-width: rem(747);
        margin-bottom: rem(35);
    }
    &__text {
        max-width: rem(704);
        &>*{
            font-weight: 500;
            font-size: rem(20);
            line-height: rem(35);
            color: #4F4F4F;
            text-shadow: 0 0 rem(41) #EFEFEF;
        }
    }
    &__btn{
        margin-top: rem(42);
    }


    @media(max-width: 768px){
        &__content{
            min-height: unset;
            padding: 80px 0 54px 0;
            justify-content: flex-start;
        }
        &__title{
            margin-bottom: 25px;
        }
        &__text{
            max-width: unset;
            >*{
                font-size: 18px;
                line-height: 32px;
            }
        }

        &__container {
            .swiper-slide {
                .page-promo__title {
                    margin-bottom: 25px;
                }
                .page-promo__img{
                    display: none;
                }
                .page-promo__content{
                    padding: 150px 0 110px 0;
                }
            }
        }


        .page-promo__btn {
            margin-top: 30px;
            .btn {
                width: 100%;
            }
        }

        .swiper-nav-pagination {
            bottom: 50px;
            max-width: 520px;
        }

        .page-promo__img {
            margin-left: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .page-promo__img::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(238, 238, 238, 0.8);
            z-index: 2;
        }

    }
}

  