.news{
    padding-bottom: rem(113);
    overflow: hidden;
    &__head{
        display: flex;
        align-items: center;
        margin-bottom: rem(34);
        .section-arrow{
            position: relative;
            top: rem(9);
        }
    }
    &__title{
        margin-right: rem(35);
    }
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-50) rem(-30);
        max-width: rem(1320);
        &>*{
            margin: 0 rem(50) rem(30);
            flex-grow: 1;
            flex-basis: 0;
        }
    }

    @media(max-width: 768px){
        padding-bottom: 95px;
        &__head{
            margin-bottom: 30px;
        }
        &__container{
            max-width: unset;
            margin: 0;
            flex-direction: column;
            .section-arrow{
                margin: 0;
                justify-content: flex-end;
            }
        }
    }
}