.similiar-card{
    padding: 0;
    .product-card__top{
        pointer-events: none;
    }
    .product-card__tags .tag{
        font-size: 10px;
        line-height: 10px;
        padding:    rem(4) rem(10);
    }
    &__link{
        display: flex;
        padding: rem(23) rem(15) rem(21) rem(29);
        &:hover{
            .similiar-card__title{
                color: var(--primary);
            }
        }
    }
    &__img{
        position: relative;
        margin-right: rem(16);
        width: 16.1%;
        flex-shrink: 0;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 128.4%;
        }
    }
    &__info{
        display: flex;
        margin-bottom: rem(10);
        .product-card__price{
            white-space: nowrap;
        }
    }
    &__title{
        margin-right: rem(7);
        max-width: rem(182);
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(20);
        color: var(--black);
        transition: color 0.3s ease-in-out;
    }
    &__content{
        display: flex;
        flex-direction: column;
        width: 100%;
        .product-card__price{
            margin-bottom: 1px;
        }
        .product-card__price--new{
            text-align: right;
            font-weight: 600;
            font-size: rem(18);
        }
        .product-card__price--old{
            top: rem(-15);
        }
        .product-card__package >*{
            font-weight: 300;
            font-size: Max(rem(12), 10px);
            color: var(--text-light);
        }
        .product-card__spec{
            display: flex;
            margin-top: auto;
            align-items: center;
            margin-right: 0;
            li{
                &:not(:last-child){
                    margin-right: rem(23);
                    margin-bottom: 0;
                }
            }
        }
    }
    .product-card__buttons::after{
        top: rem(-20);
        height: 20px;
    }

    @media(max-width: 1024px){
        .product-card__spec{
            li{
                &:not(:last-child){
                    margin-right: rem(12);
                }
            }
        }
    }

    @media(max-width: 768px){
        .product-card__top {
            top: 15px;
        }
        &__title{
            max-width: unset;
        }
        &__link{
            padding: 11px 15px 11px 20px; 
        }
        &__img{
            width: 21.2%;
            max-height: 125px;
        }
        &__content{
            width: 100%;
            .product-card__spec{
                margin-top: unset;
                align-items: center;
                align-items: flex-start;
                flex-direction: column;
                li{
                    &:not(:last-child){
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                }
            }
            .product-card__content{
                display: flex;
                flex-direction: column;
                justify-content: end;
            }
        }
    }
}

