.search-results{
    padding-top: rem(85);
    padding-bottom: rem(168);
    &__title{
        margin-bottom: rem(65);
    }
    &__head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(26);
    }
    &__text{
        font-weight: 400;
        font-size: Max(rem(14), 10px);
        line-height: rem(17);
    }
    &__container{
        margin-bottom: rem(75);
        display: flex;
        flex-direction: column;
    }
    &__cards{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px rem(-10);
        >*{
            width: calc(100% / 5 - 10px);
            margin: 0 5px rem(10);
        }
    }
    @media(max-width: 1080px){
        &__cards{
            >*{
                width: calc(100% / 4 - 10px);
            }
        }
    }
    @media(max-width: 768px){
        padding-top: 24px;
        padding-bottom: 78px;
        &__title{
            margin-bottom: 13px;
        }
        &__head{
            flex-direction: column;
            margin-bottom: 19px;
        }
        &__text{
            margin-right: auto;
            margin-bottom: 50px;
        }
        &__container{
            margin-bottom: 50px;
        }
        &__cards{
            margin: 0;
            >*{
                width: 100%;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
        }
        .dropdown-click{
            margin-left: auto;
        }
    }
}